import { useQuery } from "@tanstack/react-query"

import { CONTRACT_QUERY_KEY } from "@/constants/query"
import { getContractById, getContracts } from "@/http/contract"

import { IRequestParams } from "@/types/api"

export const useContractQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getContracts(params || {}),
        queryKey: [CONTRACT_QUERY_KEY, params]
    })
}

export const useContractByIdQuery = (id: string, options?: { refetchOnWindowFocus: boolean }) => {
    return useQuery({
        queryFn: () => getContractById(id),
        queryKey: [CONTRACT_QUERY_KEY, id],
        enabled: !!id,
        refetchOnWindowFocus: options?.refetchOnWindowFocus ?? true
    })
}