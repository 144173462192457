export const YesOrNo = [
    "Да", "Нет"
]

export const FormalizationRulesCategories = [
    "AI", "AII", "AIII", "AIV", "B", "C", "D", "E", "F", "прицеп"
]

export const PropulsionKinds = [
    "колесный движитель",
    "гусеничный движитель",
    "полугусеничный движитель",
    "шагающий движитель",
    "шнекороторный движитель",
    "вальцовый движитель",
    "прочие типы движителя"
]

export const BodyColors = [
    "желтый",
    "оранжевый",
    "красный",
    "фиолетовый",
    "синий",
    "зеленый",
    "серый",
    "коричневый",
    "белый",
    "черный"
]

export const Months = [
    "январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"
]

export const LayoutPatterns = [
    "кабина над двигателем",
    "переднеприводная",
    "полноприводная",
    "капотная",
    "полукапотная",
    "классическая",
    "полукапотная, заднеприводная",
    "среднемоторная, полноприводная",
    "классическая или полноприводная",
    "заднемоторная",
    "бескапотная",
    "среднемоторная",
    "вагонная бескапотная",
    "вагонного типа",
    "капотная, переднеприводная",
    "капотная, классическая",
    "вагонная, полноприводная",
    "капотная, полноприводная",
    "бескапотная, кабина над двигателем",
    "капотная, кабина за двигателем",
    "полукапотная, полноприводная",
    "кабина за двигателем",
    "вагонная",
    "двигатель в базе",
    "прицеп-дом",
    "шасси без кабины",
    "с приводом на заднее колесо",
    "платформа со специальным оборудованием",
    "заднеприводная",
    "с приводом на заднее колесо и колесо бокового прицепа",
    "с передним расположением кабины",
    "прицеп самосвальный",
    "полукапотная, переднеприводная",
    "прицеп бортовой",
    "прицеп тентованный",
    "кабина перед двигателем",
    "прицеп, пневмонагнетатель",
    "прицеп, растворонасос",
    "низкорамная грузовая платформа",
    "металлическая бортовая платформа",
    "металлическая платформа с контейнерными замками",
    "платформа для перевозки техники",
    "Металлическая платформа с устройствами для крепления и фиксации контейнера",
    "Металлическая самосвальная платформа",
    "телескопическая рама со специализированными опорами",
    "платформа с кониками",
    "полуприцеп",
    "грузовая платформа с бортами в передней части и опускающимися трапами",
    "прицеп фургон с одностворчатой передней дверью, с двустворчатой задней дверью",
    "кузов-фургон закрытого типа, с задними распашными воротами",
    "прицеп для перевозки мототехники",
    "прицеп для перевозки водного транспорта",
    "цистерна эллиптической формы",
    "прицеп, измельчитель древесины",
    "изотермический фургон",
    "фургон-рефрижератор",
    "прицеп фургон, для перевозки животных",
    "прицеп, платформа для перевозки автотранспорта",
    "полуприцеп низкорамный с раздвижной рамой",
    "прицеп для перевозки ульев",
    "платформа для перевозки грузов",
    "Мобильная установка для обработки",
    "цистерна цилиндрической формы с эллиптическим днищем",
    "прицеп для перевозки транспортных средств",
    "платформа с откидными бортами с навесным оборудованием системы световой сигнализации",
    "фургон",
    "грузовая платформа со съемными бортами, с контейнерными замками",
    "прицеп",
    "платформа со специальным оборудованием и емкостью для воды",
    "бескапотная, кабина над передними колесами",
    "специализированный кузов для перевозки планера",
    "полуприцеп-цистерна",
    "прицеп-цистерна",
    "рама с упорами для фиксации колес автомобиля",
    "пространственная каркасно-сварная рама с направляющими роликами",
    "пространственная каркасно-сварная рама с ложементами",
    "пространственная каркасно-сварная рама",
    "рама (платформа) с поворотным коником",
    "накатные трапы с пневматической замковой системой крепления сменных кузовов"
]

export const EngineIdNumberLocations = [
    "на блоке цилиндров двигателя",
    "на блоке цилиндров двигателя слева вверху",
    "на блоке цилиндров двигателя слева внизу",
    "на блоке цилиндров двигателя справа вверху",
    "на блоке цилиндров двигателя справа внизу",
    "на задней стенке блока цилиндров двигателя",
    "в развале блока цилиндров двигателя слева",
    "в развале блока цилиндров двигателя справа",
    "на блоке цилиндров двигателя слева",
    "на блоке цилиндров двигателя справа",
    "на табличке, на блоке двигателя",
    "снизу слева в середине картера",
    "на заднем фланце картера двигателя, над стартером",
    "на заднем фланце картера двигателя",
    "на картере двигателя позади корпуса масляного насоса",
    "на картере двигателя, перед задним фланцем",
    "на заводской табличке двигателя",
    "на заднем фланце картера двигателя, сзади сбоку",
    "на верхней плоскости блока цилиндров, со стороны кожуха маховика",
    "внизу и слева на блоке цилиндров, на фланце насоса низкого давления",
    "на передней части картера двигателя и с левой стороны по ходу движения",
    "на блоке цилиндров двигателя спереди посередине",
    "на передней части картера двигателя, за выхлопными трубами",
    "на картере двигателя со стороны дроссельной заслонки",
    "на нижней половине картера двигателя, справа",
    "на передней половине картера двигателя, вверху",
    "на левой половине картера двигателя, внизу",
    "на корпусе электродвигателя",
    "на заводской табличке, на блоке цилиндров двигателя"
]

export const EngineLocations = [
    "переднее продольное",
    "переднее поперечное",
    "заднее продольное",
    "продольное, перед задней осью",
    "среднее продольное",
    "переднее, поперечное",
    "заднее, поперечное",
    "продольное, на заднем свесе",
    "продольное в заднем свесе",
    "двигатель в пределах базы",
    "в базе, продольное",
    "продольное, в заднем свесе",
    "заднее поперечное",
    "в средней продольной плоскости",
    "продольное",
    "поперечное",
    "между лонжеронами рамы под кузовом",
    "два электродвигателя спереди и сзади, поперечное",
    "в колесных модулях электропортального моста",
    "два электродвигателя, расположенных поперечно над передней и задней осями",
    "в базе, поперечное",
    "с вертикально расположенным цилиндром",
    "продольное, за кабиной",
    "один электродвигатель, расположенный поперечно над задней осью",
    "один двигатель над передней осью и два двигателя на задней оси",
    "в ступице заднего колеса (мотор-колесо)",
    "два электродвигателя, выполненных в едином конструктивном блоке с мостом второй оси",
    "ось коленчатого вала перпендикулярна продольной плоскости транспортного средства",
    "три электродвигателя, расположенных поперечно продольной плоскости транспортного средства, один над передней осью и два над задней",
    "два электродвигателя, выполненных в едином конструктивном блоке с мостом третьей оси",
    "в едином конструктивном блоке с мостом второй оси",
    "в едином конструктивном блоке с мостом третьей оси"
]

export const EngineTypes = [
    "Двигатель внутреннего сгорания", "Электрический двигатель"
]

export const CylinderArrangements = [
    "рядное",
    "V-образное",
    "оппозитное",
    "W-образное",
    "горизонтальное оппозитное",
    "горизонтальное",
    "рядное, вертикальное",
    "вертикальное",
    "L-образное",
    "наклонное",
    "рядное, наклонное",
    "продольное"
]

export const FuelKinds = [
    "Бензин",
    "Дизельное топливо",
    "Компримированный природный газ",
    "Сжиженный природный газ",
    "Сжиженный пропан-бутан",
    "Водородные топливные элементы",
    "Прочее"
]

export const FuelSystemTypes = [
    "Впрыск топлива с электронным управлением",
    "Впрыск топлива под давлением",
    "непосредственный впрыск топлива с общей рампой",
    "распределительный впрыск топлива с электронным управлением",
    "Распределенный впрыск топлива",
    "Многоточечный впрыск топлива с электронным управлением",
    "Аккумуляторного типа с электронным управлением",
    "непосредственное впрыскивание топлива",
    "впрыскивание топлива под давлением",
    "распределенное впрыскивание топлива",
    "впрыскивание топлива с электронным управлением",
    "распределенная система впрыскивания",
    "многоточечный впрыск топлива",
    "Common Rail, с электронной системой регулирования подачи топлива",
    "моновпрыск газа с электронным управлением",
    "моновпрыск газа",
    "впрыскивание газа с электронным управлением",
    "Common Rail, непосредственное впрыскивание топлива",
    "Common Rail, впрыск топлива под давлением",
    "Впрыск топлива под давлением, установка форсунок для впрыска топлива",
    "Комбинированная электронно-механическая система впрыска топлива",
    "Многоточечный (распределенный) впрыск топлива",
    "распределенный впрыск газа",
    "Впрыск топлива",
    "многоточечное впрыскивание топлива с электронным управлением",
    "карбюраторная",
    "газовая, внешнего смесеобразования",
    "аккумуляторного типа , с распределенным впрыском и электронным управлением",
    "распределенное впрыскивание топлива с электронным управлением",
    "впрыск топлива под давлением, с общей рампой и электронным управлением",
    "электронная, со встроенным датчиком детонации",
    "впрыскивание топлива с электронным управлением с возможностью отключения подачи топлива",
    "непосредственное впрыскивание топлива, электронная система регулирования подачи топлива",
    "непосредственное впрыскивание топлива с электронным управлением",
    "распределенное и непосредственное впрыскивание топлива",
    "распределенное фазированное впрыскивание топлива с электронным управлением",
    "непосредственный впрыск топлива с общей рампой и электронным управлением",
    "электронный многоточечный впрыск топлива",
    "непосредственный впрыск топлива с общей рампой, с электронной системой управления впрыском",
    "система с газовым смесителем, автоматической адаптацией к составу топлива и распределенным впрыском газа",
    "непосредственный впрыск топлива, электронная система регулирования подачи топлива",
    "разделенного типа",
    "смеситель с электронным управлением",
    "впрыск топлива под давлением и впрыск газа в систему впуска",
    "впрыск топлива с электронным управлением, непосредственный впрыск",
    "непосредственный впрыск с электронным управлением",
    "распределенный впрыск топлива с электронной системой управления",
    "впрыск топлива под давлением, установка форсунок для впрыска газа во впускной коллектор (опция)",
    "непосредственный впрыск",
    "Common Rail, непосредственное впрыскивание топлива с общей рампой",
    "Common Rail, непосредственный впрыск топлива",
    "непосредственный впрыск топлива в камеру сгорания",
    "непосредственный впрыск топлива с электронным управлением",
    "Впрыск с общей рампой под давлением с распределенными насосами",
    "распределенный фазированный впрыск с электронным управлением",
    "непосредственный впрыск топлива",
    "непосредственное впрыскивание топлива с общей рампой",
    "распределенный впрыск топлива с электронным управлением",
    "впрыск топлива с общей рампой",
    "непосредственный впрыск топлива под давлением",
    "аккумуляторного типа с общей рампой (Common Rail)",
    "непосредственное впрыскивание топлива с электронным управлением, с функцией отключения подачи топлива",
    "с электронным управлением, непосредственный впрыск топлива под давлением, с общей рампой",
    "распределённое впрыскивание топлива с электронным управлением",
    "распределённое фазированное впрыскивание топлива с электронным управлением",
    "впрыск топлива, с общей рампой и электронным управлением",
    "распреденный впрыск топлива с электронным управлением",
    "Common Rail, непосредственный впрыск топлива с общей рампой",
    "впрыск дизельного топлива под давлением и впрыск газа в систему впуска",
    "центральное впрыскивание топлива",
    "прямой впрыск топлива с электронным управлением",
    "непосредственный впрыск топлива с электронным управлением MG1",
    "одноточечный впрыск топлива",
    "Bosch MG1, непосредственный впрыск топлива с электронным управлением",
    "MED 17, непосредственный впрыск топлива с электронным управлением",
    "SIMOS 18.1, непосредственный впрыск топлива с электронным управлением",
    "распределенный впрыск топлива с электронным управлением, MG1",
    "распределенный и непосредственный впрыск топлива",
    "центральное впрыскивание топлива с электронным управлением",
    "Toyota, L-Jetronic, распределенный впрыск топлива",
    "Toyota L-Jetronic, распределенный и непосредственный впрыск топлива",
    "газовая, разделенная, с впрыском газа во впускные каналы головки цилиндров",
    "моновпрыск топлива с электронным управлением",
    "одноточечный впрыск топлива с электронным управлением",
    "центральный впрыск топлива с электронным управлением",
    "прямой впрыск",
    "непосредственное впрыскивание топлива с общей рампой, с электронной системой управления впрыском",
    "Common Rail, непосредственное впрыскивание топлива с общей рампой и многоточечный синхронный впрыск во впускной коллектор",
    "непосредственное впрыскивание топлива с общей рампой и многоточечный синхронный впрыск во впускной коллектор",
    "многоточечный прямой впрыск топлива",
    "инжекторная",
    "непосредственный впрыск топлива, с электронной системой управления впрыском",
    "мультикомплексная система питания с совмещенными распределенным и непосредственным впрыском топлива, с электронным управлением",
    "непосредственный впрыск топлива или многоточечный впрыск топлива",
    "нет"
]

export const IgnitionSystems = [
    "бесконтактная",
    "бесконтактная, с электронным управлением",
    "индивидуальная катушка и свеча зажигания",
    "контактная",
    "микропроцессорная",
    "микропроцессорное принудительное зажигание",
    "однопроводная, батарейная, микропроцессорная",
    "с электронным управлением",
    "транзисторная",
    "электронная",
    "электронная система с индуктивным разрядом",
    "электронная, бесконтактная",
    "электронная, бесконтактная, микропроцессорная",
    "электронная, бесконтактная, с индуктивным датчиком и электронным коммутатором CDI",
    "электронная, конденсаторная",
    "электронная, со встроенным датчиком детонации",
    "электронная, со статическим распределителем",
    "Нет"
]

export const EnergyStorageDeviceTypes = [
    "батарея",
    "маховик/генератор",
    "конденсатор",
    "свинцово-кислотная аккумуляторная батарея",
    "никель-металлогидридная аккумуляторная батарея",
    "литий-полимерная аккумуляторная батарея",
    "литий-ионная аккумуляторная батарея"
]

export const TransmissionTypes = [
    "механическая, с ручным управлением",
    "механическая, с автоматическим управлением",
    "механическая, с автоматическим и возможностью ручного управления",
    "гидромеханическая, с автоматическим управлением",
    "гидромеханическая, с автоматическим и возможностью ручного управления",
    "электрическая, с автоматическим управлением",
    "электрическая, с автоматическим и возможностью ручного управления",
    "электромеханическая, с автоматическим управлением",
    "электромеханическая, с автоматическим и возможностью ручного управления",
    "гидростатическая, с автоматическим управлением",
    "гидростатическая, с автоматическим и возможностью ручного управления",
    "гидравлическая, с автоматическим управлением",
    "гидравлическая, с автоматическим и возможностью ручного управления",
    "механическая, с автоматическим управлением (передняя ось)",
    "механическая, с автоматическим управлением (задняя ось)",
    "механическая, для категории L",
    "гидростатическая, с ручным управлением",
    "гидромеханическая, с ручным управлением"
]

export const GearboxTypes = [
    "автоматическая",
    "механическая, с ручным управлением",
    "с ручным управлением",
    "механическая, с автоматическим управлением, синхронизированная",
    "автоматическая с возможностью ручного управления",
    "механическая, синхронизированная, с ручным управлением",
    "с ручным управлением, делителем и демультипликатором",
    "механическая, с ручным переселекторным или автоматическим управлением",
    "синхронизированная, с ручным управлением",
    "с ручным управлением, с синхронизаторами на всех передачах переднего и заднего хода",
    "роботизированная с ручным управлением",
    "с ручным и автоматическим управлением",
    "автоматическая, гидротрансформатор в составе коробки передач",
    "автоматическая, с возможностью командного управления",
    "с ручным, преселекторным или автоматическим управлением",
    "с ручным управлением, с демультипликатором",
    "механическая, трехходовая с синхронизаторами передач. Состоит из основной четырехступенчатой коробки передач и демультипликатора, управление коробкой передач дистанционное",
    "механическая, с ручным, преселекторным или автоматическим управлением",
    "с ножным управлением",
    "механическая с автоматизированным управлением",
    "автоматическая, бесступенчатая",
    "с ручным управлением, синхронизированная",
    "с ручным управлением, делителем и мультипликатором",
    "автоматическая, бесступенчатый вариатор",
    "с делителем и демультипликатором, с ручным управлением",
    "с синхронизаторами на всех передачах, с ручным переключением, с делителем",
    "гидромеханическая, с автоматическим управлением, синхронизированная",
    "полностью синхронизированная, с ручным управлением",
    "бесступенчатый вариатор",
    "c электронным управлением (роботизированная)",
    "бесступенчатая",
    "с ручным или электронным управлением",
    "механическая с ручным управлением, с синхронизацией на II-VI передачах",
    "с ручным управлением, полностью синхронизированная",
    "автоматическая, с возможностью ручного управления",
    "бесступенчатый вариатор, с возможностью ручного управления",
    "с ручным или автоматическим управлением",
    "вариатор, с автоматическим управлением",
    "вариатор, с возможностью ручного управления",
    "механическая, с автоматическим управлением",
    "с автоматическим управлением",
    "вариатор с гидротрансформатором",
    //eslint-disable-next-line max-len
    "механическая, трехходовая с синхронизаторами передач, состоит из основной четырехступенчатой коробки передач, делителя и демультипликатора, управление коробкой передач - дистанционное",
    "с ручным управлением, с синхронизаторами II-IX передачах",
    "одноступенчатый, двухвальный редуктор",
    "с ручным или автоматизированным управлением",
    "отсутствует",
    "коробка передач отсутствует",
    "с ручным управлением, с синхронизаторами на II-IX передачах",
    "автоматическая, с гидротрансформатором и встроенным ретардером",
    "с ручным управлением, с синхронизаторами на I-IX передачах",
    "с ручным управлением, с синхронизаторами на II-VI передачах",
    "автоматизированная, с возможностью ручного управления",
    "с автоматическим переключением, синхронизированная",
    "с автоматизированным управлением",
    "редуктор понижающий, одноступенчатый",
    "с ручным управлением, с синхронизаторами на II-VII передачах",
    "с ручным управлением, с синхронизаторами на I-ХVI передачах",
    "двухступенчатый редуктор",
    "управление коробкой передач дистанционное",
    "с ручным управлением, с синхронизаторами на I-V передачах",
    "механическая, с электропневматическим управлением",
    "с электронным управлением (автоматизированная)",
    "автоматическая, планетарная",
    "нет"
]

export const GearRatiosForEachGears = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "З.Х.",
    "З.Х.I",
    "З.Х.II",
    "Дополнительная понижающая передача",
    "Передаточное число главной передачи",
    "IX",
    "X",
    "XI",
    "XII",
    "XIII",
    "XIV",
    "XV",
    "XVI",
    "XVII",
    "XVIII",
    "XIX",
    "XX",
    "XXI",
    "I - Дополнительная понижающая передача",
    "II - Дополнительная понижающая передача",
    "Дополнительная понижающая передача З.Х.",
    "З.Х.III",
    "З.Х.IV",
    "З.Х.V",
    "Передаточное число промежуточной передачи",
    "гидравлическая I",
    "гидравлическая II",
    "диапазон",
    "гидравлическая 1",
    "гидравлическая 2",
    "гидравлическая III",
    "гидравлическая IV",
    "гидравлическая V",
    "З.Х.VI",
    "З.Х.VII",
    "З.Х.VIII",
    "З.Х.IX",
    "З.Х.X",
    "З.Х.XI",
    "Дополнительная понижающая передача З.Х.I",
    "Дополнительная понижающая передача З.Х.II",
    "Дополнительная понижающая передача З.Х.III",
    "З.Х.XII",
    "З.Х.XIII",
    "З.Х.XIV",
    "З.Х.XV",
    "передаточное число для 1-й оси",
    "передаточное число для 2-й оси"
]

export const MainGearTypes = [
    "двойная",
    "гипоидная",
    "двойная, коническо-цилиндрическая",
    "коническо-цилиндрическая",
    "коническая, разнесенная, с блокируемыми межколёсными дифференциалами",
    "коническая с планетарными колесными редукторами",
    "одинарная, коническая",
    "двойная разнесённая с бортовым редуктором",
    "двойная центральная двухступенчатая",
    "двойная центральная одноступенчатая",
    "двойная центральная",
    "одинарная гипоидная с нижним смещением",
    "одинарная гипоидная с верхним смещением",
    "одинарная цилиндрическая шевронная",
    "одинарная цилиндрическая косозубая",
    "одинарная цилиндрическая прямозубая",
    "одинарная цилиндрическая",
    "одинарная червячная с нижним червяком",
    "одинарная червячная с верхним червяком",
    "одинарная червячная",
    "одинарная, гипоидная",
    "коническая",
    "двойная, разнесенного типа, коническая центральная передача и планетарные колесные редукторы",
    "двойная, центральная, с блокируемым межколесным дифференциалом",
    "двойная, с блокируемым межколесным дифференциалом",
    "центральная, с блокируемым межколесным дифференциалом",
    "двухступенчатая, с колесной парой",
    "цепная",
    "одинарная, цилиндрическая, косозубая",
    "одинарная, цилиндрическая",
    "центральная, двухступенчатая с межколесным блокируемым дифференциалом; средний мост с межосевым блокируемым дифференциалом",
    "центральная, одинарная",
    "двойная, разнесенная, состоит из центрального конического редуктора и планетарных колесных передач",
    "двухступенчатая, состоит из центральной главной передачи и колесных редукторов",
    "двойная, гипоидно-цилиндрическая",
    "центральная двухступенчатая, с блокируемым межколесным дифференциалом",
    "Двойная, разнесенная, с блокируемым межколёсным дифференциалом",
    "цилиндрическая",
    "разнесенная: одноступенчатая центральная и колесная",
    "цилиндрическая косозубая",
    "разнесенная двойная",
    "двухступенчатая, с колесной передачей",
    "одноступенчатая, гипоидная",
    "двойная, разнесенная, с блокируемым межколесным дифференциалом и межосевым дифференциалом на среднем мосту",
    "одинарная",
    "разнесенная, двойная",
    "центральный двухступенчатый редуктор, с блокируемым межколесным дифференциалом",
    "одинарная косозубая",
    "шестеренчатая, цилиндрическая, двойная",
    "цилиндрическая косозубая (передняя), гипоидная (задняя)",
    "цилиндрическая+коническая",
    "двойная разнесенная",
    "планетарная",
    "одинарная, гипоидная, задний дифференциал может оснащаться блокировкой с электронным управлением",
    "двойная, первая ступень - цилиндрическая, вторая ступень - планетарная",
    "центральный двухступенчатый редуктор",
    "центральная одинарная",
    "зубчатый ремень",
    "центральная гипоидная, одинарная",
    "разнесенная планетарная, двойная",
    "разнесенная: двойная центральная передача (первая ступень цилиндрическая, вторая - коническая) и бортовые планетарные редукторы",
    "разнесённая: двойная центральная передача (первая ступень коническая, вторая – цилиндрическая) и бортовые планетарные редукторы",
    "ременная",
    "двойная, разнесенная с колесными редукторами",
    "коническая двухступенчатая разнесенная",
    "электропортальный мост с двумя электродвигателями, интегрированными в колесные модули",
    "двухступенчатая, с блокировкой межосевого дифференциала",
    "цилиндрическая, двухступенчатая",
    "с центральным гипоидным редуктором",
    "портальный мост с двойной передачей",
    "разнесенная",
    "цилиндрическая зубчатая",
    "двухступенчатая, разнесенная с гипоидным редуктором",
    "передняя - коническая, задняя - гипоидная",
    "двойная, планетарно-цилиндрическая",
    "двойная, цилиндрическо-коническая",
    "двойная, с центральным редуктором",
    "цилиндрическая, косозубая - передняя, коническая - задняя",
    "ременная с планетарным колесным редуктором",
    "двойная, центральная, с блокируемым межосевым и межколесными дифференциалами",
    "нет"
]

export const ElectricMachineKinds = [
    "электродвигатель электромобиля (электромашины)",
    "электродвигатель трансмиссии",
    "электрогенератор трансмиссии",
    "обратимая электромашина"
]

export const ElectricMachineTypes = [
    "синхронная машина",
    "переменного тока, асинхронная, однофазная",
    "переменного тока, асинхронная, двухфазная",
    "переменного тока, асинхронная, трехфазная",
    "переменного тока, асинхронная, пятифазная",
    "переменного тока, асинхронная, шестифазная",
    "постоянного тока",
    "переменного тока, синхронная, трехфазная",
    "переменного тока, синхронная, шестифазная"
]

export const SteeringWheelPositions = [
    "справа",
    "слева",
    "посередине",
    "нет"
]
