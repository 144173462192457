import { useQuery } from "@tanstack/react-query"

import { CLIENTS_QUERY_KEY } from "@/constants/query"
import { getClientById, getClients } from "@/http/vocabularyClient"

import { IRequestParams } from "@/types/api"

export const useClientQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getClients(params || {}),
        queryKey: [CLIENTS_QUERY_KEY, params],
        retry: false
    })
}

export const useClientByIdQuery = (id: number) => {
    return useQuery({
        queryFn: () => getClientById(id),
        queryKey: [CLIENTS_QUERY_KEY, id],
        enabled: id !== 0
    })
}
