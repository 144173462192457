import { FC, Key, useRef, useState, useEffect } from "react"

import { useNavigate } from "react-router-dom"

import { ContractAddFormProps, ContractForm } from "@/components/Contract"
import { Table } from "@/components/Table"
import { Resource } from "@/constants/permission"
import { getContractsExcel } from "@/http/contract"
import { useContractsFilters } from "@/pages/Contracts/ContractsFilters"

import { ContractColumns } from "./ContractColumns"

import { useContractMutation } from "@/hook/Contracts/useContractMutation"
import { useContractQuery } from "@/hook/Contracts/useContractQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useTable } from "@/hook/useTable"

import { CONTRACTS_KEY, IContract } from "@/types/IContract"

import { paths } from "@/router/paths"

import { defineLoading } from "@/utils/defineLoading"
import { getInitialColumns } from "@/utils/getInitialColumns"

const ContractsPage: FC = () => {
    const navigate = useNavigate()
    const initialColumns = getInitialColumns(ContractColumns, CONTRACTS_KEY)
    const contractComponent = useRef<ContractAddFormProps>(null)

    const { filters } = useContractsFilters()

    const { download: excelDownload, isLoading: isExcelLoading } = useDownloadFile(() => getContractsExcel({
        Filter: filter.filter,
        OrderBy: order.orderBy
      }, getInitialColumns(ContractColumns, CONTRACTS_KEY)))

    const { deleteMutation } = useContractMutation()

    const { columns, pagination, filter, order } = useTable({
        initialColumns,
        key: CONTRACTS_KEY
    })

    const { data, isLoading } = useContractQuery({
        Page: pagination.page,
        PageSize: pagination.pageSize,
        Filter: filter.filter,
        OrderBy: order.orderBy
    })

    const [renderLoading, setRenderLoading] = useState(true)

    useEffect(() => {
        defineLoading(data && !isLoading, setRenderLoading)
    }, [data, isLoading])

    const onRowClickHandler = (data: IContract) => {
        navigate(paths.contractById(data.id))
    }


    const onAddHandler = () => {
        contractComponent.current?.open()
    }

    const onEditHandler = (id: string) => {
        contractComponent.current?.open(id)
    }

    const onRemoveHandler = async (ids: Key[]) => {
        await deleteMutation.mutateAsync({
            ids: ids.map((w) => w.toString())
        })
    }

    const onCopyHandler = (ids: Key[]) => {
        contractComponent.current?.copy(ids[0].toString())
    }

    const onExcelDownloadHandler = async () => {
        await excelDownload()
      }

    return (
        <>
            <ContractForm ref={contractComponent} />
            <Table
                tableName={CONTRACTS_KEY}
                dataSource={data?.response.data}
                columns={columns.data}
                rowKey="id"
                isLoading={renderLoading}
                pagination={{
                    currentPage: pagination.page,
                    pageSize: pagination.pageSize,
                    total: data?.response.count,
                    onPageChange: pagination.onPageChange,
                    onPageSizeChange: pagination.onPageSizeChange
                }}
                onRowClick={onRowClickHandler}
                onAdd={onAddHandler}
                onEdit={onEditHandler}
                onRemove={onRemoveHandler}
                onCopy={onCopyHandler}
                onSorterChange={order.setOrderBy}
                permission={{
                    resource: Resource.Contract
                }}
                filters={{
                    filters,
                    onSubmit: filter.onFilterChange,
                    onCancel: filter.onFilterReset,
                    filterKey: CONTRACTS_KEY
                  }}
                download={{
                isLoading: isExcelLoading,
                onDownload: onExcelDownloadHandler
                }}
            />
        </>
    )
}

export default ContractsPage