import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import {
    IContractAnnex,
    IContractAnnexClose,
    IContractAnnexCreate,
    IContractAnnexDelete,
    IContractAnnexUpdate
} from "@/types/IContractAnnex"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import { ITableColumn } from "@/types/ui"

export const getContractAnnexes = (params: IRequestParams): Promise<IPagingResponse<IContractAnnex>> =>
    client.get("/ContractAnnexes", {
        params
    })

export const getContractAnnexesById = (id: string): Promise<IResponse<IContractAnnex>> => client.get(`/ContractAnnexes/${id}`)

export const createContractAnnex = (body: IContractAnnexCreate): Promise<IResponse<string>> =>
    client.post("/ContractAnnexes", body)

export const updateContractAnnex = (body: IContractAnnexUpdate): Promise<IResponse<string>> =>
    client.put("/ContractAnnexes", body)

export const closeContractAnnex = (body: IContractAnnexClose): Promise<IResponse<string>> =>
    client.put(`/ContractAnnexes/Close/${body.id}`, body)

export const deleteContractAnnexes = (params: IContractAnnexDelete): Promise<IResponse<any>> =>
    client.delete("/ContractAnnexes", { data: params })

export const getContractAnnexExcel = (params?: IRequestParams, columns?: ITableColumn<IContractAnnex>[]): Promise<IResponse<IDownloadDocument>> => 
    client.post("/ContractAnnexes/Excel", columns, {
                params
            })

export const printTheAct = (id: string): Promise<IResponse<IDownloadDocument>> =>
    client.get(`/ContractAnnexes/PrintTheAct/${id}`)

export const getActForEdo = (ids: string[]): Promise<IResponse<IDownloadDocument>> =>
    client.post("/ContractAnnexes/GetActForEdo", ids)

export const printContractAnnex = (id: string): Promise<IResponse<IDownloadDocument>> =>
    client.get(`/ContractAnnexes/Print/${id}`)

