import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IClient, IClientCreate, IClientUpdate } from "@/types/IClient"

export const getClients = (params: IRequestParams): Promise<IPagingResponse<IClient>> =>
    client.get("/Clients", {
        params
    })

export const getClientById = (id: number): Promise<IResponse<IClient>> => client.get(`/Clients/${id}`)

export const createClient = (body: IClientCreate): Promise<IResponse<number>> => client.post("/Clients", body)

export const updateClient = (body: IClientUpdate): Promise<IResponse<string>> => client.put("/Clients", body)

export const deleteClients = (ids: number[]): Promise<IResponse<boolean>> =>
    client.delete("/Clients", {
        data: {
            ids
        }
    })
