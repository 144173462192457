import { createContext, FC, useEffect, useState } from "react"

import { Spin } from "antd"

import { getPermissions } from "@/http/permissions"

import { IPermission } from "@/types/IPermission"

const PermissionContext = createContext<IPermission[]>([])

const PermissionContextProvider: FC<any> = ({ children }) => {

  const [permissions, setPermissions] = useState<IPermission[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {

    getPermissions()
      .then(resp => {
        setPermissions(resp.response)
      })
      .finally(() => {
        setIsLoading(false)
      })

  }, [])

  if(isLoading) return (
    <Spin></Spin>
  )
  
  return (
    <PermissionContext.Provider value={permissions}>
      {children}
    </PermissionContext.Provider>
  )
}
export { PermissionContext, PermissionContextProvider }