import { FC, ReactNode } from "react"

import { defaultDropAnimationSideEffects, DragOverlay, DropAnimation } from "@dnd-kit/core"

interface SortableOverlayProps {
  children: ReactNode
}

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.4"
      }
    }
  })
}

export const SortableOverlay: FC<SortableOverlayProps> = (props) => {
  const { children } = props

  return (
    <DragOverlay dropAnimation={dropAnimationConfig}>
      {children}
    </DragOverlay>
  )
}