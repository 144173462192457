import { FC, Key, useMemo, useRef, useState, useEffect } from "react"

import { useOidcUser } from "@axa-fr/react-oidc"
import { useNavigate, useSearchParams } from "react-router-dom"

import { ContractAnnexForm, ContractAnnexAddFormProps } from "@/components/ContractAnnex/ContractAnnexForm"
import { Table } from "@/components/Table"
import { Resource } from "@/constants/permission"
import { Role } from "@/constants/role"
import { getActForEdo, getContractAnnexExcel, printTheAct } from "@/http/contractAnnex"
import { ContractAnnexColumns } from "@/pages/ContractAnnexes/ContractAnnexColumns"
import { useContractAnnexesFilters } from "@/pages/ContractAnnexes/ContractAnnexesFilters"

import { useContractAnnexMutation } from "@/hook/ContractAnnexes/useContractAnnexMutation"
import { useContractAnnexQuery } from "@/hook/ContractAnnexes/useContractAnnexQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useLocationSearchHandler } from "@/hook/useLocationSearchHandler"
import { useMessage } from "@/hook/useMessage"
import { useRole } from "@/hook/useRole"
import { useTable } from "@/hook/useTable"

import { CONTRACT_ANNEXES_KEY, IContractAnnex } from "@/types/IContractAnnex"

import { paths } from "@/router/paths"

import { defineLoading } from "@/utils/defineLoading"
import { getInitialColumns } from "@/utils/getInitialColumns"

const ContractAnnexesPage: FC = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const contractAnnexComponent = useRef<ContractAnnexAddFormProps>(null)

    const { deleteMutation } = useContractAnnexMutation()

    const { filters } = useContractAnnexesFilters()
    
    const initialColumns = getInitialColumns(ContractAnnexColumns, CONTRACT_ANNEXES_KEY)

    const { columns, pagination, filter, order } = useTable({
        initialColumns,
        key: CONTRACT_ANNEXES_KEY
    })

    const { data, isLoading } = useContractAnnexQuery({
        Page: pagination.page,
        PageSize: pagination.pageSize,
        Filter: filter.filter,
        OrderBy: order.orderBy
    })

    const [renderLoading, setRenderLoading] = useState(true)

    useEffect(() => {
        defineLoading(data && !isLoading, setRenderLoading)
    }, [data, isLoading])

    const { download: excelDownload, isLoading: isExcelLoading } = useDownloadFile(() => getContractAnnexExcel({
        Filter: filter.filter,
        OrderBy: order.orderBy
      }, getInitialColumns(ContractAnnexColumns, CONTRACT_ANNEXES_KEY)))

    const { hasRole } = useRole()
    const isAdmin = useMemo(() => {
        return hasRole(Role.Admin)
    }, [hasRole])

    let selectedId = ""
    let selectedIds = [] as string[]
    const [isActionLoading, setIsActionLoading] = useState(false)

    const { download: actDownload } = useDownloadFile(() => printTheAct(selectedId))
    const { download: actForEdoDownload } = useDownloadFile(() => getActForEdo(selectedIds))

    const { oidcUser } = useOidcUser()
    const { Error } = useMessage(CONTRACT_ANNEXES_KEY)

    const onRowClickHandler = (data: IContractAnnex) => {
        navigate(paths.contractAnnexById(data.id))
    }

    const onAddHandler = () => {
        contractAnnexComponent.current?.open()
    }

    const onEditHandler = (id: string) => {
        contractAnnexComponent.current?.open(id)
    }

    const onRemoveHandler = async (ids: Key[]) => {
        await deleteMutation.mutateAsync({
            ids: ids.map((w) => w.toString())
        })
    }

    const onCopyHandler = (ids: Key[]) => {
        contractAnnexComponent.current?.copy(ids[0].toString())
    }

    const checkContractAnnexes = () => {
        let check = true
        const contractAnnexes = data?.response.data.filter(c => selectedIds.includes(c.id))
        if (contractAnnexes?.find(c => c.passportCount > c.readyCount)) {
            Error({ response: { data: { message: "Для формирования актов значения показателей в полях «Кол-во паспортов» и «В отчете» должны быть равны" } } })
            check = false
        }

        if (!isAdmin && contractAnnexes?.find(c => c.author.id !== oidcUser.sub)) {
            Error({ response: { data: { message: "Для формирования актов пользователь должен являться администратором или автором документов" } } })
            check = false
        }

        return check
    }

    const onActDownloadHandler = async (ids: Key[]) => {
        setIsActionLoading(true)

        selectedIds = ids.map((w) => w.toString())
        const check = checkContractAnnexes()
        if (check) {
            for (const index in selectedIds) {
                selectedId = selectedIds[index]
                await actDownload()
            }
        }
       
        setIsActionLoading(false)
    }

    const onActForEdoDownloadHandler = async (ids: Key[]) => {
        setIsActionLoading(true)

        selectedIds = ids.map((w) => w.toString())
        const check = checkContractAnnexes()
        if (check) {
            await actForEdoDownload()
        }

        setIsActionLoading(false)
    }

    const onOpenForEdit = () => {
        const searchId = searchParams.get("edit")
        if (searchId) {
            onEditHandler(searchId)
        }
    }

    const onExcelDownloadHandler = async () => {
        await excelDownload()
    }

    useLocationSearchHandler({
        value: "edit",
        handler: onOpenForEdit
    })

    useLocationSearchHandler({
        value: "openForm",
        handler: onAddHandler
    })

    return (
        <>
            <ContractAnnexForm ref={contractAnnexComponent} />
            <Table
                tableName={CONTRACT_ANNEXES_KEY}
                dataSource={data?.response.data}
                columns={columns.data}
                rowKey="id"
                isLoading={renderLoading}
                pagination={{
                    currentPage: pagination.page,
                    pageSize: pagination.pageSize,
                    total: data?.response.count,
                    onPageChange: pagination.onPageChange,
                    onPageSizeChange: pagination.onPageSizeChange
                }}
                filters={{
                    filters,
                    onSubmit: filter.onFilterChange,
                    onCancel: filter.onFilterReset,
                    filterKey: CONTRACT_ANNEXES_KEY
                }}
                onAdd={onAddHandler}
                onEdit={onEditHandler}
                onRemove={onRemoveHandler}
                onCopy={onCopyHandler}
                onAction={[onActDownloadHandler, onActForEdoDownloadHandler]}
                actionText={["Распечатать акт", "Акт для ЭДО"]}
                isActionLoading={isActionLoading}
                onUpdateColumns={columns.onColumnChange}
                addText="Добавить приложение"
                onRowClick={onRowClickHandler}
                onSorterChange={order.setOrderBy}
                permission={{
                    resource: Resource.ContractAnnex
                }}
                download={{
                    isLoading: isExcelLoading,
                    onDownload: onExcelDownloadHandler
                }}
            />
        </>
    )
}

export default ContractAnnexesPage
