import { useEffect } from "react"

import { useLocation } from "react-router-dom"

interface UseLocationSearchHandlerOptions {
  value: string;
  handler: () => void;
}

export const useLocationSearchHandler = (options: UseLocationSearchHandlerOptions) => {
  const { value, handler } = options
  const location = useLocation()

  useEffect(() => {
    if (location.search?.includes(value)) {
      handler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}