const COLUMN_WIDTH_PREFIX = "-WIDTH-SETTINGS"

export interface IColumnWidth {
    name: string
    width: number
}

export const setColumnWidth = (key: string, column: IColumnWidth) => {
    const tableName = key + COLUMN_WIDTH_PREFIX

    const jsonStr = localStorage.getItem(tableName)

    if(!jsonStr) {
        const table = [column]
        const jsonTable = JSON.stringify(table)
        localStorage.setItem(tableName, jsonTable)
    }
    else {
        const table = JSON.parse(jsonStr) as IColumnWidth[]

        const elemIndex = table.findIndex(x => x.name === column.name)
        elemIndex !== -1 ? table[elemIndex].width = column.width : table.push(column)

        const jsonTable = JSON.stringify(table)
        localStorage.setItem(tableName, jsonTable)
    }
}

export const getColumnsWidth = (key: string) => {
    const tableName = key + COLUMN_WIDTH_PREFIX

    const jsonStr = localStorage.getItem(tableName)

    if(jsonStr) {
        const table = JSON.parse(jsonStr) as IColumnWidth[]
        return table
    }

    return []
}