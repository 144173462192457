import { ITableColumn } from "@/types/ui"

export const technicalVocabulariesColumns: ITableColumn<ITechnicalVocabulary>[] = [
    {
        title: "Значение",
        key: "name",
        dataIndex: ["name"],
        sorter: true,
        width: 200,
        isVisible: true,
        order: 1
    }
]

export interface ITechnicalVocabulary {
    name: string
}

export const TECHNICAL_VOCABULARIES_KEY = "TECHNICAL_VOCABULARIES_KEY"
