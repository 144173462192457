import { useQuery } from "@tanstack/react-query"

import { AUTHORIZED_ORGANIZATION_QUERY_KEY } from "@/constants/query"
import { getAuthorizedOrganizationById, getAuthorizedOrganizations } from "@/http/authorizedOrganization"

import { IRequestParams } from "@/types/api"

export const useAuthorizedOrganizationQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getAuthorizedOrganizations(params || {}),
    queryKey: [AUTHORIZED_ORGANIZATION_QUERY_KEY, params],
    retry: false
  })
}

export const useAuthorizedOrganizationByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getAuthorizedOrganizationById(id),
    queryKey: [AUTHORIZED_ORGANIZATION_QUERY_KEY, id],
    enabled: id !== 0
  })
}
