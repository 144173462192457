import { useState } from "react"

import { useMessage } from "@/hook/useMessage"

import { IRequestParams, IResponse } from "@/types/api"
import { IDownloadDocument } from "@/types/IDownloadDocument"

import { base64ToArrayBuffer } from "@/utils/download"

const DOWNLOAD_FILE_KEY = "certificationBodies"

export const useDownloadFile = (fetch: (params?: IRequestParams) => Promise<IResponse<IDownloadDocument>>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const { Success, Error, Process } = useMessage(DOWNLOAD_FILE_KEY)

  const download = async (params?: IRequestParams) => {
    setIsLoading(true)
    Process()

    try {
      const data = await fetch(params)

      const blob = new Blob([base64ToArrayBuffer(data.response.content)], {
        type: data.response.mimeType
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", data.response.name)

      document.body.appendChild(link)

      link.click()

      link.parentNode?.removeChild(link)

      setIsSuccess(true)
      Success()
    } catch (e: any) {
      setIsSuccess(false)
      Error(e)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    download,
    isLoading,
    isSuccess
  }
}
