import React from "react"

import ReactDOM from "react-dom/client"

import "antd/dist/reset.css"
import "@/assets/styles/main.scss"
import { App } from "./App"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(<App />)
