import { Tag } from "antd"
import { Link } from "react-router-dom"

import { IContractAnnex } from "@/types/IContractAnnex"
import { ITableColumn } from "@/types/ui"

import { paths } from "@/router/paths"

import { formatDate } from "@/utils/formatDate"
import { formatNumber } from "@/utils/formatNumber"

const EMPTY_DATA = "Нет"
const NOT_INDICATED = "Не указано"

export const ContractAnnexColumns: ITableColumn<IContractAnnex>[] = [
    {
        title: "Номер записи",
        key: "number",
        dataIndex: ["number"],
        align: "center",
        sorter: true,
        width: 175,
        isVisible: true,
        order: 1
    },
    {
        title: "Организация",
        key: "organization",
        dataIndex: ["organization"],
        align: "center",
        sorter: true,
        width: 125,
        render: (_, { organization }) => <span key={organization?.id}>{(organization?.shortName || organization?.name) ?? NOT_INDICATED}</span>,
        isVisible: true,
        order: 2
    },
    {
        title: "Номер договора",
        key: "contractName",
        dataIndex: ["contract", "name"],
        align: "center",
        sorter: true,
        width: 175,
        render: (_, { contract }) =>
            <Link to={paths.contractById(contract?.id ?? "")} onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              window.open(paths.contractById(contract?.id ?? ""), "_self")
            }}>
              {contract?.name}
            </Link>,
        isVisible: true,
        order: 3
    },
    {
        title: "Дата договора",
        key: "contractDate",
        dataIndex: ["contract", "date"],
        align: "center",
        sorter: true,
        width: 150,
        render: (_, { contract }) => <><span>{contract?.date ? formatDate(contract?.date) : NOT_INDICATED}</span></>,
        isVisible: true,
        order: 4
    },
    {
        title: "Номер приложения",
        key: "name",
        dataIndex: ["name"],
        align: "center",
        sorter: true,
        width: 175,
        isVisible: true,
        order: 5
    },
    {
        title: "Дата приложения",
        key: "date",
        dataIndex: ["date"],
        align: "center",
        sorter: true,
        width: 130,
        isVisible: true,
        render: (_, { date }) => <>{date && <span>{formatDate(date)}</span>}</>,
        order: 6
    },
    {
        title: "Подписано",
        key: "isSigned",
        dataIndex: ["isSigned"],
        align: "center",
        sorter: true,
        width: 110,
        isVisible: true,
        render: (_, { isSigned }) => <>{isSigned ? "Да" : "Нет"}</>,
        order: 7
    },
    {
        title: "Оплачено",
        key: "isPaid",
        dataIndex: ["isPaid"],
        align: "center",
        sorter: true,
        width: 110,
        isVisible: true,
        render: (_, { isPaid }) => <>{isPaid ? "Да" : "Нет"}</>,
        order: 8
    },
    {
        title: "Закрыто",
        key: "isClosed",
        dataIndex: ["isClosed"],
        align: "center",
        sorter: true,
        width: 110,
        isVisible: true,
        render: (_, { isClosed }) =>
            <Tag color={isClosed ? "green-inverse" : "lightgray"}
                 style={{ width: "45px",  textAlign: "center", padding: "0 10px" }}>
                {isClosed ? "Да" : "Нет"}
            </Tag>,
        order: 9
    },
    {
        title: "Дата закрытия",
        key: "closedDate",
        dataIndex: ["closedDate"],
        align: "center",
        sorter: true,
        width: 130,
        isVisible: true,
        render: (_, { closedDate }) => <>{closedDate && <span>{formatDate(closedDate)}</span>}</>,
        order: 10
    },
    {
        title: "Кол-во паспортов",
        key: "passportCount",
        dataIndex: ["passportCount"],
        align: "center",
        sorter: true,
        width: 125,
        isVisible: true,
        order: 11
    },
    {
        title: "Выполнено",
        key: "registeredCount",
        dataIndex: ["registeredCount"],
        align: "center",
        sorter: true,
        width: 125,
        isVisible: true,
        order: 12
    },
    {
        title: "В отчете",
        key: "readyCount",
        dataIndex: ["readyCount"],
        align: "center",
        sorter: true,
        width: 125,
        isVisible: true,
        order: 13
    },
    {
        title: "Автор",
        key: "author",
        dataIndex: ["author"],
        align: "center",
        width: 150,
        render: (_, { author }) => <span key={author.id}>{author.fullName ?? EMPTY_DATA}</span>,
        sorter: true,
        sorterField: "authorId",
        isVisible: true,
        order: 14
    },
    {
        title: "Сумма, руб.",
        key: "amount",
        dataIndex: ["amount"],
        align: "center",
        sorter: true,
        width: 150,
        isVisible: true,
        render: (_, { amount }) => <>{formatNumber(amount)}</>,
        order: 15
    },
    {
        title: "Наименование клиента",
        key: "clientName",
        dataIndex: ["client", "name"],
        align: "center",
        sorter: true,
        width: 350,
        isVisible: true,
        order: 16,
        render: (_, { client }) => <>{client?.shortName || client?.name}</>
    },
    {
        title: "ИНН",
        key: "clientInn",
        dataIndex: ["client", "inn"],
        align: "center",
        sorter: true,
        width: 150,
        isVisible: true,
        order: 17
    },
    {
        title: "Стоимость итого",
        key: "totalPrice",
        dataIndex: "totalPrice",
        align: "center",
        sorter: true,
        width: 150,
        isVisible: true,
        render: (_, { totalPrice }) => <>{formatNumber(totalPrice)}</>,
        order: 18
    }
]
