import React, { Key, useEffect, useMemo, useState } from "react"

import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  FileExcelOutlined,
  FilterOutlined,
  FormOutlined,
  SettingOutlined
} from "@ant-design/icons"
import {
  Button,
  Popconfirm,
  Space,
  Table as AntTable,
  TablePaginationConfig,
  Tooltip
} from "antd"
import {
  FilterValue,
  SorterResult, TableCurrentDataSource,
  TableRowSelection,
  ColumnType
} from "antd/es/table/interface"

import { FilterWrapper } from "@/components/FilterWrapper"
import { ColumnsSetting, getColumnsWidth, setColumnWidth } from "@/components/Table/ColumnsSetting"
import { Resource, Scope } from "@/constants/permission"

import classes from "./Table.module.scss"

import { usePermission } from "@/hook/usePermission"

import { IFilterProps, ITableColumn } from "@/types/ui"

import { getNested } from "@/utils/getNested"

const MIN_COLUMN_WIDTH = 75

interface TablePaginationProps {
  currentPage?: number;
  total?: number;
  pageSize?: number;
  onPageChange?: (page: number, pageSize: number) => void;
  onPageSizeChange?: (current: number, size: number) => void;
  showSizeChanger?: boolean;
}

interface TableDownloadProps {
  onDownload: () => void;
  isLoading: boolean;
}

interface TablePermissionProps {
  resource: Resource;
}

interface TableProps<T> {
  tableName?: string;
  dataSource?: T[];
  columns: ITableColumn<T>[];
  onAdd?: () => void;
  addText?: string;
  onCreate?: () => void;
  addManuallyText?: string;
  onEdit?: (id: any) => void;
  onCopy?: (selectedRowKeys: Key[]) => void;
  onRemove?: (selectedRowKeys: Key[]) => void;
  download?: TableDownloadProps;
  onAction?: ((selectedRowKeys: Key[]) => void)[];
  actionText?: string[];
  isActionLoading?: boolean;
  isLoading?: boolean;
  rowKey: string | string[];
  onRowClick?: (data: T, index: number | undefined) => void;
  onUpdateColumns?: (newColumns: ITableColumn<T>[]) => void;
  onSorterChange?: (value: string) => void;
  pagination?: TablePaginationProps;
  filters?: IFilterProps;
  permission?: TablePermissionProps;
  rowClassNameCheck?: string
  hasSelection?: boolean;
}

export const Table = <T extends Object>(props: TableProps<T>) => {
  const {
    tableName,
    dataSource = [],
    columns,
    pagination,
    onCopy,
    onRemove,
    onEdit,
    onAdd,
    onCreate,
    onAction,
    addText = "Добавить",
    addManuallyText = "Создать вручную",
    actionText,
    download,
    isLoading,
    isActionLoading,
    rowKey,
    onRowClick,
    onUpdateColumns,
    onSorterChange,
    filters,
    permission,
    rowClassNameCheck,
    hasSelection = true
  } = props

  const {
    showSizeChanger,
    currentPage,
    pageSize,
    total,
    onPageChange,
    onPageSizeChange
  } = pagination || {}

  const [isSortingEnable, setSortingEnable] = useState(true)

  const memoizedVisibleColumns = useMemo(() => columns.filter(column => column.isVisible), [columns])

  const [visibleColumns, setVisibleColumns] = useState(memoizedVisibleColumns)

  // Cоздание events для ячейки-заголовка
  memoizedVisibleColumns.forEach((value: ITableColumn<T>) => {
    value.onHeaderCell = (data: ColumnType<T>) => {
      return {
        onMouseDown: (event: React.MouseEvent) => {
          if (data.dataIndex !== undefined) {
            handleMouseDown(event, data.dataIndex.toString())
          }
        }
      }
    }

    value.sorter = isSortingEnable
  })

  useEffect(() => {
    const columnsWidthSettings = tableName ? getColumnsWidth(tableName) : null

    memoizedVisibleColumns.forEach((value: ITableColumn<T>) => {
      if (columnsWidthSettings) {
        const setting = columnsWidthSettings.find(x => x.name === value.dataIndex?.toString())

        if (setting) {
          value.width = setting.width
        }
      }
    })
  }, [memoizedVisibleColumns, tableName])

  useEffect(() => {
    memoizedVisibleColumns.forEach((value: ITableColumn<T>) => {
      value.sorter = isSortingEnable
    })
  }, [isSortingEnable, memoizedVisibleColumns])

  const { hasPermission } = usePermission()

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [openFilters, setOpenFilters] = useState<boolean>(false)
  const [openSetting, setOpenSetting] = useState<boolean>(false)

  const onOpenFiltersHandler = () => {
    setOpenFilters(true)
  }

  const onOpenSettingHandler = () => {
    setOpenSetting(true)
  }

  const onCloseSettingHandler = () => {
    setOpenSetting(false)
  }

  const onSelectHandler = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const onCopyHandler = () => {
    if (selectedRowKeys.length && onCopy) {
      onCopy(selectedRowKeys)
    }
  }

  const onRemoveHandler = async () => {
    if (selectedRowKeys.length && onRemove) {
      await onRemove(selectedRowKeys)
      setSelectedRowKeys([])
    }
  }

  const onEditHandler = () => {
    if (selectedRowKeys.length === 1 && onEdit) {
      const id = selectedRowKeys[0] as string
      onEdit(id)
    }
  }

  const onCreateHandler = () => {
    if (onAdd) {
      onAdd()
    }
  }

  const onCreateManuallyHandler = () => {
    if (onCreate) {
      onCreate()
    }
  }

  const onActionHandler = async (index: number) => {
    if (selectedRowKeys.length && onAction) {
      await onAction[index](selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<T> = {
    selectedRowKeys,
    onChange: onSelectHandler
  }

  const onRow = (data: T, index?: number) => {
    return {
      onClick: () => (onRowClick ? onRowClick(data, index) : null)
    }
  }

  const onUpdatedColumnsHandler = (updatedColumns: ITableColumn<T>[]) => {
    if (onUpdateColumns) {
      onUpdateColumns(updatedColumns)
      setOpenSetting(false)
    }
  }

  const generateRowKey = (data: T): string => {
    if (Array.isArray(rowKey)) {
      return getNested(data, rowKey) || ""
    }

    return data[rowKey as keyof object]
  }

  const paginationProps: TablePaginationConfig = {
    position: ["bottomCenter"],
    current: currentPage,
    total,
    pageSize,
    showSizeChanger,
    onChange: onPageChange,
    onShowSizeChange: onPageSizeChange,
    showTotal: (total, range) => { return <span>{range[0]}-{range[1]} из {total}</span> },
    showQuickJumper: true
  }

  const isCan = useMemo(() => {
    if (permission?.resource == null) return {
      Create: true,
      CreateManually: true,
      Update: true,
      Delete: true,
      Register: true
    }

    return {
      Create: hasPermission(permission.resource, Scope.Create),
      CreateManually: hasPermission(permission.resource, Scope.CreateManually),
      Update: hasPermission(permission.resource, Scope.Update) || hasPermission(permission.resource, Scope.Close),
      Delete: hasPermission(permission.resource, Scope.Delete),
      Register: hasPermission(permission.resource, Scope.Register)
    }
  }, [hasPermission, permission])

  const onTableChange = (
    _: TablePaginationConfig,
    __: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    ___: TableCurrentDataSource<T>
  ) => {
    if (!onSorterChange) {
      return
    }

    if (!Array.isArray(sorter)) {
      if (!sorter.order) {
        onSorterChange("")
      } else {
        const order = sorter.order === "ascend" ? "asc" : "desc"

        const newOrderBy = `${(sorter.column as ITableColumn<T>)?.sorterField || sorter.columnKey} ${order}`
        onSorterChange(newOrderBy)
      }
    }
  }

  //#region Dynamic column width

  const getSelectedColumnIndex = () => {
    if (resizing && currentColumnIndex !== null) {
      const columnIndex = visibleColumns.findIndex(x => x.dataIndex?.toString() === currentColumnIndex)

      const additional = partOfCell === 1 && columnIndex !== 0 ? -1 : 0

      return columnIndex + additional
    }

    return -1
  }

  const saveColumnWidthSettings = () => {
    const columnIndex = getSelectedColumnIndex()

    if (columnIndex === -1) return

    const column = visibleColumns[columnIndex]

    if (column.dataIndex && tableName) {
      setColumnWidth(tableName, { name: column.dataIndex?.toString(), width: Number(column.width) })
    }
  }

  // Возвращает цифру от 0 до 2:
  // 0 - курсор далеко от границы
  // 1 - курсор у левой границы
  // 2 - курсор у правой границы
  const isCursorNearby = (event: React.MouseEvent): number => {
    const element = event.target as HTMLElement
    const rect = element.getBoundingClientRect()

    if (event.clientX - rect.left < 3) {
      return 1
    }
    else if (rect.right - event.clientX < 3) {
      return 2
    }

    return 0
  }

  // Изменяет вид курсора, если он находится у края столбца
  const cursorNearBoundHandler = (event: React.MouseEvent) => {
    const element = event.target as HTMLElement
    const isCursorNearEdge = isCursorNearby(event)

    if (isCursorNearEdge) {
      element.classList.add(classes.resizeColHeader)
    }
    else {
      element.classList.remove(classes.resizeColHeader)
    }
  }

  const [resizing, setResizing] = useState(false)
  const [currentColumnIndex, setCurrentColumnIndex] = useState<string | null>(null)
  const [partOfCell, setPartOfCell] = useState<number>(0)

  const disableResizing = () => {
    setSortingEnable(true)

    saveColumnWidthSettings()

    setResizing(false)
    setCurrentColumnIndex(null)
    setPartOfCell(0)
  }

  const handleMouseDown = (event: React.MouseEvent, index: string) => {
    const part = isCursorNearby(event)

    if (part) {
      setResizing(true)
      setCurrentColumnIndex(index)
      setPartOfCell(part)
      setSortingEnable(false)
    }
  }

  const handleMouseMove = (event: React.MouseEvent) => {
    cursorNearBoundHandler(event)

    if (resizing && currentColumnIndex !== null) {
      setVisibleColumns((prevColumns: ITableColumn<T>[]) => {
        const newColumns = [...prevColumns]
        const columnIndex = getSelectedColumnIndex()

        if (columnIndex === -1) {
          return prevColumns
        }

        const oldColumnWidth = Number(newColumns[columnIndex]?.width)
        const newColumnWidth = Number(newColumns[columnIndex]?.width) + event.movementX

        newColumns[columnIndex].width = newColumnWidth
        if (columnIndex + 1 < newColumns.length) {
          if (oldColumnWidth < Number(newColumns[columnIndex].width)) {
            newColumns[columnIndex + 1].width = Number(newColumns[columnIndex + 1].width) - (newColumnWidth - oldColumnWidth)
          }
          else {
            newColumns[columnIndex + 1].width = Number(newColumns[columnIndex + 1].width) + (newColumnWidth - oldColumnWidth)
          }
        }

        for (let i = 0; i < newColumns.length; i++) {
          if (Number(newColumns[i]?.width) < MIN_COLUMN_WIDTH) {
            newColumns[i].width = MIN_COLUMN_WIDTH
          }
        }

        return newColumns
      })
    }
  }

  const onHeaderRow = () => {
    return {
      onMouseUp: () => disableResizing(),
      onMouseMove: (event: React.MouseEvent) => handleMouseMove(event),
      onMouseLeave: () => disableResizing(),
      className: classes.headerRow
    }
  }

  //#endregion

  return (
      <>
        <div className={classes.actions}>
          <Space>
            {
                isCan.Create && addText && onAdd && (
                    <Button
                        onClick={onCreateHandler}
                        type="primary"
                        icon={<FileAddOutlined/>}>
                      {addText}
                    </Button>
                )
            }
            {
                isCan.CreateManually && addManuallyText && onCreate && (
                  <Button
                      onClick={onCreateManuallyHandler}
                      type="primary"
                      icon={<FormOutlined/>}>
                    {addManuallyText}
                  </Button>
                )
            }
            {
                isCan.Update && onEdit && (
                    <Tooltip title="Редактировать">
                      <Button disabled={selectedRowKeys.length !== 1 || isActionLoading} onClick={onEditHandler} type="dashed" icon={<EditOutlined />} />
                    </Tooltip>
                )
            }
            {
                isCan.Delete && onRemove && (
                    <Popconfirm disabled={!selectedRowKeys.length || isActionLoading}
                                onConfirm={onRemoveHandler}
                                title="Удалить"
                                description="Вы действительно хотите удалить?"
                                okText="Да"
                                cancelText="Нет">
                      <Tooltip title="Удалить">
                        <Button
                            disabled={!selectedRowKeys.length || isActionLoading}
                            type="dashed"
                            icon={<DeleteOutlined/>}/>
                      </Tooltip>
                    </Popconfirm>
                )
            }
            {
                isCan.Create && onCopy && (
                    <Popconfirm disabled={!selectedRowKeys.length || isActionLoading}
                                onConfirm={onCopyHandler}
                                title="Скопировать"
                                description="Вы действительно хотите скопировать?"
                                okText="Да"
                                cancelText="Нет">
                      <Tooltip title="Скопировать">
                        <Button
                            disabled={selectedRowKeys.length !== 1 || isActionLoading}
                            type="dashed"
                            icon={<CopyOutlined/>}/>
                      </Tooltip>
                    </Popconfirm>
                )
            }
            {
                actionText && onAction &&
                  onAction.map((action, index) => (
                      <Popconfirm onConfirm={() => onActionHandler(index)}
                                  title={actionText[index]}
                                  key={index}
                                  description="Вы уверены?"
                                  okText="Да"
                                  cancelText="Нет">
                          <Button
                              key={"button" + index}
                              disabled={selectedRowKeys.length === 0 || isActionLoading}
                              type="primary"
                              style={{ marginLeft: 75 }}
                          >
                            {actionText[index]}
                          </Button>
                      </Popconfirm>
                  ))
            }
          </Space>
          <Space>
            {
                download && (
                    <Tooltip title="Выгрузить в Excel">
                      <Button
                          type="primary"
                          disabled={download.isLoading || isActionLoading}
                          onClick={download.onDownload}
                          icon={<FileExcelOutlined/>}
                      />
                    </Tooltip>
                )
            }
            {onUpdateColumns && (
                <Tooltip title="Настройка полей">
                  <Button
                      type="primary"
                      icon={<SettingOutlined/>}
                      onClick={onOpenSettingHandler}
                  />
                </Tooltip>
            )}
            {filters && (
                <Tooltip title="Фильтр">
                  <Button
                      type="primary"
                      icon={<FilterOutlined/>}
                      onClick={onOpenFiltersHandler}
                  />
                </Tooltip>
            )}
          </Space>

        </div>
        <AntTable
            locale={{ emptyText: "Нет данных" }}
            columns={memoizedVisibleColumns}
            dataSource={dataSource}
            showSorterTooltip={false}
            rowSelection={hasSelection ? rowSelection : undefined}
            rowKey={generateRowKey}
            loading={isLoading}
            scroll={{ x: "max-content", y: "calc(100vh - 325px)" }}
            onRow={onRow}
            onHeaderRow={onHeaderRow}
            rowClassName={(record) => {
              if (!rowClassNameCheck) return classes.row
              return JSON.stringify(record).includes(rowClassNameCheck) ? classes.archive : classes.row
            }}
            pagination={{ ...paginationProps, showSizeChanger: !!onPageSizeChange }}
            onChange={onTableChange}
        />
        {onUpdateColumns && (
            <ColumnsSetting
                open={openSetting}
                onCancel={onCloseSettingHandler}
                columns={columns}
                onSubmit={onUpdatedColumnsHandler}
            />
        )}
        {filters && (
            <FilterWrapper
                filterKey={filters.filterKey}
                open={openFilters}
                setOpen={setOpenFilters}
                filters={filters.filters}
                submitText={filters.submitText}
                cancelText={filters.cancelText}
                onSubmit={filters.onSubmit}
                onCancel={filters.onCancel}
            />
        )}
      </>
  )
}