import { useMutation, useQueryClient } from "@tanstack/react-query"

import { CLIENTS_QUERY_KEY } from "@/constants/query"
import { createClient, deleteClients, updateClient } from "@/http/vocabularyClient"

import { useMessage } from "@/hook/useMessage"

export const useClientMutation = () => {
    const client = useQueryClient()
    const { Success, Error, Process } = useMessage(CLIENTS_QUERY_KEY)

    const onSuccess = () => {
        client.invalidateQueries([CLIENTS_QUERY_KEY]).then()
        Success()
    }

    const onMutate = () => {
        Process()
    }

    const onError = (e: any) => {
        Error(e)
    }

    const createMutation = useMutation({
        mutationFn: createClient,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
    })

    const updateMutation = useMutation({
        mutationFn: updateClient,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
    })

    const deleteMutation = useMutation({
        mutationFn: deleteClients,
        onSuccess: () => onSuccess(),
        onMutate: () => onMutate(),
        onError: (e) => onError(e)
    })

    return {
        createMutation,
        updateMutation,
        deleteMutation
    }
}
