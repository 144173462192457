export const paths = {
  applicant: "/applicants",
  applicantById: (id: number) => `/applicants/${id}`,
  complianceDocuments: "/compliance-documents",
  complianceDocumentById: (id: string) => `/compliance-documents/${id}`,
  epsmApplications: "/epsm-applications",
  epsmApplicationById: (id: string) => `/epsm-applications/${id}`,
  epsmApplicationCreate: () => "/epsm-applications/create",
  epsmApplicationEdit: (id: string) => `/epsm-applications/${id}/edit`,
  contractAnnexes: "/contract-annexes",
  contractAnnexById: (id: string) => `/contract-annexes/${id}`,
  agreements: "/agreements",
  agreementById: (id: string) => `/agreements/${id}`,
  contracts: "/contracts",
  contractById: (id: string) => `/contracts/${id}`,
  tradeMarks: "/trade-marks",
  tradeMarkById: (id: number) => `/trade-marks/${id}`,
  generalProductName: "/general-product-names",
  generalProductNameById: (id: number) => `/general-product-names/${id}`,
  productType: "/product-types",
  productTypeById: (id: number) => `/product-types/${id}`,
  productModel: "/product-models",
  productModelById: (id: number) => `/product-models/${id}`,
  productModification: "/product-modifications",
  productModificationById: (id: number) => `/product-modifications/${id}`,
  serialNumbers: "/serial-numbers",
  serialNumberById: (id: number) => `/serial-numbers/${id}`,
  manufacturers: "/manufacturers",
  formalizationRulesCategories: "/formalization-rules-categories",
  propulsionKinds: "/propulsion-kinds",
  bodyColors: "/body-colors",
  layoutPatterns: "/layout-patterns",
  engineIdNumberLocations: "/engine-id-number-locations",
  engineLocations: "/engine-locations",
  engineTypes: "/engine-types",
  cylinderArrangements: "/cylinder-arrangements",
  fuelKinds: "/fuel-kinds",
  fuelSystemTypes: "/fuel-system-types",
  energyStorageDeviceTypes: "/energy-storage-device-types",
  ignitionSystems: "/ignition-systems",
  transmissionTypes: "/transmission-types",
  gearboxTypes: "/gearbox-types",
  gearRatiosForEachGears: "/gear-ratios-for-each-gears",
  mainGearTypes: "/main-gear-types",
  electricMachineKinds: "/electric-machine-kinds",
  electricMachineTypes: "/electric-machine-types",
  steeringWheelPositions: "/steering-wheel-positions",
  manufacturerById: (id: number) => `/manufacturers/${id}`,
  representativesOfManufacturerApplicants: "/representatives-of-manufacturer-applicants",
  owners: "/owners",
  ownerById: (id: number) => `/owners/${id}`,
  certificationBodies: "/certification-bodies",
  certificationBodyById: (id: number) => `/certification-bodies/${id}`,
  clients: "/clients",
  clientById: (id: number) => `/clients/${id}`,
  authorizedOrganizations: "/authorized-organizations",
  authorizedOrganizationById: (id: number) => `/authorized-organizations/${id}`,
  authorizedOrganization: "/authorized-organizations",
  users: "/users",
  userById: (id: string) => `/users/${id}`,
  roles: "/roles",
  reports: "/reports",
  loadingDataToCreateEPSM: "/loading-data-to-create-epsm",
  uploadingApplicationForCreationEPSM: "/uploading-application-for-creation-epsm",
  logsLoadingDataToCreateEPSM: "/logs-loading-data-to-create-epsm",
  logsUploadingApplicationForCreationEPSM: "/logs-uploading-application-for-creation-epsm",
  elptsRequests: "/elpts-requests",
  main: "/main",
  authorizationError: "/authorization-error"
}
