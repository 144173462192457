import { IAuthor } from "./IAuthor"

import { IAuthorizedOrganization } from "@/types/IAuthorizedOrganization"
import { IClient } from "@/types/IClient"

export interface IContract {
    id: string
    number: string
    name: string
    date: string
    organization: IAuthorizedOrganization
    client: IClient
    author: IAuthor
    createdOn: string
    lastModifiedBy: IAuthor
    lastModifiedOn: string
}

export interface IContractCreate {
    name: string
    date: Date
    organizationId: number
    clientId: number
    jobGenitive: string
    requisites: string
    fullNameGenitive: string
    jobNominative: string
    fullNameNominative: string
}

export interface IContractUpdate extends IContractCreate {
    id: string
}

export interface IContractDelete {
    ids: string[]
}

export const CONTRACTS_KEY = "CONTRACTS_KEY"