import { FC } from "react"

import { Button, Descriptions, Empty, Skeleton, Space, Tag } from "antd"
import { Link, useNavigate, useParams } from "react-router-dom"

import classes from "./SerialNumberById.module.scss"

import { useSerialNumberByIdQuery } from "@/hook/Vocabularies/useSerialNumberQuery"

import { ISerialNumber } from "@/types/ISerialNumber"

import { paths } from "@/router/paths"

const SerialNumberById: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id?: string }>()

  const { data, isLoading, isError } = useSerialNumberByIdQuery(Number(id))

  const onBackHandler = () => {
    navigate(paths.serialNumbers)
  }

  if (isError) {
    return <Empty description="Серийный номер не найден" />
  }

  const getTitle = (serialNumber: ISerialNumber) => {
    return `Серийный номер продукции ${serialNumber.idNumber}${serialNumber.isDeleted ? " [УДАЛЕНО]" : ""}`
  }
  return (
    <Skeleton loading={isLoading}>
      {data && (
        <>
          <Descriptions title={getTitle(data.response)} layout="vertical" bordered></Descriptions>
          <Descriptions title="Идентификационные признаки самоходной машины (другого вида техники)" layout="vertical" bordered>
            <Descriptions.Item label="Торговая марка">
              <Tag>{data.response.tradeMark.name}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Коммерческое наименование (модель)">
              <Tag>{data.response.productModel.name}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Тип">
              <Tag>{data.response.productType.name}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Модификация">
              <Tag>{data.response.productModification.name}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Код ТНВЭД">
              <Tag>{data.response.tnvedCode}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Категория в соответствии с Правилами оформления ">
              {data.response.vehicleIdentification?.formalizationRulesCategory}
            </Descriptions.Item>
            <Descriptions.Item label="Категория в соответствии с ТР ТС 031/2012 или ТР ТС 010/2011 или ТР ТС 018/2011 ">
              {data.response.vehicleIdentification?.customsUnionRegulationCategory}
            </Descriptions.Item>
            <Descriptions.Item label="Номер документа «Заявление на создание ЭПСМ»">
              <Link to={paths.epsmApplicationById(data.response.epsmApplicationId)}>{data.response.epsmApplicationNumber}</Link>
            </Descriptions.Item>
            <Descriptions.Item label="Идентификационный номер / Заводской номер">{data.response.idNumber}</Descriptions.Item>
            <Descriptions.Item label="Наименование, определяемое назначением самоходной машины (другого вида техники) ">
              {data.response.generalProductNames && data.response.generalProductNames.map((name) => <Tag key={name.id}>{name.name}</Tag>)}
            </Descriptions.Item>
            <Descriptions.Item label="Номер двигателя">{data.response.vehicleIdentification?.engineIdNumber}</Descriptions.Item>
            <Descriptions.Item label="Номер кузова (кабины, прицепа, рамы) ">
              {data.response.vehicleIdentification?.bodyIdNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Номер коробки передач ">{data.response.vehicleIdentification?.gearboxIdNumber}</Descriptions.Item>
            <Descriptions.Item label="Номер основного ведущего моста ">
              {data.response.vehicleIdentification?.mainDriveAxleIdNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Тип движителя">{data.response.vehicleIdentification?.propulsionKind}</Descriptions.Item>
            <Descriptions.Item label="Признак комбинированного цвета">
              {data.response.vehicleIdentification?.isCombinedColor}
            </Descriptions.Item>
            <Descriptions.Item label="Цвет кузова (кабины, прицепа)">{data.response.vehicleIdentification?.bodyColor}</Descriptions.Item>
            <Descriptions.Item label="Дополнительный цвет 1, в случае комбинированного цвета">
              {data.response.vehicleIdentification?.complementaryColor1}
            </Descriptions.Item>
            <Descriptions.Item label="Дополнительный цвет 2, в случае комбинированного цвета">
              {data.response.vehicleIdentification?.complementaryColor2}
            </Descriptions.Item>
            <Descriptions.Item label="Наименование оттенка цвета кузова (кабины, прицепа)">
              {data.response.vehicleIdentification?.bodyColorShadeName}
            </Descriptions.Item>
            <Descriptions.Item label="Год производства">{data.response.vehicleIdentification?.manufactureYear}</Descriptions.Item>
            <Descriptions.Item label="Месяц производства">{data.response.vehicleIdentification?.manufactureMonth}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Маркировка" layout="vertical" bordered>
            <Descriptions.Item label="Место расположения таблички изготовителя ">
              {data.response.labeling?.manufacturerPlateLocation}
            </Descriptions.Item>
            <Descriptions.Item label="Место расположения идентификационного номера самоходной машины (другого вида техники)">
              {data.response.labeling?.vehicleIdNumberLocation}
            </Descriptions.Item>
            <Descriptions.Item label="Структура и содержание идентификационного номера самоходной машины (другого вида техники)">
              {data.response.labeling?.vehicleIdNumberStructureContent}
            </Descriptions.Item>
            <Descriptions.Item label="Место расположения номера двигателя">
              {data.response.labeling?.engineIdNumberLocation}
            </Descriptions.Item>
            <Descriptions.Item label="Структура и содержание номера двигателя">
              {data.response.labeling?.engineIdNumberStructureContent}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Общие характеристики самоходной машины (другого вида техники)" layout="vertical" bordered>
            <Descriptions.Item label="Количество осей/колес">
              {data.response.generalCharacteristics?.axleAndWheelQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Схема компоновки">{data.response.generalCharacteristics?.layoutPattern}</Descriptions.Item>
            <Descriptions.Item label="Рама">{data.response.generalCharacteristics?.frame}</Descriptions.Item>
            <Descriptions.Item label="Положение и размещение приводного двигателя (двигателей) ">
              {data.response.generalCharacteristics?.engineLocation}
            </Descriptions.Item>
            <Descriptions.Item label="Сведения о наличии реверсивного места оператора">
              {data.response.generalCharacteristics?.reversibleControlIndicator}
            </Descriptions.Item>
            <Descriptions.Item label="Исполнение загрузочного пространства">
              {data.response.generalCharacteristics?.carriageSpaceImplementation}
            </Descriptions.Item>
            <Descriptions.Item label="Пассажировместимость">{data.response.generalCharacteristics?.passengerQuantity}</Descriptions.Item>
            <Descriptions.Item label="Тип кузова/количество дверей">
              {data.response.generalCharacteristics?.bodyTypeAndDoorQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Тип кузова">
              {data.response.generalCharacteristics?.bodyType}
            </Descriptions.Item>
            <Descriptions.Item label="Количество дверей">
              {data.response.generalCharacteristics?.doorQuantity}
            </Descriptions.Item>
            <Descriptions.Item label="Длина, мм">{data.response.generalCharacteristics?.lengthMm}</Descriptions.Item>
            <Descriptions.Item label="Ширина, мм">{data.response.generalCharacteristics?.widthMm}</Descriptions.Item>
            <Descriptions.Item label="Высота, мм">{data.response.generalCharacteristics?.heightMm}</Descriptions.Item>
            <Descriptions.Item label="Дорожный просвет, мм">{data.response.generalCharacteristics?.groundClearanceMm}</Descriptions.Item>
            <Descriptions.Item label="База, мм">{data.response.generalCharacteristics?.wheelbaseMm}</Descriptions.Item>
            <Descriptions.Item label="Колея, мм">{data.response.generalCharacteristics?.trackGaugeMm}</Descriptions.Item>
            <Descriptions.Item label="Снаряженная масса, кг">{data.response.generalCharacteristics?.curbMassKg}</Descriptions.Item>
            <Descriptions.Item label="Технически допустимая максимальная масса самоходной машины, кг">
              {data.response.generalCharacteristics?.technicallyPermissibleMaxWeightKg}
            </Descriptions.Item>
            <Descriptions.Item label="Технически допустимая максимальная масса, приходящаяся на каждую ось, начиная с передней оси, кг">
              {data.response.generalCharacteristics?.technicallyPermissibleMaxWeightOnAxleKg}
            </Descriptions.Item>
            <Descriptions.Item label="Технически допустимая общая масса самоходной машины и прицепа, кг">
              {data.response.generalCharacteristics?.technicallyPermissibleOverallWeightWithTrailerKg}
            </Descriptions.Item>
            <Descriptions.Item label="Полезная нагрузка, кг">{data.response.generalCharacteristics?.payloadKg}</Descriptions.Item>
            <Descriptions.Item label="Буксировка прицепа, кг">{data.response.generalCharacteristics?.towableTrailer}</Descriptions.Item>
            <Descriptions.Item label="Технически допустимая статическая вертикальная нагрузка в точке сцепки тягово-сцепного устройства, кг">
              {data.response.generalCharacteristics?.hitchLoadKg}
            </Descriptions.Item>
            <Descriptions.Item label="Техническая допустимая буксируемая масса, кг">
              {data.response.generalCharacteristics?.permissibleTowableWeightKg}
            </Descriptions.Item>
            <Descriptions.Item label="Описание гибридной самоходной машины (другого вида техники)">
              {data.response.generalCharacteristics?.hybridVehicleDescription}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Двигатель" layout="vertical" bordered>
            <Descriptions.Item label="Наименование">{data.response.generalCharacteristics?.engine.presence}</Descriptions.Item>
            <Descriptions.Item label="Тип двигателя">{data.response.generalCharacteristics?.engine.brand}</Descriptions.Item>
            <Descriptions.Item label="Марка">{data.response.generalCharacteristics?.engine.type}</Descriptions.Item>
            <Descriptions.Item label="Тип">{data.response.generalCharacteristics?.engine.engineType}</Descriptions.Item>
            <Descriptions.Item label="Расположение цилиндров">
              {data.response.generalCharacteristics?.engine.cylinderArrangement}
            </Descriptions.Item>
            <Descriptions.Item label="Кол-во цилиндров">{data.response.generalCharacteristics?.engine.cylinderQuantity}</Descriptions.Item>
            <Descriptions.Item label="Степень сжатия">{data.response.generalCharacteristics?.engine.compressionRate}</Descriptions.Item>
            <Descriptions.Item label="Рабочий объем, см3">
              {data.response.generalCharacteristics?.engine.cylinderCapacitySm3}
            </Descriptions.Item>
            <Descriptions.Item label="Макс. Мощность, кВт">{data.response.generalCharacteristics?.engine.maxPowerKw}</Descriptions.Item>
            <Descriptions.Item label="Обороты (min/max), мин-1">
              {data.response.generalCharacteristics?.engine.rpmMinMax1}
            </Descriptions.Item>
            <Descriptions.Item label="Максимальный крутящий момент, Нм">
              {data.response.generalCharacteristics?.engine.maxTorqueNm}
            </Descriptions.Item>
            <Descriptions.Item label="Обороты (min/max), мин-1">
              {data.response.generalCharacteristics?.engine.rpmMinMax2}
            </Descriptions.Item>
            <Descriptions.Item label="Рабочее напряжение, В">
              {data.response.generalCharacteristics?.engine.operatingVoltage}
            </Descriptions.Item>
            <Descriptions.Item label="Максимальная 30-минутная мощность, кВт">
              {data.response.generalCharacteristics?.engine.max30MinPowerKw}
            </Descriptions.Item>
            <Descriptions.Item label="Вид топлива">{data.response.generalCharacteristics?.engine.fuelKind}</Descriptions.Item>
            <Descriptions.Item label="Тип системы питания">{data.response.generalCharacteristics?.engine.fuelSystemType}</Descriptions.Item>
            <Descriptions.Item label="Блок управления (маркировка)">
              {data.response.generalCharacteristics?.engine.controlUnitLabel}
            </Descriptions.Item>
            <Descriptions.Item label="Система зажигания">{data.response.generalCharacteristics?.engine.ignitionSystem}</Descriptions.Item>
            <Descriptions.Item label="Система выпуска и нейтрализации отработавших газов">
              {data.response.generalCharacteristics?.engine.exhaustSystem}
            </Descriptions.Item>
            <Descriptions.Item label="Устройство накопления энергии">
              {data.response.generalCharacteristics?.engine.energyStorageDevice}
            </Descriptions.Item>
            <Descriptions.Item label="Тип устройства накопления энергии">
              {data.response.generalCharacteristics?.engine.energyStorageDeviceType}
            </Descriptions.Item>
            <Descriptions.Item label="Место расположения устройства накопления энергии">
              {data.response.generalCharacteristics?.engine.energyStorageDeviceLocation}
            </Descriptions.Item>
            <Descriptions.Item label="Запас хода, км">{data.response.generalCharacteristics?.engine.rangeKm}</Descriptions.Item>
            <Descriptions.Item label="Другие приводные двигатели или комбинации двигателей">
              {data.response.generalCharacteristics?.engine.otherDriveEnginesOrCombinations}
            </Descriptions.Item>
            <Descriptions.Item label="Номинальное напряжение">
              {data.response.generalCharacteristics?.engine.nominalVoltage}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Шины" layout="vertical" bordered>
            <Descriptions.Item label="Размерность">{data.response.generalCharacteristics?.tires.size}</Descriptions.Item>
            <Descriptions.Item label="Индекс несущей способности для максимально допустимой нагрузки">
              {data.response.generalCharacteristics?.tires.loadCapacityIndexForMaxPermissibleLoad}
            </Descriptions.Item>
            <Descriptions.Item label="Скоростная категория">{data.response.generalCharacteristics?.tires.speedCategory}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Трансмиссия" layout="vertical" bordered>
            <Descriptions.Item label="Наименование">{data.response.generalCharacteristics?.transmission.name}</Descriptions.Item>
            <Descriptions.Item label="Тип">{data.response.generalCharacteristics?.transmission.type}</Descriptions.Item>
            <Descriptions.Item label="Схема трансмиссии">{data.response.generalCharacteristics?.transmission.scheme}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Коробка передач" layout="vertical" bordered>
            <Descriptions.Item label="Наименование">{data.response.generalCharacteristics?.gearbox.name}</Descriptions.Item>
            <Descriptions.Item label="Марка">{data.response.generalCharacteristics?.gearbox.brand}</Descriptions.Item>
            <Descriptions.Item label="Тип">{data.response.generalCharacteristics?.gearbox.type}</Descriptions.Item>
            <Descriptions.Item label="Маркировка">{data.response.generalCharacteristics?.gearbox.label}</Descriptions.Item>
            <Descriptions.Item label="Число передач вперёд/назад">
              {data.response.generalCharacteristics?.gearbox.gearQuantityForwardAndReverse}
            </Descriptions.Item>
            <Descriptions.Item label="Передаточные числа для каждой передачи">
              {data.response.generalCharacteristics?.gearbox.gearRatiosForEachGear}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Раздаточная коробка" layout="vertical" bordered>
            <Descriptions.Item label="Наименование">{data.response.generalCharacteristics?.transferCase.name}</Descriptions.Item>
            <Descriptions.Item label="Марка">{data.response.generalCharacteristics?.transferCase.brand}</Descriptions.Item>
            <Descriptions.Item label="Тип">{data.response.generalCharacteristics?.transferCase.type}</Descriptions.Item>
            <Descriptions.Item label="Маркировка">{data.response.generalCharacteristics?.transferCase.label}</Descriptions.Item>
            <Descriptions.Item label="Число передач">{data.response.generalCharacteristics?.transferCase.gearQuantity}</Descriptions.Item>
            <Descriptions.Item label="Передаточные отношения">
              {data.response.generalCharacteristics?.transferCase.gearRatios}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Главная передача" layout="vertical" bordered>
            <Descriptions.Item label="Наименование">{data.response.generalCharacteristics?.mainGear.name}</Descriptions.Item>
            <Descriptions.Item label="Марка">{data.response.generalCharacteristics?.mainGear.brand}</Descriptions.Item>
            <Descriptions.Item label="Распределение по осям">{data.response.generalCharacteristics?.mainGear.type}</Descriptions.Item>
            <Descriptions.Item label="Тип">{data.response.generalCharacteristics?.mainGear.label}</Descriptions.Item>
            <Descriptions.Item label="Маркировка">{data.response.generalCharacteristics?.mainGear.axisDistribution}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Вал отбора мощности" layout="vertical" bordered>
            <Descriptions.Item label="Наименование">{data.response.generalCharacteristics?.powerTakeOff.name}</Descriptions.Item>
            <Descriptions.Item label="Тип">{data.response.generalCharacteristics?.powerTakeOff.type}</Descriptions.Item>
            <Descriptions.Item label="Расположение">{data.response.generalCharacteristics?.powerTakeOff.location}</Descriptions.Item>
            <Descriptions.Item label="Частота вращения">
              {data.response.generalCharacteristics?.powerTakeOff.rotationFrequency}
            </Descriptions.Item>
            <Descriptions.Item label="Отношение к частоте вращения двигателя вала отбора мощности ">
              {data.response.generalCharacteristics?.powerTakeOff.rotationFrequencyRate}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Электромашина" layout="vertical" bordered>
            <Descriptions.Item label="Наименование">{data.response.generalCharacteristics?.electricMachine.name}</Descriptions.Item>
            <Descriptions.Item label="Вид электромашины ">{data.response.generalCharacteristics?.electricMachine.kind}</Descriptions.Item>
            <Descriptions.Item label="Марка">{data.response.generalCharacteristics?.electricMachine.brand}</Descriptions.Item>
            <Descriptions.Item label="Тип">{data.response.generalCharacteristics?.electricMachine.type}</Descriptions.Item>
            <Descriptions.Item label="Рабочее напряжение">
              {data.response.generalCharacteristics?.electricMachine.operatingVoltage}
            </Descriptions.Item>
            <Descriptions.Item label="Максимальная 30-минутная мощность">
              {data.response.generalCharacteristics?.electricMachine.max30MinPowerKw}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Подвеска" layout="vertical" bordered>
            <Descriptions.Item label="Наименование">{data.response.generalCharacteristics?.suspension.name}</Descriptions.Item>
            <Descriptions.Item label="Передняя (описание)">
              {data.response.generalCharacteristics?.suspension.frontDescription}
            </Descriptions.Item>
            <Descriptions.Item label="Задняя (описание)">
              {data.response.generalCharacteristics?.suspension.rearDescription}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="Рулевое управление" layout="vertical" bordered>
            <Descriptions.Item label="Положение рулевого колеса">
              {data.response.generalCharacteristics?.steering.wheelPosition}
            </Descriptions.Item>
            <Descriptions.Item label="Описание">{data.response.generalCharacteristics?.steering.description}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Тормозные системы" layout="vertical" bordered>
            <Descriptions.Item label="Наименование">{data.response.generalCharacteristics?.brakingSystem.name}</Descriptions.Item>
            <Descriptions.Item label="Рабочая">{data.response.generalCharacteristics?.brakingSystem.service}</Descriptions.Item>
            <Descriptions.Item label="Запасная">{data.response.generalCharacteristics?.brakingSystem.spare}</Descriptions.Item>
            <Descriptions.Item label="Вспомогательная (износостойкая)">
              {data.response.generalCharacteristics?.brakingSystem.secondary}
            </Descriptions.Item>
            <Descriptions.Item label="Стояночная">{data.response.generalCharacteristics?.brakingSystem.parking}</Descriptions.Item>
            <Descriptions.Item label="Макс. Скорость">{data.response.generalCharacteristics?.brakingSystem.maxSpeed}</Descriptions.Item>
          </Descriptions>
          <Space className={classes.buttons}>
            <Button onClick={onBackHandler}>Назад к списку</Button>
          </Space>
        </>
      )}
    </Skeleton>
  )
}

export default SerialNumberById
