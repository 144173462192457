import { Key, useState } from "react"

import { ArrowsAltOutlined, EyeInvisibleOutlined } from "@ant-design/icons"
import { Modal, Tabs } from "antd"

import { FieldOrder } from "@/components/Table/FieldOrder"
import { FieldVisibility } from "@/components/Table/FieldVisibility"


import { ITableColumn } from "@/types/ui"


interface ColumnsSettingProps<T> {
  open: boolean
  onCancel: () => void
  onSubmit: (updatedColumns: ITableColumn<T>[]) => void
  columns: ITableColumn<T>[]
}

export const ColumnsSetting = <T extends object>(props: ColumnsSettingProps<T>) => {
  const { open, columns, onSubmit, onCancel } = props

  const [updatedColumns, setUpdatedColumns] = useState<ITableColumn<T>[]>(columns)

  const toggleColumnVisible = (key: Key) => {
    setUpdatedColumns((prevState) => prevState.map((item) => (item.key === key ? { ...item, isVisible: !item.isVisible } : item)))
  }


  const submitHandler = () => {
    onSubmit(updatedColumns)
  }

  return (
    <Modal
      open={open}
      centered={true}
      onCancel={onCancel}
      title="Настроики столбцов"
      okText="Сохранить и перезагрузить страницу"
      cancelText="Отмена"
      onOk={submitHandler}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane
          key="1"
          tab={
            <span>
              <EyeInvisibleOutlined />
              Видимость столбцов
            </span>
          }
        >
          <FieldVisibility columns={updatedColumns} toggleColumnVisible={toggleColumnVisible} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            <span>
              <ArrowsAltOutlined />
              Порядок столбцов
            </span>
          }
        >
          <FieldOrder columns={updatedColumns} onUpdateColumns={setUpdatedColumns} />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
}
