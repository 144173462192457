export const OGRN_LENGTH = 13
export const OGRNIP_LENGTH = 15
export const INN_UL_LENGTH = 10
export const INN_IP_LENGTH = 12
export const SNILS_LENGTH = 11
export const KPP_LENGTH = 9
export const INDEX_LENGTH = 6
export const BASE_ADDRESS_FIELDS_LENGTH = 120
export const BUILDING_NUMBER_LENGTH = 50
export const ROOM_NUMBER_LENGTH = 20
export const BANK_ACCOUNT_LENGTH = 20
export const CORRESPONDENT_ACCOUNT_LENGTH = 20
export const BIK_LENGTH = 9
export const PHONE_NUMBER_LENGTH = 15