import { OidcClient } from "@axa-fr/oidc-client"
import axios from "axios"

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
})

client.interceptors.request.use(
  (config) => {
    const { tokens } = OidcClient.get()

    if (tokens.accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${tokens.accessToken}`
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const status = error.response?.status

    if (status === 401) {
      const oidc = OidcClient.get()
      oidc.logoutAsync()
    }

    return Promise.reject(error)
  }
)
