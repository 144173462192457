import { useEffect, useState } from "react"

import dayjs from "dayjs"

import { FieldData } from "@/components/FilterWrapper"
import { YesOrNo } from "@/constants/validValues"

import { useContractQuery } from "@/hook/Contracts/useContractQuery"
import { useFilterStorage } from "@/hook/useFilterStorage"
import { useAuthorizedOrganizationQuery } from "@/hook/Vocabularies/useAuthorizedOrganizationQuery"
import { useClientQuery } from "@/hook/Vocabularies/useClientQuery"
import { useUserQuery } from "@/hook/Vocabularies/useUserQuery"

import { CONTRACT_ANNEXES_KEY } from "@/types/IContractAnnex"
import { FilterField, FilterFieldOperator, FilterFieldType, IFilterField } from "@/types/ui"

const contractAnnexesFilters: IFilterField[] = [
    {
        text: "Внутренний номер",
        name: "Number",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "Номер приложения",
        name: "Name",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "Дата закрытия",
        name: "ClosedDate",
        type: FilterFieldType.DateRange
    },
    {
        text: "Дата приложения",
        name: "Date",
        type: FilterFieldType.DateRange
    },
    {
        text: "Кол-во паспортов",
        name: "PassportCount",
        type: FilterFieldType.Number,
        operator: FilterFieldOperator.Equal
    },
    {
        text: "Выполнено",
        name: "RegisteredCount",
        type: FilterFieldType.Number,
        operator: FilterFieldOperator.Equal
    },
    {
        text: "В отчете",
        name: "ReadyCount",
        type: FilterFieldType.Number,
        operator: FilterFieldOperator.Equal
    },
    {
        text: "Сумма, руб.",
        name: "Amount",
        type: FilterFieldType.Number,
        operator: FilterFieldOperator.Equal
    },
    {
        text: "Стоимость итого",
        name: "TotalPrice",
        type: FilterFieldType.Number,
        operator: FilterFieldOperator.Equal
    },
    {
        text: "Дата договора",
        name: "ContractDate",
        type: FilterFieldType.DateRange
    }
]

const yesOrNoValues = YesOrNo.map(value => ({ 
    id: value === YesOrNo[0] ? "true" : "false", name: value }
))

export const useContractAnnexesFilters = () => {
    const [filters, setFilters] = useState<FilterField[]>([])

    const { getFilter } = useFilterStorage({
        key: CONTRACT_ANNEXES_KEY
    })

    const oldFilters: FieldData[] = getFilter()

    const { data: contracts } = useContractQuery({
        OrderBy: "name asc"
      })
    const { data: organizations } = useAuthorizedOrganizationQuery({
        OrderBy: "name asc"
      })
    const { data: users } = useUserQuery({
        OrderBy: "lastName asc"
      })
    const { data: clients } = useClientQuery({
        OrderBy: "name asc"
      })

    useEffect(() => {
        setFilters([
            ...contractAnnexesFilters,
            {
                text: "Номер договора",
                name: "ContractId",
                type: FilterFieldType.Select,
                values: contracts?.response.data
            },
            {
                text: "Организация",
                name: "Organization",
                type: FilterFieldType.Select,
                values: organizations?.response.data
            },
            {
                text: "Клиент",
                name: "Client",
                type: FilterFieldType.Select,
                values: clients?.response.data
            },
            {
                text: "Автор",
                name: "AuthorId",
                type: FilterFieldType.Select,
                values: users?.response.data.map(m => ( { id: m.id, name: `${m.lastName} ${m.firstName}` } ))
            },
            {
                text: "Подписано",
                name: "IsSigned",
                type: FilterFieldType.Select,
                values: yesOrNoValues
            },
            {
                text: "Оплачено",
                name: "IsPaid",
                type: FilterFieldType.Select,
                values: yesOrNoValues
            },
            {
                text: "Закрыто",
                name: "IsClosed",
                type: FilterFieldType.Select,
                values: yesOrNoValues
            }
        ])
    }, [contracts, organizations, users, clients])

    return {
        filters: filters.map(f => {
            const currentFilter = oldFilters.find(i => (i.name as string[])[0] === f.name)

            if (!currentFilter || !currentFilter.value) {
                return f
            }

            switch (f.type) {
                case FilterFieldType.Date:
                    return {
                        ...f, value: dayjs(new Date(currentFilter.value as string))
                    }
                case FilterFieldType.DateRange:
                    return {
                        ...f, value: [
                            dayjs(new Date(currentFilter.value[0] as string)),
                            dayjs(new Date(currentFilter.value[1] as string))
                        ]
                    }
                default:
                    return {
                        ...f, value: currentFilter.value
                    }
            }
        })
    }
}
