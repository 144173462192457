import { Tag } from "antd"

import { EpsmApplicationStatus, IEpsmApplication } from "@/types/IEpsmApplication"
import { ITableColumn } from "@/types/ui"

const EMPTY_DATA = "Нет"

const statusColorMap: Record<number, string> = {
    0: "#d9d9d9",
    1: "#ffff00",
    2: "#00b0f0",
    3: "#7030a0",
    4: "#ff0000",
    5: "#00b050",
    6: "#000000",
    7: "#000000",
    8: "#7030a0"
  }

export const InnerEpsmApplicationsColumns: ITableColumn<IEpsmApplication>[] = [
    {
      title: "Внутренний номер",
      key: "number",
      dataIndex: ["number"],
      align: "center",
      width: 180,
      sorter: true,
      isVisible: true,
      order: 1
    },
    {
      title: "Автор",
      key: "author",
      dataIndex: ["author"],
      align: "center",
      width: 190,
      sorter: true,
      sorterField: "authorId",
      render: (_, { author }) => <span key={author.id}>{author.fullName ?? EMPTY_DATA}</span>,
      isVisible: true,
      order: 2
    },
    {
      title: "Статус",
      key: "status",
      dataIndex: ["status"],
      align: "center",
      width: 120,
      sorter: true,
      render: (_, { status }) =>
          <Tag color={statusColorMap[status.id]}
               style={{ color: status.id === EpsmApplicationStatus.InWork ? "black" : "white" }}>
            {status.value}
          </Tag>,
      isVisible: true,
      order: 3
    },
    {
      title: "Номер ЭПСМ",
      key: "passportNumber",
      dataIndex: ["passportNumber"],
      align: "center",
      width: 150,
      sorter: true,
      isVisible: true,
      order: 4
    },
    {
      title: "Серийный номер",
      key: "serialNumber",
      dataIndex: ["serialNumber"],
      align: "center",
      width: 200,
      sorter: true,
      isVisible: true,
      order: 5
    }
  ]
  