import React, { FC, ReactNode } from "react"

import { Typography } from "antd"

import classes from "./TabForm.module.scss"

const { Text } = Typography

interface TabFormProps {
  isError: boolean
  children: ReactNode
}

export const TabForm: FC<TabFormProps> = (props) => {
  const { children, isError } = props

  return (
    <div className={classes["tab-label"]}>
      <Text className={isError ? "" : classes.valid} type={isError ? "danger" : undefined}>
        {children}
      </Text>
    </div>
  )
}
