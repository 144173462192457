import { FC, useState } from "react"

import { BarsOutlined, ProfileOutlined } from "@ant-design/icons"
import { Button, Descriptions, Empty, Skeleton, Space, Spin, Tabs, TabsProps } from "antd"
import { useNavigate, useParams, Link } from "react-router-dom"

import { InnerEpsmApplicationsColumns } from "@/components/ContractAnnex/InnerEpsmApplication/InnerEpsmApplicationsColumns"
import { Table } from "@/components/Table"
import { Resource } from "@/constants/permission"
import { printContractAnnex } from "@/http/contractAnnex"
import { getEpsmApplicationExcel } from "@/http/epsmApplications"

import classes from "./ContractAnnexById.module.scss"

import { useContractAnnexByIdQuery } from "@/hook/ContractAnnexes/useContractAnnexQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"
import { useTable } from "@/hook/useTable"

import { EPSM_APPLICATIONS_KEY, EPSM_APPLICATIONS_IN_CONTRACT_ANNEX_KEY, IEpsmApplication } from "@/types/IEpsmApplication"

import { paths } from "@/router/paths"

import { formatDate, formatDateWithTime } from "@/utils/formatDate"
import { formatNumber } from "@/utils/formatNumber"
import { getInitialColumns } from "@/utils/getInitialColumns"

const ContractAnnexById: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams<{ id?: string }>()

    const { data, isLoading, isError } = useContractAnnexByIdQuery(id ?? "")

    const applicationInitialColumns = getInitialColumns(InnerEpsmApplicationsColumns, EPSM_APPLICATIONS_IN_CONTRACT_ANNEX_KEY)

    const [applicationPageSize, setApplicationPageSize] = useState(10)

    const { columns, order } = useTable<IEpsmApplication>({
        initialColumns: applicationInitialColumns
    })

    const [isPrintLoading, setIsPrintLoading] = useState(false)
    const { download: printDownload } = useDownloadFile(() => printContractAnnex(id ?? ""))

    const EMPTY_DATA = "Нет"

    const { download: excelDownload, isLoading: isExcelLoading } = useDownloadFile(() => getEpsmApplicationExcel({
        Filter: "ContractAnnex = " + id,
        OrderBy: order.orderBy
    }, getInitialColumns(applicationInitialColumns, EPSM_APPLICATIONS_KEY)))

    const onExcelDownloadHandler = async () => {
        await excelDownload()
    }

    const onPrintHandler = async () => {
        setIsPrintLoading(true)
        await printDownload()
        setIsPrintLoading(false)
    }

    const onBackHandler = () => {
        navigate(paths.contractAnnexes)
    }

    if (isError) {
        return <Empty description="Приложение к договору не найдено" />
    }

    const MOCK_DATA = "Не указано"

    const onApplicationRowClickHandler = (data: IEpsmApplication) => {
        navigate(paths.epsmApplicationById(data.id))
    }

    const onChangeApplicationSize = (current: number, size: number) => {
        setApplicationPageSize(size)
    }

    if(!data){
        return <Spin/>
    }

    const tabsItems: TabsProps["items"] = [
        {
            key: "1",
            label: (
                <span>
                    <ProfileOutlined /> Приложение к договору
                </span>
            ),
            children: (
                <>
                    <Descriptions
                    title={
                        <div className={classes.header}>
                            <span>Приложение к договору {data.response.number}{data.response.isDeleted && " [УДАЛЕНО]"}</span>
                            <Space>
                                <Button
                                type='primary'
                                style={{ minWidth: "100px" }}
                                disabled={isPrintLoading || data.response.isDeleted}
                                onClick={onPrintHandler}
                                >
                                    Печать
                                </Button>
                            </Space>
                        </div>
                    }
                    layout="vertical"
                    bordered 
                    column={3}
                    className={classes.descriptions}
                    >
                        <Descriptions.Item label="Номер договора" style={{ width: "33%" }}>
                            <Link to={paths.contractById(data.response.contract ? data.response.contract.id : "")}>{data.response.contract?.name}</Link>
                        </Descriptions.Item>
                        <Descriptions.Item label="Дата договора" style={{ width: "33%" }}>
                            {data.response.contract?.date && formatDate(data.response.contract?.date.toString())}
                        </Descriptions.Item>
                        <Descriptions.Item label="Уполномоченная организация" style={{ width: "33%" }}>
                            {data.response.organization?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Номер приложения">{data.response.name}</Descriptions.Item>
                        <Descriptions.Item label="Дата приложения">{formatDate(data.response.date.toString())}</Descriptions.Item>
                        <Descriptions.Item label="Автор">{data.response.author.fullName ?? EMPTY_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Количество паспортов">{data.response.passportCount}</Descriptions.Item>
                        <Descriptions.Item label="Выполнено">{data.response.registeredCount}</Descriptions.Item>
                        <Descriptions.Item label="В отчете">{data.response.readyCount}</Descriptions.Item>
                        <Descriptions.Item label="Сумма, руб.">{formatNumber(data.response.amount)}</Descriptions.Item>
                        <Descriptions.Item label="Наименование Клиента">{data.response.client?.name}</Descriptions.Item>
                        <Descriptions.Item label="ИНН Клиента">{data.response.client?.inn}</Descriptions.Item>
                        <Descriptions.Item label="Подписано">{data.response.isSigned ? "Да" : "Нет"}</Descriptions.Item>
                        <Descriptions.Item label="Оплачено">{data.response.isPaid ? "Да" : "Нет"}</Descriptions.Item>
                        <Descriptions.Item label="Закрыто">
                            {data.response.isClosed ? "Да" : "Нет"}
                            {data.response.closedDate && ` (${formatDate(data.response.closedDate.toString())})`}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions layout="vertical" bordered column={6}>
                        <Descriptions.Item label="Дата создания">
                            {data.response.createdOn && formatDateWithTime(data.response.createdOn)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Автор создания">
                            {data.response.author.fullName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Дата изменения">
                            {data.response.lastModifiedOn && formatDateWithTime(data.response.lastModifiedOn)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Автор изменения">
                            {data.response.lastModifiedBy.fullName}
                        </Descriptions.Item>
                        {data.response.isDeleted && 
                            <>
                                <Descriptions.Item label="Дата удаления">
                                    {data.response.deletedOn && formatDateWithTime(data.response.deletedOn)}
                                </Descriptions.Item>
                                <Descriptions.Item label="Автор удаления">
                                    {data.response.deletedBy.fullName}
                                </Descriptions.Item>
                            </>   
                        }
                    </Descriptions>

                    <br/>
                    <Descriptions title="Печатные реквизиты" layout="horizontal" bordered column={1} className={classes.descriptions}>
                        <Descriptions.Item label="Должность подписанта в род.падеже">{data.response.jobGenitive ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Реквизиты Документа на право подписи">{data.response.requisites ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="ФИО подписанта в род.падеже">{data.response.fullNameGenitive ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Должность подписанта в им.падеже">{data.response.jobNominative ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Фамилия И.О. подписанта в им.падеже">{data.response.fullNameNominative ?? MOCK_DATA}</Descriptions.Item>
                    </Descriptions>

                    <Descriptions title="Услуги" layout="horizontal" bordered column={1}>
                        <Descriptions.Item label="Подготовка комплекта документов для подачи заявления в МинПромТорг (включая доставку) с целью оформления ЭПСМ:">
                            {data.response.preparationPrice ?? 0} руб.
                        </Descriptions.Item>
                        <Descriptions.Item label={"Внесение документа об оценке соответствия в реестр АС СЭП:"}>
                            {data.response.complianceDocumentsList ?? "-"}
                        </Descriptions.Item>
                        <Descriptions.Item label=" ">
                            {data.response.registrationPrice ?? 0} руб.
                        </Descriptions.Item>
                        <Descriptions.Item label="Оформление электронных паспортов самоходных машин:">
                            {data.response.formalizationPrice ?? 0} руб.
                        </Descriptions.Item>
                        <Descriptions.Item label="Корректировка электронных паспортов самоходных машин:">
                            {data.response.correctionPrice ?? 0} руб.
                        </Descriptions.Item>
                        <Descriptions.Item label="Аннулирование электронных паспортов самоходных машин:">
                            {data.response.cancellationPrice ?? 0} руб.
                        </Descriptions.Item>
                        <Descriptions.Item label="Итого:">
                            <b>{data.response.totalPrice ?? 0} руб.</b>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions title=" " layout="vertical" bordered className={classes.descriptions}>
                        <Descriptions.Item label="Дополнительные условия">
                            {data.response.additionalConditions ?? "-"}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            )
        },
        {
            key: "2",
            label: (
                <span>
                    <BarsOutlined/> Заявления к приложению
                </span>
            ),
            children: (
                <Table
                    hasSelection={false}
                    columns={columns.data}
                    dataSource={data.response.applications}
                    isLoading={isLoading}
                    rowKey="id"
                    onRowClick={onApplicationRowClickHandler}
                    permission={{
                        resource: Resource.EpsmApplication
                    }}
                    pagination={{
                        pageSize: applicationPageSize,
                        onPageSizeChange: onChangeApplicationSize
                    }}
                    onSorterChange={order.setOrderBy}
                    download={{
                        isLoading: isExcelLoading,
                        onDownload: onExcelDownloadHandler
                      }}
                />
            )
        }
    ]

    return (
        <Skeleton loading={isLoading}>
            {data && (
                <>
                    <Tabs items={tabsItems}/>

                    <Space className={classes.buttons}>
                        <Button onClick={onBackHandler}>Назад к списку</Button>
                    </Space>
                </>
            )}
        </Skeleton>
    )
}

export default ContractAnnexById
