import { FC, useState } from "react"

import { PlusOutlined } from "@ant-design/icons"
import { message, Modal, Upload, UploadFile } from "antd"
import { RcFile, UploadProps } from "antd/es/upload/interface"
import { uid } from "uid"

import { getBase64 } from "@/utils/getBase64"

interface PicturesUploadProps {
  fileList?: UploadFile[];
  onChangeHandler?: (file: UploadFile | UploadFile[]) => void;
  maxCount?: number;
  readonly?: boolean;
}

export const PicturesUpload: FC<PicturesUploadProps> = (props) => {
  const { fileList, onChangeHandler, readonly, maxCount = 6 } = props

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState("")
  const [previewTitle, setPreviewTitle] = useState("")

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1))
  }

  const handleCancel = () => setPreviewOpen(false)

  const beforeUpload = async (file: RcFile) => {
    const maxFileSize = 502
    if (file.size / 1024 > maxFileSize) {
      message.open({
        key: file.name,
        type: "error",
        content: `Максимальный размер файла ${maxFileSize} Кб!`,
        duration: 3
      })

      return false
    }

    return true
  }

  const uploadHandler: UploadProps["customRequest"] = async (options) => {
    const { onSuccess, onError, file } = options
    try {
      const content = await getBase64(file as RcFile)
      onChangeHandler?.({
        url: content,
        name: (file as RcFile).name,
        uid: uid()
      })
      onSuccess?.("Ok")
    } catch (e: any) {
      onError?.(e)
    }
  }

  const removeHandler = (file: UploadFile) => {
    if (fileList?.length) {
      const newFileList = fileList.filter(item => item.uid !== file.uid)
      onChangeHandler?.(newFileList)
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined/>
      <div style={{ marginTop: 8 }}>Добавить</div>
    </div>
  )

  return (
    <>
      <Upload
        fileList={fileList}
        accept=".png,.jpg,.jpeg"
        listType="picture-card"
        multiple
        maxCount={maxCount}
        customRequest={uploadHandler}
        onRemove={removeHandler}
        beforeUpload={beforeUpload}
        onPreview={handlePreview}
        showUploadList={{
          showRemoveIcon: !readonly
        }}
      >
        {readonly || (fileList && fileList.length >= maxCount) ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage}/>
      </Modal>
    </>
  )
}
