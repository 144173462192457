import { FC } from "react"

import { OIDC_LOGIN_DEFAULT } from "@/constants/oidcStorageKeys"

import { paths } from "@/router/paths"

const AuthorizationCallBackSuccess: FC = () => {

  // permissions еще не получены на данный момент
  // просто проверяем, не "запомнилась" ли страница "ошибки доступа"
  const authorizationRedirectProps = JSON.parse(localStorage.getItem(OIDC_LOGIN_DEFAULT) ?? "")

  if (authorizationRedirectProps.callbackPath === paths.authorizationError) {
    authorizationRedirectProps.callbackPath = paths.main
    localStorage.setItem(OIDC_LOGIN_DEFAULT, JSON.stringify(authorizationRedirectProps))
  }
  return (<p>Авторизация прошла успешно</p>)
}
export default AuthorizationCallBackSuccess