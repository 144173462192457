import { FC, useState } from "react"

import { Button, Descriptions, Empty, Skeleton, Space } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import { printContract } from "@/http/contract"

import classes from "./ContractByIdPage.module.scss"

import { useContractByIdQuery } from "@/hook/Contracts/useContractQuery"
import { useDownloadFile } from "@/hook/useDownloadFile"

import { paths } from "@/router/paths"

import { formatDate, formatDateWithTime } from "@/utils/formatDate"

const ContractByIdPage: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams<{ id?: string }>()

    const { data, isLoading, isError } = useContractByIdQuery(id ?? "")

    const [isPrintLoading, setIsPrintLoading] = useState(false)
    const { download: printDownload } = useDownloadFile(() => printContract(id ?? ""))

    const EMPTY_DATA = "Нет"

    const onCreateContractAnnexHandler = async () => {
        navigate(paths.contractAnnexes, { state: { id: id } })
    }

    const onPrintHandler = async () => {
        setIsPrintLoading(true)
        await printDownload()
        setIsPrintLoading(false)
    }

    const onBackHandler = () => {
        navigate(paths.contracts)
    }

    if (isError) {
        return <Empty description="Договор не найден" />
    }

    const MOCK_DATA = "Не указано"

    return (
        <Skeleton loading={isLoading}>
            {data && (
                <>
                    <Descriptions
                        title={
                            <div className={classes.header}>
                                <span>Договор {data.response.number}</span>
                                <Space>
                                    <Button
                                        style={{ minWidth: "100px" }}
                                        onClick={onCreateContractAnnexHandler}
                                    >
                                        Создать Приложение к Договору
                                    </Button>
                                    <Button
                                        type='primary'
                                        style={{ minWidth: "100px" }}
                                        disabled={isPrintLoading}
                                        onClick={onPrintHandler}
                                    >
                                        Печать
                                    </Button>
                                </Space>
                            </div>
                        } 
                        layout="vertical" 
                        bordered column={3} 
                        className={classes.descriptions}
                    >
                        <Descriptions.Item label="Номер договора">{data.response.name}</Descriptions.Item>
                        <Descriptions.Item label="Дата договора">{formatDate(data.response.date.toString())}</Descriptions.Item>
                        <Descriptions.Item label="Автор">{data.response.author.fullName ?? EMPTY_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Уполномоченная организация">{data.response.organization?.name}</Descriptions.Item>
                        <Descriptions.Item label="Наименование Клиента">{data.response.client.name}</Descriptions.Item>
                        <Descriptions.Item label="ИНН Клиента">{data.response.client.inn}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Печатные реквизиты" layout="horizontal" bordered column={1} className={classes.descriptions}>
                        <Descriptions.Item label="Должность подписанта в род.падеже">{data.response.client?.jobGenitive ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Реквизиты Документа на право подписи">{data.response.client?.requisitesGenitive ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="ФИО подписанта в род.падеже">{data.response.client?.fullNameGenitive ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Должность подписанта в им.падеже">{data.response.client?.jobNominative ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Фамилия И.О. подписанта в им.падеже">{data.response.client?.fullNameNominative ?? MOCK_DATA}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout="vertical" bordered column={4}>
                        <Descriptions.Item label="Дата создания" style={{ width: "25%" }}>
                            {data.response.createdOn && formatDateWithTime(data.response.createdOn)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Автор создания" style={{ width: "25%" }}>
                            {data.response.author.fullName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Дата изменения" style={{ width: "25%" }}>
                            {data.response.lastModifiedOn && formatDateWithTime(data.response.lastModifiedOn)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Автор изменения" style={{ width: "25%" }}>
                            {data.response.lastModifiedBy.fullName}
                        </Descriptions.Item>
                    </Descriptions>
                    <br/>
                    <Space className={classes.buttons}>
                        <Button onClick={onBackHandler}>Назад к списку</Button>
                    </Space>
                </>
            )}
        </Skeleton>
    )
}

export default ContractByIdPage
