import { createContext, FC, useEffect, useState } from "react"

import { useOidcUser } from "@axa-fr/react-oidc"
import { Spin } from "antd"

import { getUserById } from "@/http/user"

import { IRole } from "@/types/IRole"

const RoleContext = createContext<IRole[]>([])

const RoleContextProvider: FC<any> = ({ children }) => {

    const { oidcUser } = useOidcUser()

    const [roles, setRoles] = useState<IRole[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {

        if (oidcUser) {
            getUserById(oidcUser.sub)
                .then(resp => {
                    setRoles(resp.response.roles ?? [])
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            setRoles([])
            setIsLoading(false)
        }

    }, [oidcUser])

    if(isLoading) return (
        <Spin></Spin>
    )

    return (
        <RoleContext.Provider value={roles}>
            {children}
        </RoleContext.Provider>
    )
}
export { RoleContext, RoleContextProvider }
