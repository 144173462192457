import React, { FC } from "react"

import { Navigate, Route, Routes } from "react-router-dom"

import { Resource, Scope } from "@/constants/permission"
import {
  BodyColors,
  CylinderArrangements,
  ElectricMachineKinds, ElectricMachineTypes,
  EnergyStorageDeviceTypes,
  EngineIdNumberLocations,
  EngineLocations,
  EngineTypes,
  FormalizationRulesCategories,
  FuelKinds,
  FuelSystemTypes,
  GearboxTypes,
  GearRatiosForEachGears,
  IgnitionSystems,
  LayoutPatterns,
  MainGearTypes,
  PropulsionKinds,
  SteeringWheelPositions,
  TransmissionTypes
} from "@/constants/validValues"
import { MainLayout } from "@/layout/MainLayout"
import { AgreementByIdPage } from "@/pages/AgreementById"
import { AgreementsPage } from "@/pages/Agreements"
import { ApplicantById } from "@/pages/ApplicantById"
import { ApplicantPage } from "@/pages/Applicants"
import { AuthorizationError } from "@/pages/AuthorizationError"
import { AuthorizedOrganizationPage } from "@/pages/AuthorizedOrganization"
import AuthorizedOrganizationById from "@/pages/AuthorizedOrganizationById/AuthorizedOrganizationById"
import { CertificationBodiesPage } from "@/pages/CertificationBodies"
import CertificationBodyById from "@/pages/CertificationBodyById/CertificationBodyById"
import ClientById from "@/pages/ClientById/ClientById"
import ClientsPage from "@/pages/Clients/ClientsPage"
import { ComplianceDocumentByIdPage } from "@/pages/ComplianceDocumentById"
import { ComplianceDocumentsPage } from "@/pages/ComplianceDocuments"
import ContractAnnexById from "@/pages/ContractAnnexById/ContractAnnexByIdPage"
import ContractAnnexesPage from "@/pages/ContractAnnexes/ContractAnnexesPage"
import ContractByIdPage from "@/pages/ContractById/ContractByIdPage"
import ContractsPage from "@/pages/Contracts/ContractsPage"
import { ElptsRequestsPage } from "@/pages/ElptsRequests"
import { EpsmApplicationByIdPage } from "@/pages/EpsmApplicationById"
import { EpsmApplicationEditPage } from "@/pages/EpsmApplicationEdit"
import { EpsmApplicationsPage } from "@/pages/EpsmApplications"
import { GeneralProductNameById } from "@/pages/GeneralProductNameById"
import { GeneralProductNamePage } from "@/pages/GeneralProductNames"
import { MainPage } from "@/pages/Main"
import { ManufacturerById } from "@/pages/ManufacturerById"
import { ManufacturerPage } from "@/pages/Manufacturers"
import OwnerById from "@/pages/OwnerById/OwnerById"
import { OwnersPage } from "@/pages/Owners"
import { ProductModelPageById } from "@/pages/ProductModelPageById"
import { ProductModelPage } from "@/pages/ProductModels"
import { ProductModificationById } from "@/pages/ProductModificationById"
import { ProductModificationPage } from "@/pages/ProductModifications"
import { ProductTypeById } from "@/pages/ProductTypeById"
import { ProductTypePage } from "@/pages/ProductTypes"
import { RolesPage } from "@/pages/Roles"
import SerialNumberById from "@/pages/SerialNumberById/SerialNumberById"
import { SerialNumbersPage } from "@/pages/SerialNumbers"
import TechnicalVocabulariesPage from "@/pages/TechnicalVocabularies/TechnicalVocabulariesPage"
import { TradeMarkById } from "@/pages/TradeMarkById"
import { TradeMarksPage } from "@/pages/TradeMarks"
import { UserById } from "@/pages/UserById"
import { UsersPage } from "@/pages/Users"

import { paths } from "./paths"

import GuardRoute from "@/router/GuardRoute"

export const Router: FC = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={paths.complianceDocuments} element={<GuardRoute resource={Resource.ComplianceDocument} scope={Scope.Read} />}>
          <Route index element={<ComplianceDocumentsPage />} />
          <Route path=":id" element={<ComplianceDocumentByIdPage />} />
        </Route>
        <Route path={paths.epsmApplications} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<EpsmApplicationsPage />} />
          <Route path="create" element={<EpsmApplicationEditPage />} />
          <Route path=":id">
            <Route index element={<EpsmApplicationByIdPage />} />
            <Route path="edit" element={<EpsmApplicationEditPage />} />
          </Route>
        </Route>
        <Route path={paths.contractAnnexes} element={<GuardRoute resource={Resource.ContractAnnex} scope={Scope.Read} />}>
          <Route index element={<ContractAnnexesPage />} />
          <Route path=":id" element={<ContractAnnexById />} />
        </Route>
        <Route path={paths.agreements} element={<GuardRoute resource={Resource.Agreement} scope={Scope.Read} />}>
          <Route index element={<AgreementsPage />} />
          <Route path=":id" element={<AgreementByIdPage />} />
        </Route>
        <Route path={paths.contracts} element={<GuardRoute resource={Resource.Contract} scope={Scope.Read} />}>
          <Route index element={<ContractsPage />} />
          <Route path=":id" element={<ContractByIdPage />} />
        </Route>
        <Route path={paths.tradeMarks} element={<GuardRoute resource={Resource.TradeMark} scope={Scope.Read} />}>
          <Route index element={<TradeMarksPage />} />
          <Route path=":id" element={<TradeMarkById />} />
        </Route>
        <Route path={paths.generalProductName} element={<GuardRoute resource={Resource.GeneralProductName} scope={Scope.Read} />}>
          <Route index element={<GeneralProductNamePage />} />
          <Route path=":id" element={<GeneralProductNameById />} />
        </Route>
        <Route path={paths.productType} element={<GuardRoute resource={Resource.ProductType} scope={Scope.Read} />}>
          <Route index element={<ProductTypePage />} />
          <Route path=":id" element={<ProductTypeById />} />
        </Route>
        <Route path={paths.productModel} element={<GuardRoute resource={Resource.ProductModel} scope={Scope.Read} />}>
          <Route index element={<ProductModelPage />} />
          <Route path=":id" element={<ProductModelPageById />} />
        </Route>
        <Route path={paths.productModification} element={<GuardRoute resource={Resource.ProductModification} scope={Scope.Read} />}>
          <Route index element={<ProductModificationPage />} />
          <Route path=":id" element={<ProductModificationById />} />
        </Route>
        <Route path={paths.serialNumbers} element={<GuardRoute resource={Resource.SerialNumber} scope={Scope.Read} />}>
          <Route index element={<SerialNumbersPage />} />
          <Route path=":id" element={<SerialNumberById />} />
        </Route>
        <Route path={paths.formalizationRulesCategories} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Категория в соответствии с Правилами оформления" data={FormalizationRulesCategories} />} />
        </Route>
        <Route path={paths.propulsionKinds} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Тип движителя" data={PropulsionKinds} />} />
        </Route>
        <Route path={paths.bodyColors} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Цвет кузова (кабины, прицепа)" data={BodyColors} />} />
        </Route>
        <Route path={paths.layoutPatterns} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Схема компоновки" data={LayoutPatterns} />} />
        </Route>
        <Route path={paths.engineIdNumberLocations} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Место расположения номера двигателя" data={EngineIdNumberLocations} />} />
        </Route>
        <Route path={paths.engineLocations} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Положение и размещение приводного двигателя (двигателей)" data={EngineLocations} />} />
        </Route>
        <Route path={paths.engineTypes} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Тип двигателя" data={EngineTypes} />} />
        </Route>
        <Route path={paths.cylinderArrangements} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Расположение цилиндров" data={CylinderArrangements} />} />
        </Route>
        <Route path={paths.fuelKinds} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Вид топлива" data={FuelKinds} />} />
        </Route>
        <Route path={paths.fuelSystemTypes} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Тип системы питания" data={FuelSystemTypes} />} />
        </Route>
        <Route path={paths.energyStorageDeviceTypes} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Тип устройства накопления энергии" data={EnergyStorageDeviceTypes} />} />
        </Route>
        <Route path={paths.ignitionSystems} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Система зажигания" data={IgnitionSystems} />} />
        </Route>
        <Route path={paths.transmissionTypes} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Тип трансмиссии" data={TransmissionTypes} />} />
        </Route>
        <Route path={paths.gearboxTypes} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Тип коробки передач" data={GearboxTypes} />} />
        </Route>
        <Route path={paths.gearRatiosForEachGears} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Передача коробки передач" data={GearRatiosForEachGears} />} />
        </Route>
        <Route path={paths.mainGearTypes} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Тип главной передачи" data={MainGearTypes} />} />
        </Route>
        <Route path={paths.electricMachineKinds} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Вид электромашины" data={ElectricMachineKinds} />} />
        </Route>
        <Route path={paths.electricMachineTypes} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Тип электромашины" data={ElectricMachineTypes} />} />
        </Route>
        <Route path={paths.steeringWheelPositions} element={<GuardRoute resource={Resource.EpsmApplication} scope={Scope.Read} />}>
          <Route index element={<TechnicalVocabulariesPage title="Рулевое управление. Положение рулевого колеса" data={SteeringWheelPositions} />} />
        </Route>
        <Route path={paths.manufacturers} element={<GuardRoute resource={Resource.Manufacturer} scope={Scope.Read} />}>
          <Route index element={<ManufacturerPage />} />
          <Route path=":id" element={<ManufacturerById />} />
        </Route>
        <Route path={paths.applicant} element={<GuardRoute resource={Resource.Applicant} scope={Scope.Read} />}>
          <Route index element={<ApplicantPage />} />
          <Route path=":id" element={<ApplicantById />} />
        </Route>
        <Route path={paths.owners} element={<GuardRoute resource={Resource.Owner} scope={Scope.Read} />}>
          <Route index element={<OwnersPage />} />
          <Route path=":id" element={<OwnerById />} />
        </Route>
        <Route path={paths.certificationBodies} element={<GuardRoute resource={Resource.CertificationBody} scope={Scope.Read} />}>
          <Route index element={<CertificationBodiesPage />} />
          <Route path=":id" element={<CertificationBodyById />} />
        </Route>
        <Route path={paths.clients} element={<GuardRoute resource={Resource.Client} scope={Scope.Read} />}>
          <Route index element={<ClientsPage />} />
          <Route path=":id" element={<ClientById />} />
        </Route>
        <Route path={paths.elptsRequests} element={<GuardRoute resource={Resource.CertificationBody} scope={Scope.Read} />}>
          <Route index element={<ElptsRequestsPage />} />
        </Route>
        <Route path={paths.authorizedOrganization} element={<GuardRoute resource={Resource.AuthorizedOrganization} scope={Scope.Read} />}>
          <Route index element={<AuthorizedOrganizationPage />} />
          <Route path=":id" element={<AuthorizedOrganizationById />} />
        </Route>
        <Route path={paths.users} element={<GuardRoute resource={Resource.User} scope={Scope.Read} />}>
          <Route index element={<UsersPage />} />
          <Route path=":id" element={<UserById />} />
        </Route>
        <Route path={paths.roles} element={<GuardRoute resource={Resource.User} scope={Scope.Read} />}>
          <Route index element={<RolesPage />} />
        </Route>
        <Route path={paths.authorizationError} element={<AuthorizationError />} />
      </Route>
      <Route element={<MainLayout/>}>
        <Route path={paths.main} element={<MainPage/>} />
        <Route path="*" element={<Navigate to={paths.main} replace />} />
      </Route>
    </Routes>
  )
}
