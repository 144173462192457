import { useQuery } from "@tanstack/react-query"

import { USERS_QUERY_KEY } from "@/constants/query"
import { getUserById, getUsers } from "@/http/user"

import { IRequestParams } from "@/types/api"

export const useUserQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getUsers(params || {}),
    queryKey: [USERS_QUERY_KEY, params],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false
  })
}

export const useUserByIdQuery = (id: string) => {
  return useQuery({
    queryFn: () => getUserById(id),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryKey: [USERS_QUERY_KEY, id],
    enabled: !!id
  })
}