import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IOwner, IOwnerCreate, IOwnerUpdate } from "@/types/IOwner"

export const getOwners = (params: IRequestParams): Promise<IPagingResponse<IOwner>> =>
  client.get("/Owners", {
    params
  })

export const getOwnerById = (id: number): Promise<IResponse<IOwner>> => client.get(`/Owners/${id}`)

export const createOwner = (body: IOwnerCreate): Promise<IResponse<number>> => client.post("/Owners", body)

export const updateOwner = (body: IOwnerUpdate): Promise<IResponse<string>> => client.put("/Owners", body)

export const deleteOwners = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/Owners", {
    data: {
      ids
    }
  })
