import { message } from "antd"

export const useMessage = (key: string) => {
  const Success = (content?: string, duration?: number) => {
    message.open({
      key: key,
      type: "success",
      content: content ?? "Успешно!",
      duration: duration ?? 3
    })
  }

  const Error = (e: any, duration: number = 5) => {
    message.open({
      key: key,
      type: "error",
      content: e.response.data.message,
      duration: duration
    })
  }

  const Process = (content?: string) => {
    message.open({
      key: key,
      type: "loading",
      content: content ?? "Обработка...",
      duration: 0
    })
  }

  return {
    Success,
    Error,
    Process
  }
}
