import { FC } from "react"

import { Button, Descriptions, Empty, Skeleton, Space } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import classes from "./ClientById.module.scss"

import { useCountryQuery } from "@/hook/Vocabularies/Technical/useCountryQuery"
import { useClientByIdQuery } from "@/hook/Vocabularies/useClientQuery"

import { getRegistrationName } from "@/types/RegistrationType"

import { paths } from "@/router/paths"

const ClientById: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams<{ id?: string }>()

    const { data, isLoading, isError } = useClientByIdQuery(Number(id))

    const { data: countries } = useCountryQuery()

    const FormatAddress = (address: any) => {
        const country = (address?.countryId ? countries?.response.data.find(c => c.id === address.countryId)?.name : "") ?? ""
        const city = address?.city ?? address?.settlement
    
        const result = [country, address?.region, address?.district, city, address?.street, address?.buildingNumber, address?.roomNumber, address?.postCode]
    
        return result.filter(Boolean).join(", ")
    }

    const onBackHandler = () => {
        navigate(paths.clients)
    }

    if (isError) {
        return <Empty description="Клиент не найден" />
    }

    const MOCK_DATA = "Не указано"
    
    return (
        <Skeleton loading={isLoading}>
            {data && (
                <>
                    <Descriptions title={`Клиент ${data.response.number}`} layout="vertical" bordered className={classes.descriptions}>
                        <Descriptions.Item label="Номер записи">{data.response.number}</Descriptions.Item>
                        <Descriptions.Item label="Клиент" style={{ width: "33%" }}>{data.response.name}</Descriptions.Item>
                        <Descriptions.Item label="Является ИП">{data.response.isIndividualEntrepreneur ? "Да" : "Нет"}</Descriptions.Item>
                        <Descriptions.Item label="ИНН">{data.response.inn}</Descriptions.Item>
                        <Descriptions.Item label="КПП">{data.response.kpp}</Descriptions.Item>
                        <Descriptions.Item label={getRegistrationName(data.response?.registrationType) || "Регистрационный номер"}>
                            {data.response.ogrn}
                        </Descriptions.Item>
                        <Descriptions.Item label="Юридический адрес" style={{ width: "33%" }} span={3}>
                            {FormatAddress(data.response.legalAddress)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Контактное лицо">{data.response.contactPerson}</Descriptions.Item>
                        <Descriptions.Item label="Электронная почта">{data.response.email}</Descriptions.Item>
                        <Descriptions.Item label="Номер телефона">{data.response.phone}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Банковские реквизиты" column={2} bordered className={classes.descriptions}>
                        <Descriptions.Item label="№ Р/СЧ в Банке">{data.response.bankAccount}</Descriptions.Item>
                        <Descriptions.Item label="Наименование банка">{data.response.bankName}</Descriptions.Item>
                        <Descriptions.Item label="БИК">{data.response.bik}</Descriptions.Item>
                        <Descriptions.Item label="№ К/СЧ">{data.response.correspondentAccount}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Печатные реквизиты" column={1} bordered className={classes.descriptions}>
                        <Descriptions.Item label="Должность подписанта в род.падеже">{data.response.jobGenitive ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Реквизиты Документа на право подписи в род.падеже">{data.response.requisitesGenitive ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="ФИО подписанта в род.падеже">{data.response.fullNameGenitive ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Должность подписанта в им.падеже">{data.response.jobNominative ?? MOCK_DATA}</Descriptions.Item>
                        <Descriptions.Item label="Фамилия И.О. подписанта в им.падеже">{data.response.fullNameNominative ?? MOCK_DATA}</Descriptions.Item>
                    </Descriptions> 
                    <Space className={classes.buttons}>
                        <Button onClick={onBackHandler}>Назад к списку</Button>
                    </Space>
                </>
            )}
        </Skeleton>
    )
}

export default ClientById
