import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"

import { ProfileOutlined, AppstoreOutlined } from "@ant-design/icons"
import {
    Button,
    Drawer,
    Form,
    Input,
    Select,
    Tabs,
    InputNumber,
    Row,
    Col
} from "antd"
import dayjs from "dayjs"
import { useLocation, useNavigate } from "react-router-dom"

import { DatePicker } from "@/components/DatePicker"
import { NumericInput } from "@/components/NumericInput"
import { SelectDropdownForAddItem } from "@/components/SelectDropdownForAddItem"
import { TabForm } from "@/components/TabForm"
import { Resource, Scope } from "@/constants/permission"
import { Role } from "@/constants/role"
import { YesOrNo } from "@/constants/validValues"
import { getContractById } from "@/http/contract"

import { useContractAnnexMutation } from "@/hook/ContractAnnexes/useContractAnnexMutation"
import { useContractAnnexByIdQuery } from "@/hook/ContractAnnexes/useContractAnnexQuery"
import { useContractQuery } from "@/hook/Contracts/useContractQuery"
import { usePermission } from "@/hook/usePermission"
import { useRole } from "@/hook/useRole"
import { useAuthorizedOrganizationQuery } from "@/hook/Vocabularies/useAuthorizedOrganizationQuery"
import { useClientQuery } from "@/hook/Vocabularies/useClientQuery"

import { IContractAnnexCreate } from "@/types/IContractAnnex"
import { IErrorValidate } from "@/types/ui"

import { paths } from "@/router/paths"

import { filterSelectOption } from "@/utils/filterSelectOption"

export interface ContractAnnexAddFormProps {
    open(id?: string): void;
    close(): void;
    copy(id: string): void
}

interface ContractAnnexAddFormValues {
    id: string
    contract: string
    organization: number
    number: string
    name: string
    date: Date
    passportCount: number
    amount: number
    client: number
    jobGenitive: string
    requisites: string
    fullNameGenitive: string
    jobNominative: string
    fullNameNominative: string
    isSigned: string
    isPaid: string
    isClosed: string
    closedDate: Date
}

interface ContractAnnexAddServices {
    preparationPrice: number
    complianceDocumentsList: string
    registrationPrice: number
    formalizationPrice: number
    correctionPrice: number
    cancellationPrice: number
    totalPrice: number
    additionalConditions: string
}

const fieldTabsMap: Record<string, string> = {
    id: "1",
    number: "1",
    name: "1",
    date: "1",
    passportCount: "1",
    amount: "1",
    client: "1",
    isSigned: "1",
    isPaid: "1",
    isClosed: "1",
    closedDate: "1",
    preparationPrice: "2",
    complianceDocumentsList: "2",
    registrationPrice: "2",
    formalizationPrice: "2",
    correctionPrice: "2",
    cancellationPrice: "2",
    totalPrice: "2",
    additionalConditions: "2"
}

export const ContractAnnexForm = forwardRef<ContractAnnexAddFormProps, {}>((props, ref) => {
    const [form] = Form.useForm<ContractAnnexAddFormValues>()
    const [services] = Form.useForm<ContractAnnexAddServices>()
    const [id, setId] = useState<string>("")
    const [isOpen, setIsOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isCopy, setIsCopy] = useState<boolean>(false)

    const { data: contractAnnex } = useContractAnnexByIdQuery(id)
    const { createMutation, updateMutation, closeMutation } = useContractAnnexMutation()

    const { data: contracts } = useContractQuery({
        OrderBy: "name asc"
    })
    const { data: organizations } = useAuthorizedOrganizationQuery({
        OrderBy: "name asc"
    })
    const { data: clients } = useClientQuery({
        OrderBy: "name asc"
    })
    
    const yes = YesOrNo[0]
    const no = YesOrNo[1]

    const [errorTabs, setErrorTabs] = useState<string[]>([])

    const inputNumberProps = {
        min: 0, precision: 2, addonAfter: "₽", style: { width: "100%" }
    }

    const [visabilityClosedDate, setVisabilityClosedDate] = useState<boolean>(false)

    useImperativeHandle(ref, () => ({
        open(id?: string) {
            onOpenHandler(id)
        },
        close() {
            onCloseHandler()
        },
        copy(id: string) {
            onCopyHandler(id)
        }
    }))

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        setErrorTabs([])

        const state = location.state
        location.state = null

        if (state !== null) {
            const contractId = state?.id ?? null

            if (contractId !== null) {
                form.resetFields()
                setIsOpen(true)

                onChangeContract(contractId)
            }
        }

        if (contractAnnex && isOpen) {
            const response = contractAnnex.response
            const prefix = response.contract?.name

            form.setFields([
                { name: "organization", value: response.organization?.id },
                { name: "contract", value: response.contract?.id },
                { name: "contractName", value: prefix + "-" },
                { name: "name", value: response.name.replace(prefix + "-", "") },
                { name: "date", value: response.date && dayjs(response.date) },
                { name: "passportCount", value: response.passportCount },
                { name: "amount", value: response.amount },
                { name: "client", value: response.client?.id },
                { name: "jobGenitive", value: response.jobGenitive },
                { name: "requisites", value: response.requisites },
                { name: "fullNameGenitive", value: response.fullNameGenitive },
                { name: "jobNominative", value: response.jobNominative },
                { name: "fullNameNominative", value: response.fullNameNominative },
                { name: "isSigned", value: response.isSigned ? yes : no },
                { name: "isPaid", value: response.isPaid ? yes : no },
                { name: "isClosed", value: response.isClosed ? yes : no },
                { name: "closedDate", value: response.closedDate && dayjs(response.closedDate) }
            ])

            services.setFields([
                { name: "preparationPrice", value: response.preparationPrice },
                { name: "complianceDocumentsList", value: response.complianceDocumentsList },
                { name: "registrationPrice", value: response.registrationPrice },
                { name: "formalizationPrice", value: response.formalizationPrice },
                { name: "correctionPrice", value: response.correctionPrice },
                { name: "cancellationPrice", value: response.cancellationPrice },
                { name: "totalPrice", value: response.totalPrice },
                { name: "additionalConditions", value: response.additionalConditions }
            ])

            setVisabilityClosedDate(isAdmin && response.isClosed)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, contractAnnex, form, isOpen, yes, no, services, location])

    const isTabError = (key: string) => errorTabs.includes(key)

    const title = useMemo(() => {
        const contractAnnex = " приложение к договору"

        if (isCopy) {
            return "Копировать" + contractAnnex
        }

        if (id) {
            return "Редактировать" + contractAnnex
        }

        return "Добавить" + contractAnnex
    }, [id, isCopy])

    const { hasPermission } = usePermission()

    const isBasePartVisible = useMemo(() => {
        if (id) {
            return hasPermission(Resource.ContractAnnex, Scope.Update)
        } else {
            return hasPermission(Resource.ContractAnnex, Scope.Create)
        }
    }, [id, hasPermission])

    const isAccountantPartVisible = useMemo(() => {
        return hasPermission(Resource.ContractAnnex, Scope.Close)
    }, [hasPermission])

    const { hasRole } = useRole()

    const isAdmin = useMemo(() => {
        return hasRole(Role.Admin)
    }, [hasRole])

    const canCloseContractAnnex = () => {
        return id && !isCopy && isAccountantPartVisible
    }

    const onOpenHandler = (id?: string) => {
        if (id) {
            setId(id)
        }
        form.resetFields()
        services.resetFields()
        setIsOpen(true)
    }

    const handleTotal = (changedValue: ContractAnnexAddServices, data: ContractAnnexAddServices) => {
        const result = +(data.preparationPrice ?? 0) + +(data.registrationPrice ?? 0) + +(data.formalizationPrice ?? 0)
            + +(data.correctionPrice ?? 0) + +(data.cancellationPrice ?? 0)
        services.setFieldValue("totalPrice", result)
        form.setFieldValue("amount", services.getFieldValue("totalPrice") ?? 0)
    }

    const onFinish = async (data: ContractAnnexAddFormValues) => {
        try {
            setErrorTabs([])
            await form.validateFields()
            await services.validateFields()

            const prices = services.getFieldsValue()
            const request: IContractAnnexCreate = {
                contractId: data.contract,
                name: data.name,
                date: data.date,
                passportCount: data.passportCount,
                amount: data.amount,
                preparationPrice: prices.preparationPrice,
                complianceDocumentsList: prices.complianceDocumentsList,
                registrationPrice: prices.registrationPrice,
                formalizationPrice: prices.formalizationPrice,
                correctionPrice: prices.correctionPrice,
                cancellationPrice: prices.cancellationPrice,
                totalPrice: prices.totalPrice,
                additionalConditions: prices.additionalConditions
            }
            setIsProcessing(true)

            if (id) {
                if (isCopy) {
                    const newId = (await createMutation.mutateAsync(request)).response
                    onCloseHandler(newId, true)
                } else {
                    if (isBasePartVisible) {
                        await updateMutation.mutateAsync({
                            ...request,
                            id: id
                        })
                        onCloseHandler(id, true)
                    }
                    if (isAccountantPartVisible) {
                        await closeMutation.mutateAsync({
                            ...request,
                            id: id,
                            isSigned: data.isSigned === yes,
                            isPaid: data.isPaid === yes,
                            isClosed: data.isClosed === yes,
                            closedDate: data.closedDate
                        })
                    }
                }
            } else {
                const newId = (await createMutation.mutateAsync(request)).response
                onCloseHandler(newId, true)
            }
        } catch (e) {
            const errors = (e as IErrorValidate).errorFields

            if (errors?.length) {
                const newErrorTabs = errors
                    .map((error) => fieldTabsMap[error.name[0]])
                    .reduce((acc, item) => {
                        if (acc.includes(item)) {
                            return acc
                        }

                        return [...acc, item]
                    }, [] as string[])

                setErrorTabs([...newErrorTabs])
            }
        } finally {
            setIsProcessing(false)
        }
    }

    const onChangeContract = async (id: string) => {
        const contract = await getContractById(id)
        var response = contract.response
        var client = response?.client
        form.setFields([
            { name: "contract", value: id  },
            { name: "client", value: client?.id  },
            { name: "organization", value: response?.organization?.id  },
            { name: "jobGenitive", value: client?.jobGenitive },
            { name: "requisites", value: client?.requisitesGenitive },
            { name: "fullNameGenitive", value: client?.fullNameGenitive },
            { name: "jobNominative", value: client?.jobNominative },
            { name: "fullNameNominative", value: client?.fullNameNominative },
            { name: "contractName", value: response?.name + "-" }
        ])
    }

    const onCopyHandler = (id: string) => {
        setId(id)
        setIsCopy(true)
        form.resetFields()
        services.resetFields()
        setIsOpen(true)
    }

    const onCloseHandler = (currentId?: string, isSubmit: boolean = false) => {
        setId("")
        form.resetFields()
        services.resetFields()
        setIsCopy(false)
        setIsOpen(false)

        if(currentId && isSubmit)
            navigate(paths.contractAnnexById(currentId))
    }

    const customDropdownHandler = (url: string) => {
        window.open(`${url}?openForm`, "_blank")
    }

    const onChangeClosedStatus = (status: string) => {
        form.setFieldValue("isClosed", status)

        if(status === no)
            setVisabilityClosedDate(false)
        else
            setVisabilityClosedDate(true)
    }

    return (
        <Drawer
            title={title}
            width={820}
            onClose={() => onCloseHandler(id)}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Button type="primary" disabled={isProcessing} onClick={() => onFinish(form.getFieldsValue())}>
                    Сохранить
                </Button>
            }
        >
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane
                    key="1"
                    tab={
                    <TabForm isError={isTabError("1")}>
                        <span>
                            <ProfileOutlined /> Реквизиты приложения
                        </span>
                    </TabForm>
                }>
                    <Form layout="vertical" autoComplete="off" form={form}>
                        <div hidden={!isBasePartVisible}>
                            <Form.Item
                                name="contract"
                                label="Документ-основание (договор)"
                                rules={[
                                    {
                                        required: isBasePartVisible,
                                        message: "Пожалуйста выберите документ-основание"
                                    }
                                ]}
                            >
                                <Select
                                    allowClear={false}
                                    showSearch
                                    options={contracts?.response.data.map(body => ({
                                        value: body.id,
                                        label: `№ ${body?.name} от ${new Date((new Date(body?.date)).toDateString()).toLocaleDateString("ru-RU")} г.`
                                    }))}
                                    filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                                    placeholder="Договор"
                                    onChange={onChangeContract}
                                    dropdownRender={(menu) => (
                                        <SelectDropdownForAddItem
                                            menu={menu}
                                            handler={() => customDropdownHandler(paths.contracts)}/>
                                    )}/>
                            </Form.Item>
                            <Form.Item 
                                name="organization" 
                                label="Организация" 
                                rules={[
                                    {
                                        required: isBasePartVisible,
                                        message: "Организация заполняется из Документа-основания (договора)"
                                    }
                                ]}
                            >
                                <Select
                                    allowClear={false}
                                    showSearch
                                    options={organizations?.response.data.map(body => ({
                                        value: body.id,
                                        label: body.name + ", " + body.inn
                                    }))}
                                    filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                                    placeholder="Организация"
                                    disabled
                                    dropdownRender={(menu) => (
                                        <SelectDropdownForAddItem
                                            menu={menu}
                                            handler={() => customDropdownHandler(paths.authorizedOrganizations)}/>
                                    )}/>
                            </Form.Item>
                            <Row gutter={32}>
                                <Col span={12}>
                                    <Form.Item label="Префикс и номер приложения" required>
                                        <Input.Group compact>
                                            <Form.Item
                                                name="contractName"
                                                noStyle
                                                rules={[{ 
                                                    required: isBasePartVisible,
                                                    message: "Префикс заполняется из договора"
                                                }]}
                                            >
                                                <Input placeholder="Номер договора" style={{ width: "50%" }} disabled />
                                            </Form.Item>
                                            <Form.Item name="name" noStyle rules={[{
                                                required: isBasePartVisible,
                                                message: "Пожалуйста введите номер приложения"
                                            }]}>
                                                <Input placeholder="Номер приложения" style={{ width: "50%" }}  />
                                            </Form.Item>
                                        </Input.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="date"
                                        label="Дата приложения"
                                        rules={[{
                                            required: isBasePartVisible,
                                            message: "Пожалуйста выберите дату"
                                        }]}
                                    >
                                        <DatePicker style={{ width: "100%" }} placeholder="Дата приложения"
                                                    disabledDate={(current) => current.isAfter(new Date())}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col span={12}>
                                    <Form.Item label="Количество паспортов" name="passportCount" rules={[{
                                        required: isBasePartVisible,
                                        message: "Пожалуйста введите кол-во паспортов"
                                    }]}>
                                        <NumericInput placeholder="Количество паспортов" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Сумма, руб." name="amount" rules={[{
                                        required: isBasePartVisible,
                                        message: "Пожалуйста введите сумму"
                                    }]}>
                                        <NumericInput placeholder="Сумма, руб." />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                name="client"
                                label="Клиент"
                                rules={[{ 
                                    required: isBasePartVisible,
                                    message: "Клиент заполняется из Документа-основания (договора)"
                                }]}
                            >
                                <Select
                                    allowClear={false}
                                    showSearch
                                    options={clients?.response.data.map(body => ({
                                        value: body.id,
                                        label: body.name + ", " + body.inn
                                    }))}
                                    filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                                    placeholder="Клиент"
                                    disabled
                                    dropdownRender={(menu) => (
                                        <SelectDropdownForAddItem
                                            menu={menu}
                                            handler={() => customDropdownHandler(paths.clients)}/>
                                    )}/>
                            </Form.Item>

                            <Form.Item
                                label="Печатные реквизиты приложения:"
                                name="jobGenitive"
                                rules={[{
                                    required: isBasePartVisible,
                                    message: "Должность подписанта в род. падеже заполняется из Клиента"
                                }]}
                            >
                                <Input placeholder="Должность подписанта в род. падеже" disabled />
                            </Form.Item>
                            <Form.Item name="requisites" rules={[{ 
                                required: isBasePartVisible,
                                message: "Реквизиты Документа на право подписи заполняются из Клиента"
                            }]}>
                                <Input placeholder="Реквизиты Документа на право подписи" disabled />
                            </Form.Item>
                            <Form.Item name="fullNameGenitive" rules={[{ 
                                required: isBasePartVisible,
                                message: "ФИО подписанта в род. падеже заполняется из Клиента"
                            }]}>
                                <Input placeholder="ФИО подписанта в род. падеже" disabled />
                            </Form.Item>
                            <Form.Item name="jobNominative">
                                <Input placeholder="Должность подписанта в им. падеже" disabled />
                            </Form.Item>
                            <Form.Item name="fullNameNominative" rules={[{ 
                                required: isBasePartVisible,
                                message: "Фамилия И.О. подписанта в им. падеже заполняется из Клиента"
                            }]}>
                                <Input placeholder="Фамилия И.О. подписанта в им. падеже" disabled />
                            </Form.Item>
                        </div>

                        {canCloseContractAnnex() &&
                            <>
                                <Row gutter={32}>
                                    <Col span={12}>
                                        <Form.Item name="isSigned" label="Подписано">
                                            <Select allowClear={false}
                                                    options={YesOrNo.map(value => ({ value: value, label: value }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="isPaid" label="Оплачено">
                                            <Select allowClear={false}
                                                    options={YesOrNo.map(value => ({ value: value, label: value }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={32}>
                                    <Col flex="1 1 50%">
                                        <Form.Item name="isClosed" label="Закрыто">
                                            <Select allowClear={false}
                                                    options={YesOrNo.map(value => ({ value: value, label: value }))}
                                                    onChange={onChangeClosedStatus}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {visabilityClosedDate &&
                                        <Col flex="1 1 50%">
                                                <Form.Item name="closedDate" label="Дата закрытия" style={{ width: "100%" }}>
                                                    <DatePicker value={form.getFieldValue("closedDate")}
                                                        style={{ width: "100%" }}
                                                        allowClear={false}
                                                        placeholder="Дата закрытия" />
                                                </Form.Item>
                                        </Col>
                                    }
                                </Row>
                            </>
                        }
                    </Form>
                </Tabs.TabPane>

                <Tabs.TabPane
                    key="2"
                    forceRender
                    tab={
                    <TabForm isError={isTabError("2")}>
                        <span>
                            <AppstoreOutlined /> Услуги
                        </span>
                    </TabForm>
                }>
                    <Form layout="horizontal" autoComplete="off" labelWrap labelCol={{ flex: "500px" }} form={services} onValuesChange={handleTotal}>
                        <Form.Item
                            label="Подготовка комплекта документов для подачи заявления в МинПромТорг (включая доставку) с целью оформления ЭПСМ"
                            name="preparationPrice"
                        >
                            <InputNumber placeholder="Стоимость, рублей" {...inputNumberProps} />
                        </Form.Item>
                        <Form.Item
                            label="Внесение документа об оценке соответствия в реестр АС СЭП – декларация/сертификат соответствия"
                            name="registrationPrice"
                        >
                            <InputNumber placeholder="Стоимость, рублей" {...inputNumberProps} />
                        </Form.Item>
                        <Form.Item
                            name="complianceDocumentsList"
                            colon={false}
                        >
                            <Input placeholder="Документ соответствия" />
                        </Form.Item>
                        <Form.Item
                            label="Оформление электронных паспортов самоходных машин"
                            name="formalizationPrice"
                        >
                            <InputNumber placeholder="Стоимость, рублей" {...inputNumberProps} />
                        </Form.Item>
                        <Form.Item
                            label="Корректировка электронных паспортов самоходных машин"
                            name="correctionPrice"
                        >
                            <InputNumber placeholder="Стоимость, рублей" {...inputNumberProps} />
                        </Form.Item>
                        <Form.Item
                            label="Аннулирование электронных паспортов самоходных машин"
                            name="cancellationPrice"
                        >
                            <InputNumber placeholder="Стоимость, рублей" {...inputNumberProps} />
                        </Form.Item>
                        <Form.Item label="Итого:" name="totalPrice">
                            <InputNumber placeholder="Стоимость, рублей" disabled {...inputNumberProps} />
                        </Form.Item>
                        <Form.Item name="additionalConditions">
                            <Input placeholder="Дополнительные условия" style={{ width: "100%" }} />
                        </Form.Item>
                    </Form>
                </Tabs.TabPane>
            </Tabs>
        </Drawer>
    )
})
