import React from "react"

import { OidcConfiguration, OidcProvider } from "@axa-fr/react-oidc"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ConfigProvider } from "antd"
import ruRU from "antd/locale/ru_RU"
import { BrowserRouter } from "react-router-dom"

import { PermissionContextProvider } from "@/context/PermissionContext"
import { RoleContextProvider } from "@/context/RoleContext"
import { ErrorBoundary } from "@/hoc/ErrorBoundary"
import { Router } from "@/router"

import AuthorizationCallBackSuccess from "./pages/AuthorizationCallBackSuccess/AuthorizationCallBackSuccess"

const configuration: OidcConfiguration = {
  client_id: process.env.REACT_APP_CLIENT_ID ?? "",
  redirect_uri: window.location.origin + "/authentication/callback",
  silent_redirect_uri: window.location.origin + "/authentication/silent-callback",
  scope: "openid profile email offline_access",
  authority: process.env.REACT_APP_AUTHORITY ?? "",
  service_worker_only: false,
  storage: localStorage,
  token_request_extras: {
    client_secret: process.env.REACT_APP_CLIENT_SECRET ?? ""
  }
}

const client = new QueryClient()

export const App = () => {
  return (
    <OidcProvider configuration={configuration} callbackSuccessComponent={AuthorizationCallBackSuccess}>
      <QueryClientProvider client={client}>
        <ConfigProvider locale={ruRU}>
          <React.StrictMode>
            <ErrorBoundary>
              <BrowserRouter>
                <RoleContextProvider>
                  <PermissionContextProvider>
                    <Router />
                  </PermissionContextProvider>
                </RoleContextProvider>
              </BrowserRouter>
            </ErrorBoundary>
          </React.StrictMode>
        </ConfigProvider>
      </QueryClientProvider>
    </OidcProvider>

  )
}
