import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { ICertificationBody, ICertificationBodyUpdate } from "@/types/ICertificationBody"

export const getCertificationBodies = (params: IRequestParams): Promise<IPagingResponse<ICertificationBody>> =>
  client.get("/CertificationBodies", {
    params
  })

export const getCertificationBodyById = (id: number): Promise<IResponse<ICertificationBody>> => client.get(`/CertificationBodies/${id}`)

export const createCertificationBody = (name: string): Promise<IResponse<number>> =>
  client.post("/CertificationBodies", {
    name
  })

export const updateCertificationBody = (body: ICertificationBodyUpdate): Promise<IResponse<string>> =>
  client.put("/CertificationBodies", body)

export const deleteCertificationBodies = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/CertificationBodies", {
    data: {
      ids
    }
  })
