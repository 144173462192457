import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IUser, IUserCreate, IUserUpdate } from "@/types/IUser"

export const getUsers = (
  params: IRequestParams
): Promise<IPagingResponse<IUser>> =>
  client.get("/Users", {
    params
  })

export const getUserById = (id: string): Promise<IResponse<IUser>> => client.get(`/Users/${id}`)

export const createUser = (user: IUserCreate): Promise<IResponse<boolean>> => client.post("/Users", user)

export const updateUser = (user: IUserUpdate): Promise<IResponse<boolean>> => client.put("/Users", user)

export const logoutUser = (id: string): Promise<IResponse<boolean>> => client.post(`/Users/${id}/Logout`)

export const deleteUsers = (ids: string[]): Promise<IResponse<boolean>> =>
  client.delete("/Users", {
    data: {
      ids
    }
  })
