import React, { FC, useEffect, useMemo, useState } from "react"

import { CopyOutlined, HddOutlined, ReadOutlined } from "@ant-design/icons"
import { Layout, Menu } from "antd"
import { NavLink, useLocation } from "react-router-dom"


import { Resource, Scope } from "@/constants/permission"

import classes from "./Sidebar.module.scss"

import { usePermission } from "@/hook/usePermission"

import { MenuOption, MenuPermission } from "@/types/ui"

import { paths } from "@/router/paths"

const menuItems: any[] = [
  getItem("Документы", "documents", { icon: <CopyOutlined /> }, [
    getItem(<NavLink to={paths.agreements}>Соглашения с УИЛ</NavLink>, paths.agreements,{
      permission: { resource: Resource.Agreement, scope: Scope.Read }
    }),
    getItem(<NavLink to={paths.complianceDocuments}>Документы соответствия</NavLink>, paths.complianceDocuments,{
      permission: { resource: Resource.ComplianceDocument, scope: Scope.Read }
    }),
    getItem(<NavLink to={paths.epsmApplications}>Заявления на создание ЭПСМ</NavLink>, paths.epsmApplications,{
      permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
    }),
    getItem(<NavLink to={paths.contracts}>Договоры</NavLink>, paths.contracts,{
      permission: { resource: Resource.Contract, scope: Scope.Read }
    }),
    getItem(<NavLink to={paths.contractAnnexes}>Приложения к договорам</NavLink>, paths.contractAnnexes,{
      permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
    })
  ]),
  getItem("Справочники", "handbook", { icon: <ReadOutlined /> }, [
    getItem("Продукция", "products", {}, [
      getItem(<NavLink to={paths.tradeMarks}>Торговые марки продукции</NavLink>, paths.tradeMarks,{
        permission: { resource: Resource.TradeMark, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.generalProductName}>Общее наименование продукции</NavLink>, paths.generalProductName,{
        permission: { resource: Resource.GeneralProductName, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.productType}>Тип продукции</NavLink>, paths.productType,{
        permission: { resource: Resource.ProductType, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.productModel}>Коммерческое наименование (модель)</NavLink>, paths.productModel,{
        permission: { resource: Resource.ProductModel, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.productModification}>Модификация продукции</NavLink>, paths.productModification,{
        permission: { resource: Resource.ProductModification, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.serialNumbers}>Серийные номера продукции</NavLink>, paths.serialNumbers,{
        permission: { resource: Resource.SerialNumber, scope: Scope.Read }
      }),
      getItem("Технические показатели", "", {}, [
        getItem(<NavLink to={paths.formalizationRulesCategories}>Категория</NavLink>, paths.formalizationRulesCategories,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.propulsionKinds}>Тип движителя</NavLink>, paths.propulsionKinds,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.bodyColors}>Цвет кузова</NavLink>, paths.bodyColors,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.layoutPatterns}>Схема компоновки</NavLink>, paths.layoutPatterns,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.engineIdNumberLocations}>Тип движителя</NavLink>, paths.engineIdNumberLocations,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.engineLocations}>Положение двигателя</NavLink>, paths.engineLocations,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.engineTypes}>Тип двигателя</NavLink>, paths.engineTypes,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.cylinderArrangements}>Цилиндры</NavLink>, paths.cylinderArrangements,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.fuelKinds}>Вид топлива</NavLink>, paths.fuelKinds,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.fuelSystemTypes}>Тип системы питания</NavLink>, paths.fuelSystemTypes,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.energyStorageDeviceTypes}>Устройство накопления энергии</NavLink>, paths.energyStorageDeviceTypes,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.ignitionSystems}>Система зажигания</NavLink>, paths.ignitionSystems,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.transmissionTypes}>Тип трансмиссии</NavLink>, paths.transmissionTypes,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.gearboxTypes}>Коробка передач</NavLink>, paths.gearboxTypes,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.gearRatiosForEachGears}>Передача</NavLink>, paths.gearRatiosForEachGears,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.mainGearTypes}>Главная передача</NavLink>, paths.mainGearTypes,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.electricMachineKinds}>Вид электромашины</NavLink>, paths.electricMachineKinds,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.electricMachineTypes}>Тип электромашины</NavLink>, paths.electricMachineTypes,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        }),
        getItem(<NavLink to={paths.steeringWheelPositions}>Рулевое колесо</NavLink>, paths.steeringWheelPositions,{
          permission: { resource: Resource.EpsmApplication, scope: Scope.Read }
        })
      ])
    ]),
    getItem("Контрагенты", "counterparties", {}, [
      getItem(<NavLink to={paths.manufacturers}>Изготовители</NavLink>, paths.manufacturers,{
        permission: { resource: Resource.Manufacturer, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.applicant}>Представители изготовителя-заявители</NavLink>, paths.applicant,{
        permission: { resource: Resource.Applicant, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.owners}>Собственники</NavLink>, paths.owners,{
        permission: { resource: Resource.Owner, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.certificationBodies}>Органы сертификации</NavLink>, paths.certificationBodies,{
        permission: { resource: Resource.CertificationBody, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.clients}>Клиенты</NavLink>, paths.clients,{
        permission: { resource: Resource.Client, scope: Scope.Read }
      })
    ]),
    getItem("Организация", "organization", {}, [
      getItem(<NavLink to={paths.authorizedOrganization}>Уполномоченная организация</NavLink>, paths.authorizedOrganization,{
        permission: { resource: Resource.AuthorizedOrganization, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.users}>Пользователи</NavLink>, paths.users,{
        permission: { resource: Resource.User, scope: Scope.Read }
      }),
      getItem(<NavLink to={paths.roles}>Роли</NavLink>, paths.roles,{
        permission: { resource: Resource.Role, scope: Scope.Read }
      })
    ])
  ]),
  getItem("Логи", "logs", { icon: <HddOutlined /> }, [
    getItem(<NavLink to={paths.elptsRequests}>ЭПСМ</NavLink>, paths.elptsRequests,{
      permission: { resource: Resource.ElptsRequest, scope: Scope.Read }
    })
  ])
]

const openKeysMap: Record<string, string[]> = {
  "compliance-documents": ["documents", "compliance-documents"],
  "epsm-applications": ["documents", "epsm-applications"],
  "trade-marks": ["handbook", "products", "trade-marks"],
  "general-product-names": ["handbook", "products", "general-product-names"],
  "product-types": ["handbook", "products", "product-types"],
  "product-models": ["handbook", "products", "product-models"],
  "product-modifications": ["handbook", "products", "product-modifications"],
  "serial-numbers": ["handbook", "products", "serial-numbers"],
  manufacturers: ["handbook", "counterparties", "manufacturers"],
  applicants: ["handbook", "counterparties", "applicants"],
  owners: ["handbook", "counterparties", "owners"],
  "certification-bodies": ["handbook", "counterparties", "certification-bodies"],
  "authorized-organizations": ["handbook", "organization", "authorized-organizations"],
  users: ["handbook", "organization", "users"],
  roles: ["handbook", "organization", "roles"],
  "logs": ["elpts-requests"]
}

export const Sidebar: FC = React.memo(() => {
  const location = useLocation()
  const [keyPath, setKeyPath] = useState<string>(location.pathname.split("/")[1] || "compliance-documents")
  const { hasPermission } = usePermission()

  const [collapsed, setCollapsed] = useState<boolean>(false)

  const toggleCollapsed = (isCollapsed: boolean) => {
    setCollapsed(isCollapsed)
  }

  useEffect(() => {
    if(location.pathname) {
      const key = location.pathname.split("/")[1]
      if(key)
        setKeyPath(key)
    }
  }, [location.pathname])

  const menus = useMemo(() => {
    const generateMenuItems = (menuItems: any): any => {
      if(!menuItems) return
      const items = []

      for (const menu of menuItems) {
        if(menu.permission && !hasPermission(menu.permission.resource, menu.permission.scope)) continue

        const key: string = menu.key
        const children = generateMenuItems(menu.children) as any[]
        if (children && !children.some(x => x)){
          continue
        }

        items.push({
          label: menu.label,
          key: key.includes("/") ? key.split("/")[1] : key,
          icon: menu.icon,
          children: children
        })
      }

      return items
    }

    return generateMenuItems(menuItems)
  }, [hasPermission])

  return (
    <Layout.Sider
      width={300}
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
    >
      <div className={classes.logo}>ЭПСМ</div>
      <Menu
        className={classes.menu}
        theme="dark"
        mode="inline"
        defaultOpenKeys={openKeysMap[keyPath]}
        selectedKeys={[keyPath]}
        items={menus}
      />
    </Layout.Sider>
  )
})

function getItem(
  label: React.ReactNode,
  key: React.Key,
  options?: MenuOption,
  children?: MenuPermission[]
): MenuPermission {
  return {
    key,
    children,
    label,
    icon: options?.icon,
    permission: options?.permission
  } as MenuPermission
}
