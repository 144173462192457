import { FC, ReactElement } from "react"

import { Button, Divider } from "antd"

import classes from "./SelectDropdownForAddItem.module.scss"

interface SelectDropdownForAddItemProps {
  menu: ReactElement;
  handler: () => void;
  addText?: string;
}

export const SelectDropdownForAddItem: FC<SelectDropdownForAddItemProps> = (props) => {
  const { menu, handler, addText = "Создать новую запись" } = props

  return (
    <>
      {menu}
      <Divider className={classes.divider} />
      <Button type="primary" block onMouseDown={handler}>{addText}</Button>
    </>
  )
}