import { FC, useEffect, useState } from "react"

import { Button, Descriptions, Empty, Skeleton, Space, UploadFile } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { uid } from "uid"

import { PicturesUpload } from "@/components/PicturesUpload"

import classes from "./AuthorizedOrganizationById.module.scss"

import { useAuthorizedOrganizationByIdQuery } from "@/hook/Vocabularies/useAuthorizedOrganizationQuery"

import { paths } from "@/router/paths"

import { uploadFileConvert } from "@/utils/uploadFileConvert"

const AuthorizedOrganizationById: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id?: string }>()

  const { data, isLoading, isError } = useAuthorizedOrganizationByIdQuery(Number(id))

  const [stampFile, setStampFile] = useState<UploadFile[]>([])
  const [signatureFile, setSignatureFile] = useState<UploadFile[]>([])

  useEffect(() => {
    const stamp = data?.response?.stamp
    if (stamp) {
      const newPromise = uploadFileConvert({ 
        url: stamp.content, name: stamp.name, uid: uid()
      })
      Promise.all([newPromise]).then(res => {
        setStampFile(res)
      })
    }

    const signature = data?.response?.signature
    if (signature) {
      const newPromise = uploadFileConvert({ 
        url: signature.content, name: signature.name, uid: uid()
      })
      Promise.all([newPromise]).then(res => {
        setSignatureFile(res)
      })
    }
  }, [data?.response?.stamp, data?.response?.signature])

  const onBackHandler = () => {
    navigate(paths.authorizedOrganizations)
  }

  if (isError) {
    return <Empty description="Уполномоченная организация не найден" />
  }

  const MOCK_DATA = "Не указано"

  return (
    <Skeleton loading={isLoading}>
      {data && (
        <>
          <Descriptions title={`Уполномоченная организация ${data.response.number}`} layout="vertical" bordered className={classes.descriptions}>
            <Descriptions.Item label="Номер записи" style={{ width: "33%" }}>{data.response.number}</Descriptions.Item>
            <Descriptions.Item label="Полное наименование" style={{ width: "33%" }}>{data.response.name}</Descriptions.Item>
            <Descriptions.Item label="Сокращенное наименование" style={{ width: "33%" }}>{data.response.shortName}</Descriptions.Item>            
            <Descriptions.Item label="Форма собственности">{data.response.ownershipType}</Descriptions.Item>
            <Descriptions.Item label="ИНН">{data.response.inn}</Descriptions.Item>
            <Descriptions.Item label="КПП">{data.response.kpp}</Descriptions.Item>
            <Descriptions.Item label="ОГРН">{data.response.ogrn}</Descriptions.Item>
            <Descriptions.Item label="Префикс номера Договора">{data.response.contractPrefixNumber}</Descriptions.Item>
          </Descriptions>
          <Descriptions column={2} bordered className={classes.descriptions}>
            <Descriptions.Item label="Электронная почта">{data.response.email}</Descriptions.Item>
            <Descriptions.Item label="Номер телефона">{data.response.phone}</Descriptions.Item>
          </Descriptions>
          <Descriptions title={"Адрес"} column={1} bordered className={classes.descriptions}>
            <Descriptions.Item label="Регион, область, район">{data.response.region}</Descriptions.Item>
            <Descriptions.Item label="Город">{data.response.city}</Descriptions.Item>
            <Descriptions.Item label="Улица">{data.response.street}</Descriptions.Item>
            <Descriptions.Item label="Номер дома, строение, квартира">{data.response.building}</Descriptions.Item>
            <Descriptions.Item label="Индекс">{data.response.index}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Банковские реквизиты" column={2} bordered className={classes.descriptions}>               
            <Descriptions.Item label="№ Р/СЧ в Банке">{data.response.bankAccount}</Descriptions.Item>
            <Descriptions.Item label="Наименование банка">{data.response.bankName}</Descriptions.Item>
            <Descriptions.Item label="БИК">{data.response.bik}</Descriptions.Item>
            <Descriptions.Item label="№ К/СЧ">{data.response.correspondentAccount}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Печатные реквизиты" column={1} bordered className={classes.descriptions}>
            <Descriptions.Item label="Должность подписанта в род.падеже">{data.response.jobGenitive ?? MOCK_DATA}</Descriptions.Item>
            <Descriptions.Item label="Реквизиты Документа на право подписи в род.падеже">{data.response.requisitesGenitive ?? MOCK_DATA}</Descriptions.Item>
            <Descriptions.Item label="ФИО подписанта в род.падеже">{data.response.fullNameGenitive ?? MOCK_DATA}</Descriptions.Item>
            <Descriptions.Item label="Должность подписанта в им.падеже">{data.response.jobNominative ?? MOCK_DATA}</Descriptions.Item>
            <Descriptions.Item label="Фамилия И.О. подписанта в им.падеже">{data.response.fullNameNominative ?? MOCK_DATA}</Descriptions.Item>
          </Descriptions> 
          <Descriptions 
            title="Печать и подпись" 
            bordered 
            className={classes.descriptions} 
            style={{ width: "33%" }}
          >
            <Descriptions.Item>
              <PicturesUpload readonly fileList={stampFile} />
            </Descriptions.Item>
            <Descriptions.Item>
              <PicturesUpload readonly fileList={signatureFile} />
            </Descriptions.Item>
          </Descriptions>
          <Space className={classes.buttons}>
            <Button onClick={onBackHandler}>Назад к списку</Button>
          </Space>
        </>
      )}
    </Skeleton>
  )
}

export default AuthorizedOrganizationById
