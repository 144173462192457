import { IContract } from "@/types/IContract"
import { ITableColumn } from "@/types/ui"

import { formatDate } from "@/utils/formatDate"

const EMPTY_DATA = "Нет"
const NOT_INDICATED = "Не указано"

export const ContractColumns: ITableColumn<IContract>[] = [
    {
        title: "Номер записи",
        key: "number",
        dataIndex: ["number"],
        align: "center",
        sorter: true,
        width: 175,
        isVisible: true,
        order: 1
    },
    {
        title: "Номер договора",
        key: "name",
        dataIndex: ["name"],
        align: "center",
        sorter: true,
        width: 175,
        isVisible: true,
        order: 2
    },
    {
        title: "Дата договора",
        key: "date",
        dataIndex: ["date"],
        align: "center",
        sorter: true,
        width: 150,
        render: (_, { date }) => <><span>{date ? formatDate(date) : NOT_INDICATED}</span></>,
        isVisible: true,
        order: 3
    },
    {
        title: "Организация",
        key: "organization",
        dataIndex: ["organization"],
        align: "center",
        sorter: true,
        width: 125,
        render: (_, { organization }) => <span key={organization?.id}>{(organization?.shortName || organization?.name) ?? NOT_INDICATED}</span>,
        isVisible: true,
        order: 4
    },
    {
        title: "Клиент",
        key: "clientName",
        dataIndex: ["client", "name"],
        align: "center",
        sorter: true,
        width: 300,
        isVisible: true,
        order: 5,
        render: (_, { client }) => <>{client?.shortName || client?.name}</>
    },
    {
        title: "ИНН клиента",
        key: "clientInn",
        dataIndex: ["client", "inn"],
        align: "center",
        sorter: true,
        width: 150,
        isVisible: true,
        order: 6
    },
    {
        title: "Автор",
        key: "author",
        dataIndex: ["author"],
        align: "center",
        width: 150,
        render: (_, { author }) => <span key={author.id}>{author.fullName ?? EMPTY_DATA}</span>,
        sorter: true,
        sorterField: "authorId",
        isVisible: true,
        order: 7
    }
]
