import { FC } from "react"

import { Layout, theme } from "antd"

import { UserMenu } from "@/components/UserMenu"

import classes from "./Header.module.scss"

export const Header: FC = () => {
  const {
    token: { colorBgContainer }
  } = theme.useToken()

  return (
    <Layout.Header className={classes.header} style={{ background: colorBgContainer }}>
      <div className={classes.user}>
        <UserMenu />
      </div>
    </Layout.Header>
  )
}
