export const COMPLIANCE_DOCUMENT_QUERY_KEY = "complianceDocuments"
export const EPSM_APPLICATIONS_QUERY_KEY = "epsmApplications"
export const DOCUMENT_KINDS_QUERY_KEY = "document_kinds"
export const STATUSES_QUERY_KEY = "statuses"
export const EPSM_APPLICATION_STATUSES_QUERY_KEY = "epsmApplicationsStatuses"
export const APPLICANTS_QUERY_KEY = "applicants"
export const CERTIFICATION_BODIES_QUERY_KEY = "certificationBodies"
export const CONTRACT_ANNEX_QUERY_KEY = "contractAnnexes"
export const AGREEMENT_QUERY_KEY = "agreements"
export const AGREEMENT_KINDS_QUERY_KEY = "agreement_kinds"
export const AGREEMENT_STATUSES_QUERY_KEY = "agreement_statuses"
export const CONTRACT_QUERY_KEY = "contracts"
export const CLIENTS_QUERY_KEY = "clients"

export const ELPTS_REQUEST_QUERY_KEY = "elptsRequest"

export const GENERAL_PRODUCT_NAMES_QUERY_KEY = "generalProductNames"
export const MANUFACTURERS_QUERY_KEY = "manufacturers"
export const PRODUCT_MODELS_QUERY_KEY = "productModels"
export const PRODUCT_MODIFICATIONS_QUERY_KEY = "productModifications"
export const PRODUCT_TYPES_QUERY_KEY = "productTypes"
export const TRADE_MARKS_QUERY_KEY = "tradeMarks"
export const OWNERS_QUERY_KEY = "owners"
export const OWNER_TYPE_QUERY_KEY = "owner_types"
export const AUTHORIZED_ORGANIZATION_QUERY_KEY = "authorizedOrganizations"
export const SERIAL_NUMBER_QUERY_KEY = "serialNumbers"
export const ROLE_QUERY_KEY = "roles"
export const USERS_QUERY_KEY = "users"

export const COUNTRIES_QUERY_KEY = "technicalVocabularies/countries"
