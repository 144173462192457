import { FC } from "react"

import { Button, Descriptions, Empty, Skeleton, Space } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import classes from "./CertificationBodyById.module.scss"

import { useCertificationBodyByIdQuery } from "@/hook/Vocabularies/useCertificationBodyQuery"

import { paths } from "@/router/paths"

const CertificationBodyById: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id?: string }>()

  const { data, isLoading, isError } = useCertificationBodyByIdQuery(Number(id))

  const onBackHandler = () => {
    navigate(paths.certificationBodies)
  }

  if (isError) {
    return <Empty description="Орган сертификации не найден" />
  }

  return (
    <Skeleton loading={isLoading}>
      {data && (
        <>
          <Descriptions title={`Орган сертификации ${data.response.number}`} layout="vertical" bordered>
            <Descriptions.Item label="Номер записи">{data.response.number}</Descriptions.Item>
            <Descriptions.Item label="Орган сертификации">{data.response.name}</Descriptions.Item>
          </Descriptions>
          <Space className={classes.buttons}>
            <Button onClick={onBackHandler}>Назад к списку</Button>
          </Space>
        </>
      )}
    </Skeleton>
  )
}

export default CertificationBodyById
