import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { ISerialNumber } from "@/types/ISerialNumber"

export const getSerialNumbers = (params: IRequestParams): Promise<IPagingResponse<ISerialNumber>> =>
  client.get("/SerialNumbers", {
    params
  })

export const getSerialNumberById = (id: number): Promise<IResponse<ISerialNumber>> => client.get(`/SerialNumbers/${id}`)
