import { useQuery } from "@tanstack/react-query"

import { OWNERS_QUERY_KEY } from "@/constants/query"
import { getOwnerById, getOwners } from "@/http/owner"

import { IRequestParams } from "@/types/api"

export const useOwnerQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getOwners(params || {}),
    queryKey: [OWNERS_QUERY_KEY, params],
    retry: false
  })
}

export const useOwnerByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getOwnerById(id),
    queryKey: [OWNERS_QUERY_KEY, id],
    enabled: id !== 0
  })
}
