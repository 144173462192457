export enum RegistrationType {
    OGRN = 1,
    OGRNIP = 2,
    OKYLP = 4,
    BIN = 5,
    OKPO = 6,
    GRYL = 7
}

export const getRegistrationType = (countryCode: string = "RU", isIE: boolean): RegistrationType => {
    switch (countryCode) {
        case "BY":
            return RegistrationType.OKYLP
        case "KZ":
            return RegistrationType.BIN
        case "KG":
            return RegistrationType.OKPO
        case "AM":
            return RegistrationType.GRYL
        default:
            return isIE ? RegistrationType.OGRNIP : RegistrationType.OGRN
    }
}

export const getRegistrationName = (type: RegistrationType): string => {
    switch (type) {
        case 1:
            return "ОГРН"
        case 2:
            return "ОГРНИП"
        case 4:
            return "ОКЮЛП"
        case 5:
            return "БИН"
        case 6:
            return "ОКПО"
        case 7:
            return "ГРЮЛ"
        default:
            return "ОГРН"
    }
}

export const getRegistrationLength = (type: RegistrationType, isIE = false): number => {
    switch (type) {
        case 1:
            return 13
        case 2:
            return 15
        case 4:
            return 9
        case 5:
            return 12
        case 6:
            return isIE ? 8 : 10
        case 7:
            return isIE ? 13 : 15
        default:
            return 13
    }
}