import React, { Key, ReactNode } from "react"

import { MenuProps } from "antd"
import { ColumnType } from "antd/es/table"
import dayjs from "dayjs"

import { Permission } from "@/types/IPermission"

export type MenuItem = Required<MenuProps>["items"][number];
export interface MenuOption {
  permission?: Permission;
  icon?: React.ReactNode;
}
export type MenuPermission = MenuItem & { permission?: Permission }

export interface IFilterProps {
  filters: IFilterField[];
  submitText?: string;
  cancelText?: string;
  onSubmit?: (filters: string) => void;
  onCancel?: () => void;
  filterKey: string;
}

export interface ITableColumn<T> extends ColumnType<T> {
  isVisible: boolean;
  title: ReactNode;
  key: Key;
  sorterField?: string
  order: number
}

export interface IFilterField {
  name: string;
  text: string;
  value?: string | dayjs.Dayjs | dayjs.Dayjs[] | number | number[] | null
  operator?: FilterFieldOperator;
  type: FilterFieldType;
}

export interface IFilterSelectField extends IFilterField {
  values: { id: number | string, name: string }[];
}

export type FilterField = IFilterField | IFilterSelectField;

export enum FilterFieldType {
  Text,
  Number,
  Select,
  MultiSelect,
  Date,
  DateRange
}

export enum FilterFieldOperator {
  Equal,
  NotEqual,
  LessThan,
  GreaterThan,
  GreaterThanOrEqual,
  LessThanOrEqual,
  Contains,
  NotContains,
}

export interface IErrorValidate {
  errorFields: { errors: string[]; name: string[] }[];
}