import { useQuery } from "@tanstack/react-query"

import { CONTRACT_ANNEX_QUERY_KEY } from "@/constants/query"
import { getContractAnnexes, getContractAnnexesById } from "@/http/contractAnnex"

import { IRequestParams } from "@/types/api"

export const useContractAnnexQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getContractAnnexes(params || {}),
        queryKey: [CONTRACT_ANNEX_QUERY_KEY, params]
    })
}

export const useContractAnnexByIdQuery = (id: string, options?: { refetchOnWindowFocus: boolean }) => {
    return useQuery({
        queryFn: () => getContractAnnexesById(id),
        queryKey: [CONTRACT_ANNEX_QUERY_KEY, id],
        enabled: !!id,
        refetchOnWindowFocus: options?.refetchOnWindowFocus ?? true
    })
}