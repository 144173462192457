export enum Resource {
  ComplianceDocument = "compliance-documents",
  EpsmApplication = "epsm-applications",
  ContractAnnex = "contract-annexes",
  Agreement = "agreements",
  Contract = "contracts",
  TradeMark = "trade-marks",
  GeneralProductName = "general-product-names",
  ProductType = "product-types",
  ProductModel = "product-models",
  ProductModification = "product-modifications",
  SerialNumber = "serial-numbers",
  Manufacturer = "manufacturers",
  Applicant = "applicants",
  Owner = "owners",
  CertificationBody = "certification-bodies",
  Client = "clients",
  ElptsRequest = "elpts-requests",
  AuthorizedOrganization = "authorized-organizations",
  User = "users",
  Role = "roles"
}

export enum Scope {
  Create = "create",
  CreateManually = "create-manually",
  Read = "read",
  Update = "update",
  Delete = "delete",
  Register = "register",
  CreatedOnly = "created-only",
  Close = "close"
}
