import { FieldData } from "@/components/FilterWrapper"

interface UseFilterStorageOptions {
  key: string
}

export const useFilterStorage = (options: UseFilterStorageOptions) => {
  const { key } = options

  const saveFilter = (fieldData: FieldData[]) => {
    sessionStorage.setItem(`${key}-filter`, JSON.stringify(fieldData))
  }

  const saveFilterString = (filterString: string) => {
    sessionStorage.setItem(`${key}-filterString`, filterString)
  }

  const resetFilter = () => {
    sessionStorage.removeItem(`${key}-filter`)
    sessionStorage.removeItem(`${key}-filterString`)
  }

  const getFilter = () => {
    const filter = sessionStorage.getItem(`${key}-filter`)

    return filter ? JSON.parse(filter) : []
  }

  const getFilterString = () => {
    return sessionStorage.getItem(`${key}-filterString`)
  }

  const savePageSize = (pageSize: number) => {
    sessionStorage.setItem(`${key}-pageSize`, pageSize.toString())
  }

  const getPageSize = () => {
    return Number(sessionStorage.getItem(`${key}-pageSize`))
  }

  return {
    saveFilter,
    saveFilterString,
    resetFilter,
    getFilter,
    getFilterString,
    getPageSize,
    savePageSize
  }
}
