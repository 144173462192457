import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"

import {
    Button,
    Drawer,
    Form,
    Input,
    Select,
    Row,
    Col
} from "antd"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

import { DatePicker } from "@/components/DatePicker"
import { SelectDropdownForAddItem } from "@/components/SelectDropdownForAddItem"
import { YesOrNo } from "@/constants/validValues"
import { getAuthorizedOrganizationById } from "@/http/authorizedOrganization"
import { getClientById } from "@/http/vocabularyClient"

import { useContractMutation } from "@/hook/Contracts/useContractMutation"
import { useContractByIdQuery } from "@/hook/Contracts/useContractQuery"
import { useAuthorizedOrganizationQuery } from "@/hook/Vocabularies/useAuthorizedOrganizationQuery"
import { useClientQuery } from "@/hook/Vocabularies/useClientQuery"

import { IContractCreate } from "@/types/IContract"

import { paths } from "@/router/paths"

import { filterSelectOption } from "@/utils/filterSelectOption"

export interface ContractAddFormProps {
    open(id?: string): void;
    close(): void;
    copy(id: string): void
}

interface ContractAddFormValues {
    id: string
    number: string
    contractPrefixNumber: string
    name: string
    date: Date
    organization: number
    client: number
    jobGenitive: string
    requisites: string
    fullNameGenitive: string
    jobNominative: string
    fullNameNominative: string
}

export const ContractForm = forwardRef<ContractAddFormProps, {}>((props, ref) => {
    const [form] = Form.useForm<ContractAddFormValues>()
    const [id, setId] = useState<string>("")
    const [isOpen, setIsOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isCopy, setIsCopy] = useState<boolean>(false)

    const { data: contract } = useContractByIdQuery(id)
    const { createMutation, updateMutation } = useContractMutation()

    const { data: organizations } = useAuthorizedOrganizationQuery({
        OrderBy: "name asc"
    })
    const { data: clients } = useClientQuery({
        OrderBy: "name asc"
    })

    const navigate = useNavigate()
    
    const yes = YesOrNo[0]
    const no = YesOrNo[1]

    useImperativeHandle(ref, () => ({
        open(id?: string) {
            onOpenHandler(id)
        },
        close() {
            onCloseHandler()
        },
        copy(id: string) {
            onCopyHandler(id)
        }
    }))

    useEffect(() => {
        if (contract && isOpen) {
            const response = contract.response
            const prefix = response.organization?.contractPrefixNumber

            form.setFields([
                { name: "contractPrefixNumber", value: prefix ? prefix + "-" : prefix },
                { name: "name", value: response.name.substring(response.name.indexOf("-") + 1) },
                { name: "date", value: response.date && dayjs(response.date) },
                { name: "organization", value: response.organization?.id },
                { name: "client", value: response.client.id },
                { name: "jobGenitive", value: response.client?.jobGenitive },
                { name: "requisites", value: response.client?.requisitesGenitive },
                { name: "fullNameGenitive", value: response.client?.fullNameGenitive },
                { name: "jobNominative", value: response.client?.jobNominative },
                { name: "fullNameNominative", value: response.client?.fullNameNominative }
            ])
        }
    }, [id, contract, form, isOpen, yes, no])

    const title = useMemo(() => {
        const contract = " договор"

        if (isCopy) {
            return "Копировать" + contract
        }

        if (id) {
            return "Редактировать" + contract
        }

        return "Добавить" + contract
    }, [id, isCopy])

    const onOpenHandler = (id?: string) => {
        if (id) {
            setId(id)
        }
        form.resetFields()
        setIsOpen(true)
    }

    const onChangeOrganizationHandler = async (id: number) => {
        if (!id) return

        const organization = await getAuthorizedOrganizationById(id)
        const response = organization?.response
        if (response === null || response?.contractPrefixNumber === null) return

        form.setFieldValue("contractPrefixNumber", response.contractPrefixNumber + "-")
    }

    const onChangeClientHandler = async (id: number) => {
        if (!id) return

        const client = await getClientById(id)
        const response = client?.response
        if (response === null) return

        form.setFieldValue("jobGenitive", response?.jobGenitive)
        form.setFieldValue("requisites", response?.requisitesGenitive)
        form.setFieldValue("fullNameGenitive", response?.fullNameGenitive)
        form.setFieldValue("jobNominative", response?.jobNominative)
        form.setFieldValue("fullNameNominative", response?.fullNameNominative)
    }

    const onFinish = async (data: ContractAddFormValues) => {
        try {
            await form.validateFields()

            const request: IContractCreate = {
                name: data.name,
                date: data.date,
                organizationId: data.organization,
                clientId: data.client,
                jobGenitive: data.jobGenitive,
                requisites: data.requisites,
                fullNameGenitive: data.fullNameGenitive,
                jobNominative: data.jobNominative,
                fullNameNominative: data.fullNameNominative
            }
            setIsProcessing(true)

            if (id) {
                if (isCopy) {
                    const newId = (await createMutation.mutateAsync(request)).response
                    onCloseHandler(newId, true)
                } else {
                await updateMutation.mutateAsync({
                    ...request,
                    id: id
                })
                onCloseHandler(id, true)
                }
            } else {
                const newId = (await createMutation.mutateAsync(request)).response
                onCloseHandler(newId, true)
            }
        } finally {
            setIsProcessing(false)
        }
    }

    const onCopyHandler = (id: string) => {
        setId(id)
        setIsCopy(true)
        form.resetFields()
        setIsOpen(true)
    }

    const onCloseHandler = (currentId?: string, isSubmit: boolean = false) => {
        setId("")
        form.resetFields()
        setIsCopy(false)
        setIsOpen(false)

        if(currentId && isSubmit)
            navigate(paths.contractById(currentId))
    }

    const customDropdownHandler = (url: string) => {
        window.open(`${url}?openForm`, "_blank")
    }

    return (
        <Drawer
            title={title}
            width={820}
            onClose={() => onCloseHandler(id)}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Button type="primary" disabled={isProcessing} onClick={() => onFinish(form.getFieldsValue())}>
                    Сохранить
                </Button>
            }
        >
            <Form layout="vertical" autoComplete="off" form={form}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Префикс и номер договора" required>
                            <Input.Group compact>
                                <Form.Item
                                    name="contractPrefixNumber"
                                    noStyle
                                    rules={[{ 
                                        required: true,
                                        message: "Префикс заполняется из Организации"
                                    }]}
                                >
                                    <Input placeholder="Префикс" style={{ width: "30%" }} disabled />
                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    noStyle
                                    rules={[{
                                        required: true,
                                        message: "Пожалуйста введите номер договора"
                                }]}>
                                    <Input placeholder="Номер договора" style={{ width: "70%" }} />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="date"
                            label="Дата договора"
                            rules={[{
                                required: true,
                                message: "Пожалуйста выберите дату"
                            }]}
                        >
                            <DatePicker style={{ width: "100%" }} placeholder="Дата договора"
                                        disabledDate={(current) => current.isAfter(new Date())}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="organization"
                    label="Организация"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста выберите уполномоченную организацию"
                        }
                    ]}
                >
                    <Select
                        allowClear={false}
                        showSearch
                        options={organizations?.response.data.map(body => ({
                            value: body.id,
                            label: body.name + ", " + body.inn
                        }))}
                        filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                        placeholder="Организация"
                        dropdownRender={(menu) => (
                            <SelectDropdownForAddItem
                                menu={menu}
                                handler={() => customDropdownHandler(paths.authorizedOrganizations)}/>
                        )}
                        onChange={onChangeOrganizationHandler}/>
                </Form.Item>
                <Form.Item
                    name="client"
                    label="Клиент"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста выберите клиента"
                        }
                    ]}
                >
                    <Select
                        allowClear={false}
                        showSearch
                        options={clients?.response.data.map(body => ({
                            value: body.id,
                            label: body.name + ", " + body.inn
                        }))}
                        filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                        placeholder="Клиент"
                        dropdownRender={(menu) => (
                            <SelectDropdownForAddItem
                                menu={menu}
                                handler={() => customDropdownHandler(paths.clients)}/>
                        )}
                        onChange={onChangeClientHandler}
                    />
                </Form.Item>

                <Form.Item label="Печатные реквизиты договора:"
                            name="jobGenitive"
                            rules={[{
                                required: true,
                                message: "Должность подписанта в род. падеже заполняется из Клиента"
                            }]}>
                    <Input placeholder="Должность подписанта в род. падеже" disabled />
                </Form.Item>
                <Form.Item name="requisites" rules={[{
                    required: true,
                    message: "Реквизиты Документа на право подписи заполняются из Клиента"
                }]}>
                    <Input placeholder="Реквизиты Документа на право подписи" disabled />
                </Form.Item>
                <Form.Item name="fullNameGenitive" rules={[{
                    required: true,
                    message: "ФИО подписанта в род. падеже заполняется из Клиента"
                }]}>
                    <Input placeholder="ФИО подписанта в род. падеже" disabled />
                </Form.Item>
                <Form.Item name="jobNominative">
                    <Input placeholder="Должность подписанта в им. падеже" disabled />
                </Form.Item>
                <Form.Item name="fullNameNominative" rules={[{
                    required: true,
                    message: "Фамилия И.О. подписанта в им. падеже заполняется из Клиента"
                }]}>
                    <Input placeholder="Фамилия И.О. подписанта в им. падеже" disabled />
                </Form.Item>
            </Form>
        </Drawer>
    )
})
