import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState
} from "react"

import { Button, Checkbox, Col, Drawer, Form, Input, Row, Select, Tooltip } from "antd"

import { NumericInput } from "@/components/NumericInput"

import { useCountryQuery } from "@/hook/Vocabularies/Technical/useCountryQuery"
import { useClientMutation } from "@/hook/Vocabularies/useClientMutation"
import { useClientByIdQuery } from "@/hook/Vocabularies/useClientQuery"

import { IClientCreate } from "@/types/IClient"
import { RegistrationType, getRegistrationLength, getRegistrationName, getRegistrationType } from "@/types/RegistrationType"

import {
    INN_IP_LENGTH,
    INN_UL_LENGTH,
    BANK_ACCOUNT_LENGTH,
    CORRESPONDENT_ACCOUNT_LENGTH,
    BIK_LENGTH,
    KPP_LENGTH,
    BASE_ADDRESS_FIELDS_LENGTH,
    BUILDING_NUMBER_LENGTH,
    ROOM_NUMBER_LENGTH,
    INDEX_LENGTH,
    PHONE_NUMBER_LENGTH
} from "@/utils/constants"
import { filterSelectOption } from "@/utils/filterSelectOption"

export interface ClientAddFormProps {
    open(id?: number): void;

    close(): void;
}

interface ClientAddFormValues {
    identifier: number
    name: string
    shortName: string
    isIndividualEntrepreneur: boolean
    inn: string
    kpp: string
    ogrn: string
    bankAccount: string
    bankName: string
    bik: string
    correspondentAccount: string
    contactPerson: string
    phone: string
    email: string
    legalAddress: any
    jobGenitive: string
    requisitesGenitive: string
    fullNameGenitive: string
    jobNominative: string
    fullNameNominative: string
}

export const ClientAddForm = forwardRef<ClientAddFormProps>((props, ref) => {
    const [form] = Form.useForm<ClientAddFormValues>()
    const [id, setId] = useState<number>(0)
    const [isOpen, setIsOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isIE, setIsIE] = useState(false)
    const [legalAddressRequired, setLegalAddressRequired] = useState(false)

    const [registrationType, setRegistrationType] = useState(RegistrationType.OGRN)

    const { data: client } = useClientByIdQuery(id)
    const { createMutation, updateMutation } = useClientMutation()

    const { data: countries } = useCountryQuery({
        OrderBy: "name asc"
    })

    useImperativeHandle(ref, () => ({
        open(id?: number) {
            onOpenHandler(id)
        },
        close() {
            onCloseHandler()
        }
    }))

    const ChangeRegistrationTypeHandler = useCallback((value: number = 1, ie = false) => {
        const country = countries?.response.data.find(c => c.id === value)
        const registration = getRegistrationType(country?.code || "RU", ie)
        setRegistrationType(registration)
    }, [countries])

    useEffect(() => {
        if (client && isOpen) {
            const response = client.response

            setIsIE(response.isIndividualEntrepreneur)

            form.setFields([
                {
                    name: "name",
                    value: response.name
                },
                {
                    name: "shortName",
                    value: response.shortName
                },
                {
                    name: "isIndividualEntrepreneur",
                    value: response.isIndividualEntrepreneur
                },
                {
                    name: "inn",
                    value: response.inn
                },
                {
                    name: "kpp",
                    value: response.kpp
                },
                {
                    name: "ogrn",
                    value: response.ogrn
                },
                {
                    name: "bankAccount",
                    value: response.bankAccount
                },
                {
                    name: "bankName",
                    value: response.bankName
                },
                {
                    name: "bik",
                    value: response.bik
                },
                {
                    name: "correspondentAccount",
                    value: response.correspondentAccount
                },
                {
                    name: "contactPerson",
                    value: response.contactPerson
                },
                {
                    name: "phone",
                    value: response.phone
                },
                {
                    name: "email",
                    value: response.email
                },
                { 
                    name: "jobGenitive",
                    value: response.jobGenitive
                },
                {
                    name: "requisitesGenitive",
                    value: response.requisitesGenitive
                },
                {
                    name: "fullNameGenitive",
                    value: response.fullNameGenitive
                },
                {
                    name: "jobNominative",
                    value: response.jobNominative
                },
                {
                    name: "fullNameNominative",
                    value: response.fullNameNominative
                }
            ])

            const legalAddress = "legalAddress"
            const legalAddressValue = response.legalAddress

            form.setFields([
                {
                    name: [legalAddress, "countryId"],
                    value: legalAddressValue?.countryId
                },
                {
                    name: [legalAddress, "region"],
                    value: legalAddressValue?.region
                },
                {
                    name: [legalAddress, "district"],
                    value: legalAddressValue?.district
                },
                {
                    name: [legalAddress, "city"],
                    value: legalAddressValue?.city
                },
                {
                    name: [legalAddress, "settlement"],
                    value: legalAddressValue?.settlement
                },
                {
                    name: [legalAddress, "street"],
                    value: legalAddressValue?.street
                },
                {
                    name: [legalAddress, "buildingNumber"],
                    value: legalAddressValue?.buildingNumber
                },
                {
                    name: [legalAddress, "roomNumber"],
                    value: legalAddressValue?.roomNumber
                },
                {
                    name: [legalAddress, "postCode"],
                    value: legalAddressValue?.postCode
                }
            ])

            ChangeRegistrationTypeHandler(legalAddressValue?.countryId, response?.isIndividualEntrepreneur)
        }
    }, [id, client, form, isOpen, ChangeRegistrationTypeHandler])

    const title = useMemo(() => {
        const client = " клиента"
        if (id) {
            return "Редактировать" + client
        }

        return "Добавить" + client
    }, [id])

    const onIETypeChange = (event: any) => {
        setIsIE(event.target.checked)
        onLegalCountryChange(form.getFieldValue(["legalAddress", "countryId"]) || 1, event.target.checked)
    }

    const onLegalCountryChange = (value: number = 1, ie = false) => {
        ChangeRegistrationTypeHandler(value, ie)
    }

    const onOpenHandler = (id?: number) => {
        if (id) {
            setId(id)
        }
        form.resetFields()
        setIsOpen(true)
    }

    const onSubmitHandler = () => {
        const legalAddressCity = form.getFieldValue(["legalAddress", "city"])
        const legalAddressSettlement = form.getFieldValue(["legalAddress", "settlement"])
    
        setLegalAddressRequired(!legalAddressCity && !legalAddressSettlement)
        
        form.submit()
      }

    const onFinish = async (data: ClientAddFormValues) => {
        try {
            const request: IClientCreate = {
                name: data.name,
                shortName: data.shortName,
                isIndividualEntrepreneur: data.isIndividualEntrepreneur,
                inn: data.inn,
                kpp: data.kpp,
                ogrn: data.ogrn,
                bankAccount: data.bankAccount,
                bankName: data.bankName,
                bik: data.bik,
                correspondentAccount: data.correspondentAccount,
                contactPerson: data.contactPerson,
                phone: data.phone,
                email: data.email,
                legalAddress: data.legalAddress,
                jobGenitive: data.jobGenitive,
                requisitesGenitive: data.requisitesGenitive,
                fullNameGenitive: data.fullNameGenitive,
                jobNominative: data.jobNominative,
                fullNameNominative: data.fullNameNominative
            }
            setIsProcessing(true)

            if (id) {
                await updateMutation.mutateAsync({
                    ...request,
                    id: id
                })
            } else {
                await createMutation.mutateAsync(request)
            }

            form.resetFields()
            onCloseHandler()
        } finally {
            setIsProcessing(false)
        }
    }

    const onCloseHandler = () => {
        setId(0)
        form.resetFields()
        setIsOpen(false)
    }

    return (
        <Drawer
            title={title}
            width={820}
            onClose={onCloseHandler}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Button type="primary" disabled={isProcessing} onClick={onSubmitHandler}>
                    Сохранить
                </Button>
            }
        >
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item label="Полное наименование" name="name" rules={[{
                    required: true,
                    message: "Пожалуйста введите полное наименование"
                }]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Сокращенное наименование" name="shortName" rules={[
                    { required: true, message: "Пожалуйста введите сокращенное наименование" }
                ]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="isIndividualEntrepreneur" valuePropName="checked">
                    <Checkbox onChange={onIETypeChange}>Является ИП</Checkbox>
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="ИНН"
                            name="inn"
                            rules={[
                                { required: true, message: "Пожалуйста введите ИНН" },
                                {
                                    len: isIE ? INN_IP_LENGTH : INN_UL_LENGTH,
                                    message: `ИНН должен состоять из ${isIE ? INN_IP_LENGTH : INN_UL_LENGTH} цифр`
                                }
                            ]}
                        >
                            <NumericInput maxLength={isIE ? INN_IP_LENGTH : INN_UL_LENGTH}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="КПП"
                            name="kpp"
                            rules={[
                                { required: !isIE, message: "Пожалуйста введите КПП" },
                                { len: KPP_LENGTH, message: `КПП должен состоять из ${KPP_LENGTH} цифр` }
                            ]}
                        >
                            <NumericInput maxLength={KPP_LENGTH}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={getRegistrationName(registrationType)}
                    name="ogrn"
                    rules={[
                        { required: true, message: `Пожалуйста введите ${getRegistrationName(registrationType)}` },
                        { 
                          len: getRegistrationLength(registrationType, isIE),
                          message: `Длина ${getRegistrationName(registrationType)} должна быть ${getRegistrationLength(registrationType, isIE)} цифр` 
                        }
                    ]}
                >
                    <NumericInput maxLength={getRegistrationLength(registrationType, isIE)} style={{ width: "50%" }}/>
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label={"№ Р/СЧ в Банке"}
                            name="bankAccount"
                            rules={[
                                { required: true, message: "Пожалуйста введите № Р/СЧ в Банке" },
                                {
                                len: BANK_ACCOUNT_LENGTH,
                                message: `№ Р/СЧ в Банке должен состоять из ${BANK_ACCOUNT_LENGTH} цифр`
                                }
                            ]}
                        >
                            <NumericInput maxLength={BANK_ACCOUNT_LENGTH}/>
                        </Form.Item>
                        <Form.Item
                            label={"БИК"}
                            name="bik"
                            rules={[
                                { required: true, message: "Пожалуйста введите БИК" },
                                {
                                len: BIK_LENGTH,
                                message: `БИК должен состоять из ${BIK_LENGTH} цифр`
                                }
                            ]}
                        >
                            <NumericInput maxLength={BIK_LENGTH}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={"Наименование банка"}
                            name="bankName"
                            rules={[
                                { required: true, message: "Пожалуйста введите наименование банка" }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={"№ К/СЧ"}
                            name="correspondentAccount"
                            rules={[
                                { required: true, message: "Пожалуйста введите № К/СЧ" },
                                {
                                len: CORRESPONDENT_ACCOUNT_LENGTH,
                                message: `№ К/СЧ должен состоять из ${CORRESPONDENT_ACCOUNT_LENGTH} цифр`
                                }
                            ]}
                        >
                            <NumericInput maxLength={CORRESPONDENT_ACCOUNT_LENGTH}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item 
                            label="Контактное лицо" 
                            name="contactPerson" 
                            rules={[{ 
                                message: "Пожалуйста введите контактное лицо", 
                                required: true 
                            }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item 
                            label="Электронная почта" 
                            name="email" 
                            rules={[{ 
                                type: "email",  message: "Пожалуйста введите электронную почту", 
                                required: true 
                            }]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item 
                            label="Номер телефона"
                            name="phone"
                            rules={[{ message: "Пожалуйста введите номер телефона", required: true },
                                    { max:PHONE_NUMBER_LENGTH, message: `Номер телефона должен состоять из ${PHONE_NUMBER_LENGTH} цифр` }
                            ]}
                        >
                            <NumericInput maxLength={PHONE_NUMBER_LENGTH}/>
                        </Form.Item>
                    </Col>
                </Row>

                {(client?.response || !id) && (
                    <>
                        <Form.Item required label="Юридический адрес">
                            <Input.Group>
                                <Form.Item
                                    name={["legalAddress", "countryId"]}
                                    rules={[{ required: true, message: "Страна обязательное поле" }]}
                                >
                                    <Select
                                        placeholder="Страна"
                                        allowClear={false}
                                        showSearch
                                        onChange={(value) => onLegalCountryChange(value, isIE)}
                                        options={countries?.response.data.map(body => ({
                                        value: body.id,
                                        label: body.name
                                        }))}
                                        filterOption={(inputValue, option) => filterSelectOption(inputValue, option?.label || "")}
                                    />
                                </Form.Item>

                                <Tooltip title="Республика, автономная область, край, город федерального назначения, специальная территория"
                                        placement="topRight"
                                        color="blue">
                                    <Form.Item
                                        name={["legalAddress", "region"]}
                                        rules={[
                                            { 
                                                required: true, 
                                                message: "Республика/автономная область/край/город федерального назначения/специальная территория обязательное поле" 
                                            },
                                            { 
                                                max: BASE_ADDRESS_FIELDS_LENGTH, 
                                                message: "Максимальная длина поля " + BASE_ADDRESS_FIELDS_LENGTH 
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Республика, автономная область, край, город федерального назначения, специальная территория" />
                                    </Form.Item>
                                </Tooltip>

                                <Form.Item
                                    name={["legalAddress", "district"]}
                                    rules={[{ max: BASE_ADDRESS_FIELDS_LENGTH, message: "Максимальная длина поля " + BASE_ADDRESS_FIELDS_LENGTH }]}
                                >
                                    <Input placeholder="Район" />
                                </Form.Item>

                                <Form.Item
                                    name={["legalAddress", "city"]}
                                    rules={[
                                        { required: legalAddressRequired, message: "Город или населенный пункт обязательное поле" },
                                        { max: BASE_ADDRESS_FIELDS_LENGTH, message: "Максимальная длина поля " + BASE_ADDRESS_FIELDS_LENGTH }
                                    ]}
                                >
                                    <Input placeholder="Город" />
                                </Form.Item>

                                <Form.Item
                                    name={["legalAddress", "settlement"]}
                                    rules={[
                                        { required: legalAddressRequired, message: "Город или населенный пункт обязательное поле" },
                                        { max: BASE_ADDRESS_FIELDS_LENGTH, message: "Максимальная длина поля " + BASE_ADDRESS_FIELDS_LENGTH }
                                    ]}
                                >
                                    <Input placeholder="Населенный пункт" />
                                </Form.Item>

                                <Form.Item
                                    name={["legalAddress", "street"]}
                                    rules={[
                                        { required: true, message: "Улица обязательное поле" },
                                        { max: BASE_ADDRESS_FIELDS_LENGTH, message: "Максимальная длина поля " + BASE_ADDRESS_FIELDS_LENGTH }
                                    ]}
                                >
                                    <Input placeholder="Улица" />
                                </Form.Item>

                                <Form.Item
                                    name={["legalAddress", "buildingNumber"]}
                                    rules={[
                                        { required: true, message: "Номер дома/корпуса обязательное поле" },
                                        { max: BUILDING_NUMBER_LENGTH, message: "Максимальная длина поля " + BUILDING_NUMBER_LENGTH }
                                    ]}
                                >
                                    <Input placeholder="Дом, корпус" />
                                </Form.Item>

                                <Form.Item
                                    name={["legalAddress", "roomNumber"]}
                                    rules={[{ max: ROOM_NUMBER_LENGTH, message: "Максимальная длина поля " + ROOM_NUMBER_LENGTH }]}
                                >
                                    <Input placeholder="Номер помещения" />
                                </Form.Item>

                                <Form.Item
                                    name={["legalAddress", "postCode"]}
                                    rules={[
                                        { required: true, message: "Индекс обязательное поле" },
                                        { len: INDEX_LENGTH, message: "Индекс должен состоять из 6 цифр" }
                                    ]}
                                >
                                    <NumericInput placeholder="Индекс" />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </>
                )}

                <Form.Item label="Печатные реквизиты приложения:"
                            name="jobGenitive"
                            rules={[{
                                required: true,
                                message: "Пожалуйста введите должность подписанта в род. падеже"
                            }]}>
                    <Input placeholder="Должность подписанта в род. падеже" />
                </Form.Item>
                <Form.Item name="requisitesGenitive" rules={[{
                    required: true,
                    message: "Пожалуйста введите реквизиты Документа на право подписи в род. падеже"
                }]}>
                    <Input placeholder="Реквизиты Документа на право подписи в род. падеже" />
                </Form.Item>
                <Form.Item name="fullNameGenitive" rules={[{
                    required: true,
                    message: "Пожалуйста введите ФИО подписанта в род. падеже"
                }]}>
                    <Input placeholder="ФИО подписанта в род. падеже" />
                </Form.Item>
                <Form.Item name="jobNominative">
                    <Input placeholder="Должность подписанта в им. падеже" />
                </Form.Item>
                <Form.Item name="fullNameNominative" rules={[{
                    required: true,
                    message: "Пожалуйста введите Фамилия И.О. подписанта в им. падеже"
                }]}>
                    <Input placeholder="Фамилия И.О. подписанта в им. падеже" />
                </Form.Item>
            </Form>
        </Drawer>
    )
})
